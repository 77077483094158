import { useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Redirect, Route } from 'react-router-dom';
import { logout, resendEmailVerification } from '../../redux/actions/userActions';
import { isKeyUserProf } from '../../util/helpers/user';
import BadRequest from '../widgets/BadRequest';
import LoadingCircle from '../widgets/LoadingCircle';

export default function ProtectedRoute({ component: Component, ...rest }) {
  const {
    location: { pathname },
    kuOnly,
  } = rest;
  const { auth, profile, locale } = useSelector(({ locale, firebase: { auth, profile } }) => ({
    auth,
    profile,
    locale,
  }));
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  // Logout if not authorized to use this platform (only key users are allowed)
  useEffect(() => {
    if (isLoaded(auth, profile)) {
      if (auth.uid && profile && !profile.keyUser) {
        dispatch(logout(true, intl));
      }
    }
  }, [dispatch, intl, auth, profile]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoaded(auth, profile)) {
          if (auth.uid) {

            if (!auth.emailVerified) {
              return (
                <BadRequest
                  text={intl.formatMessage({ id: 'lacksEmailVerification' })}
                  iconProps={{ color: theme.palette.warning.main }}
                  link={{
                    text: intl.formatMessage({ id: 'resendEmailVerification' }),
                    actionCallback: () => dispatch(resendEmailVerification(locale.locale)),
                  }}
                />
              );
            }

            // Inform of network blockage when lacking profile data
            if (!profile.hasOwnProperty('pwUpdated')) {
              return (
                <BadRequest
                  text={intl.formatMessage({ id: 'networkIsBlockingDomains' })}
                  iconProps={{ color: theme.palette.warning.main }}
                />
              );
            }

            if (!profile.pwUpdated && pathname !== '/setpw') {
              return <Redirect to="/setpw" />;
            }

            if (kuOnly && !isKeyUserProf(profile)) {
              return <Redirect to="/" />;
            }

            return <Component {...props} />;
          }
          return <Redirect to="/login" />;
        } else {
          return <LoadingCircle />;
        }
      }}
    />
  );
}
