import React, { useState } from 'react';
import { Container, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { sendPasswordResetEmail } from '../../redux/actions/userActions';
import { updateUi } from '../../redux/actions/uiActions';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../widgets/LoadingButton';
import { useIntl } from 'react-intl';
import { deburr } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '1000px',
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  title: {
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { ui, locale } = useSelector(({ ui, locale }) => ({ ui, locale }));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      dispatch(
        updateUi({
          snackbar: {
            message: { msgCode: '420', msg: 'Invalid email!' },
            severity: 'error',
            show: true,
          },
        })
      );

      return;
    }

    dispatch(sendPasswordResetEmail(email, locale.locale));
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={clsx(classes.title)}>
          {intl.formatMessage({ id: 'forgotPw.title' })}
        </Typography>

        <Typography variant="body1" className={clsx(classes.title)}>
          {intl.formatMessage({ id: 'forgotPw.subtitle' })}
        </Typography>

        <TextField
          fullWidth
          required
          id="email"
          name="email"
          type="email"
          label={intl.formatMessage({ id: 'forgotPw.input' })}
          value={email}
          onChange={(e) => setEmail(deburr(e.target.value))}
          className={classes.input}
          autoFocus
        />

        <LoadingButton
          isLoading={ui.loadingButton.isForgetPasswordLoading}
          content={intl.formatMessage({ id: 'submit' })}
          type="submit"
        />
      </form>
    </Container>
  );
}
