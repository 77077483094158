import {
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { editApiKeyAuthDomains } from '../../redux/actions/managementActions';
import { selectAllApiKeys, selectModerators } from '../../redux/selectors/userSelectors';
import LoadingButton from '../widgets/LoadingButton';
import LoadingCircle from '../widgets/LoadingCircle';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '800px',
  },
  title: {
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

export default function EditApiKeyAuthDomains(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { apiKeysData, ui, moderatorProfilesData } = useSelector((state) => ({
    apiKeysData: selectAllApiKeys(state),
    moderatorProfilesData: selectModerators(state),
    profile: state.firebase.profile,
    ui: state.ui,
  }));

  const { match } = props;
  const { isLoaded: apiKeysAreLoaded, data: apiKeys } = apiKeysData;
  const { isLoaded: moderatorProfilesLoaded, data: moderatorProfiles } = moderatorProfilesData;

  const apiKeyId = match.params.apiKeyId;
  const apiKeyData = (apiKeysAreLoaded && apiKeys[apiKeyId]) || undefined;

  const moderatorProfile = (moderatorProfilesLoaded && apiKeyData && moderatorProfiles[apiKeyData.parentUid]) || {};
  const availableDomains = moderatorProfile && moderatorProfile.authDomains;

  const [domains, setDomains] = useState([]);
  const [domainError, setDomainError] = useState(undefined);

  useEffect(() => {
    if (apiKeyData) setDomains(apiKeyData.authDomains);
  }, [apiKeyData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const company = apiKeyData.company;
    const parentUid = apiKeyData.parentUid;

    if (!parentUid || !company) return;

    dispatch(
      editApiKeyAuthDomains({
        domains,
        apiKeyId,
        company,
        parentUid,
      })
    );
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={clsx(classes.title)}>
          {intl.formatMessage(
            { id: 'editApiKeyAuthDom.title' },
            { licenseDescription: apiKeyData && apiKeyData.description }
          )}
        </Typography>

        {apiKeysAreLoaded ? (
          <>
            <TextField
              disabled
              fullWidth
              required
              name="company"
              label={intl.formatMessage({ id: 'company' })}
              value={apiKeyData.company}
              className={classes.input}
            />
            <TextField
              disabled
              fullWidth
              required
              name="parentUid"
              label={intl.formatMessage({ id: 'parentMod' })}
              value={`${moderatorProfile.firstName} ${moderatorProfile.lastName}`}
              className={classes.input}
            />
            {!isEmpty(availableDomains) ? (
              <>
                <FormControl className={classes.input}>
                  <InputLabel id="authDomains-select-label">
                    {intl.formatMessage({ id: 'editApiKeyAuthDom.authDomains' })}
                  </InputLabel>
                  <Select
                    labelId="authDomains-select-label"
                    id="authDomains-select"
                    multiple
                    value={domains}
                    onChange={(e) => {
                      setDomains(e.target.value);
                      setDomainError(undefined);
                    }}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    error={!!domainError}
                    MenuProps={MenuProps}
                  >
                    {availableDomains.map((domain) => (
                      <MenuItem key={domain} value={domain}>
                        <Checkbox checked={domains.indexOf(domain) > -1} />
                        <ListItemText primary={domain} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{domainError}</FormHelperText>
                </FormControl>
                <LoadingButton
                  isLoading={ui.loadingButton.isEditApiKeyAuthDomainsLoading}
                  content={intl.formatMessage({ id: 'saveChanges' })}
                  disabled={!availableDomains || availableDomains.length === 0}
                  type="submit"
                />
              </>
            ) : (
              <Typography>{intl.formatMessage({ id: 'editApiKeyAuthDom.noDomainsAvailable' })}</Typography>
            )}
          </>
        ) : (
          <LoadingCircle />
        )}
      </form>
    </Container>
  );
}
