import React from 'react';
import { render } from 'react-dom';
// Redux
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import App from './App';
//Internationalization
//@ts-ignore
import IntlAndTheme from './components/utils/IntlAndTheme';
//@ts-ignore
import { rrfConfig, store } from './redux/store';
//@ts-ignore
import * as serviceWorker from './serviceWorker';
//@ts-ignore
import firebase from './util/firebase';

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <IntlAndTheme>
        <App />
      </IntlAndTheme>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
