import { alpha, Button, Container, TextField, Typography, withTheme } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CropFreeIcon from '@material-ui/icons/CropFree';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { cloneDeep, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import uploadImage from '../../../assets/images/uploadImage.jpg';
import { actions } from '../../../redux/actions/actionsHandler';
import { fetchLabelImage as fetchLabelImageAction } from '../../../redux/actions/managementActions';
import { updateUi as updateUiAction } from '../../../redux/actions/uiActions';
import { selectDomainSettings, selectParentCandidateModerators } from '../../../redux/selectors/userSelectors';
import { management } from '../../../redux/types';
import { urlDataReader } from '../../../util/workers/fileWorkerUtils';
import BadRequest from '../../widgets/BadRequest';
import EditBarcodePattern from '../EditBarcodePattern';
import LabelDesignStage from './LabelDesignStage';

const maxImageWidth = 800;
const maxImageHeight = 600;

const styles = (theme) => ({
  container: { ...theme.container(1), marginLeft: 0 },
  title: {
    marginBottom: theme.spacing(2),
    maxWidth: theme.spacing(75),
  },
  button: {
    margin: theme.spacing(1, 0, 1, 0),
    alignSelf: 'flex-start',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  linearHorizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  linearVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
  redButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  blueButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
  },
  labelUploadImage: {
    objectFit: 'contain',
    maxWidth: maxImageWidth * 0.66,
    maxHeight: maxImageHeight * 0.66,
    boxShadow: `0 30px 40px ${alpha(theme.palette.other.white, 0.9)}`,
  },
  superImposed: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
});

const initialStage = {
  name: null,
  nameError: null,
  label: { x: 0, y: 0, width: 0, height: 0 },
  barcodes: [],
  selectedIndex: -1,
};

const initialPatterns = {
  barcodePatterns: [],
};

// const mockSettings = {
//   someid: {
//     labelDesigns: [
//       {
//         name: 'labelName',
//         firstVertex: { x: 163, y: 100 },
//         secondVertex: { x: 556, y: 534 },
//         barcodePatterns: [
//           {
//             dataFields: [
//               {
//                 checkField: '',
//                 limiter: '-1',
//                 limiterType: 'range',
//                 name: 'code',
//                 postfix: '',
//                 prefix: '',
//                 type: 'data',
//                 useCheckField: false,
//               },
//             ],
//             ignoreRemaining: false,
//             regex: '^(?<code>.*)$',
//             firstVertex: { x: 250, y: 150 },
//             secondVertex: { x: 500, y: 200 },
//             required: true,
//           },
//         ],
//       },
//     ],
//   },
// };

function limitDimenKeepRatio(origWidth, origHeight, maxWidth, maxHeight) {
  let width = origWidth;
  let height = origHeight;
  let ratio = width / height;

  if (ratio > 1 && width > maxWidth) {
    width = maxWidth;
    height *= width / origWidth;
  } else if (ratio < 1 && height > maxHeight) {
    height = maxHeight;
    width *= height / origHeight;
  }

  return { width, height };
}

function vertexToRect(firstVertex, secondVertex) {
  const rect = { x: 0, y: 0, width: 0, height: 0 };
  if (firstVertex && secondVertex) {
    rect.x = Math.min(firstVertex.x, secondVertex.x);
    rect.y = Math.min(firstVertex.y, secondVertex.y);
    rect.width = Math.abs(firstVertex.x - secondVertex.x);
    rect.height = Math.abs(firstVertex.y - secondVertex.y);
  }

  return rect;
}

function getLabelDesignProp(settingsData, index, prop) {
  return (
    (settingsData.isLoaded &&
      settingsData.data.labelDesigns &&
      settingsData.data.labelDesigns[index] &&
      settingsData.data.labelDesigns[index][prop]) ||
    null
  );
}

class LabelDesign extends Component {
  state = {
    mode: 'none',
    hasTriggeredImageLabelFetch: false,
    loadedNewImage: false,
    // stage data
    ...initialStage,
    // barcodePatterns
    ...initialPatterns,
    // Init from props
    init: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { settingsData, match } = props;
    if (!state.init) {
      const { isLoaded: settingsLoaded, data: settings } = settingsData;
      if (settingsLoaded && match.params.index) {
        const labelDesigns = settings.labelDesigns;
        if (labelDesigns && labelDesigns.length && match.params.index < labelDesigns.length) {
          const labelDesign = labelDesigns[match.params.index];
          const { name } = labelDesign;

          const label = vertexToRect(labelDesign.firstVertex, labelDesign.secondVertex);
          const barcodePatterns = cloneDeep(labelDesign.barcodePatterns);

          const barcodes = [];
          for (const barcodePattern of barcodePatterns) {
            barcodes.push(vertexToRect(barcodePattern.firstVertex, barcodePattern.secondVertex));
          }

          return { name, label, barcodes, barcodePatterns, init: true };
        } else {
          return { init: true };
        }
      }
    }
    return null;
  }

  handleChange = (e) => {
    const errorField = /(name)/g.exec(e.target.name);
    let nextState = {};

    if (errorField) {
      nextState[errorField[1].concat('Error')] = '';
    }

    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  handleFileChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { match, settingsData } = this.props;

    const labelName = getLabelDesignProp(settingsData, match.params.index, 'name');

    const file = e.target.files[0];
    if (file) {
      urlDataReader(file, {
        onload: (result) => {
          const img = new Image();
          img.onload = () => {
            const { width, height } = limitDimenKeepRatio(
              img.naturalWidth,
              img.naturalHeight,
              maxImageWidth,
              maxImageHeight
            );

            this.props.updateLabelImage({
              identifier: `${match.params.domain}${labelName}`,
              image: img,
              imageWidth: width,
              imageHeight: height,
              isLoaded: true,
            });
            this.setState({ loadedNewImage: true, ...initialStage, ...initialPatterns });
          };
          img.src = result;
        },
      });
    }
  };

  setMode = (mode) => {
    const { match, mng, updateLabelImage, settingsData } = this.props;
    const labelName = getLabelDesignProp(settingsData, match.params.index, 'name');

    const labelIdentifier = `${match.params.domain}${labelName}`;

    const labelImageState = mng[labelIdentifier];
    const img = labelImageState && labelImageState.image;
    // Settings mode to 'pattern' requires resizing image
    if (mode === 'pattern') {
      if (img) {
        const { width, height } = limitDimenKeepRatio(img.naturalWidth, img.naturalHeight, 200, 200);

        this.setState({
          mode,
          stageScaleX: width / labelImageState.imageWidth,
          stageScaleY: height / labelImageState.imageHeight,
        });

        updateLabelImage({
          identifier: labelIdentifier,
          imageWidth: width,
          imageHeight: height,
        });
      }
    } // mode is already 'pattern', changing mode requires resizing image
    else if (this.state.mode === 'pattern') {
      if (img) {
        const { width, height } = limitDimenKeepRatio(
          img.naturalWidth,
          img.naturalHeight,
          maxImageWidth,
          maxImageHeight
        );

        this.setState({ mode });

        updateLabelImage({
          identifier: labelIdentifier,
          imageWidth: width,
          imageHeight: height,
        });
      }
    } else {
      this.setState({ mode: mode === this.state.mode ? 'none' : mode });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { label, name, loadedNewImage } = this.state;
    const { intl, updateUi, match, settingsData, mng, location, candidateModeratorsData } = this.props;

    const labelName = getLabelDesignProp(settingsData, match.params.index, 'name');
    const barcodes = cloneDeep(this.state.barcodes);
    const barcodePatterns = cloneDeep(this.state.barcodePatterns);

    const currentIdentifier = `${match.params.domain}${labelName}`;
    const labelImageState = mng[currentIdentifier];
    const isEditMode = location && location.state ? location.state.type === 'edit' : false;

    // Sanity checks
    let err = null;
    if (!barcodes || !barcodePatterns || barcodes.length < 1) {
      err = { id: 'errMissingBarcodes' };
    } else if (barcodePatterns.length !== barcodes.length) {
      err = { id: 'barcodesMustHavePattern' };
    }

    if (err) {
      updateUi({
        snackbar: {
          message: intl.formatMessage({ id: `labelDesign.${err.id}` }, err.params),
          severity: 'error',
          show: true,
        },
      });
      return;
    }

    const { isLoaded: modsLoaded, data: candidateModerators } = candidateModeratorsData;
    if (!modsLoaded) return;

    // Guarantee valid ownership
    const candidatesCount = Object.keys(candidateModerators).length;
    if (candidatesCount !== 1) {
      this.props.updateUi({
        snackbar: {
          message: intl.formatMessage({
            id: candidatesCount > 1 ? 'manDom.errMultipleParents' : 'manDom.errMissingParent',
          }),
          severity: 'error',
          show: true,
        },
      });
      return;
    }
    const parentUid = Object.keys(candidateModerators)[0];

    // Build database format data
    barcodes.forEach((barcode, index) => {
      barcodePatterns[index].firstVertex = {
        x: barcode.x,
        y: barcode.y,
      };

      barcodePatterns[index].secondVertex = {
        x: barcode.x + barcode.width,
        y: barcode.y + barcode.height,
      };
    });

    const newLabelDesign = {
      name,
      firstVertex: { x: label.x, y: label.y },
      secondVertex: { x: label.x + label.width, y: label.y + label.height },
      barcodePatterns,
      imageFile: loadedNewImage ? labelImageState.image.src : null,
    };

    const { isLoaded: settingsLoaded, data: currentSettings } = settingsData;
    if (!settingsLoaded) return;

    const { company, domain, index } = match.params;
    updateUi({
      dialog: {
        title: intl.formatMessage({ id: 'labelDesign.updtConfTitle' }),
        message: intl.formatMessage({ id: 'labelDesign.updtConfMsg' }),
        confirmAction: isEditMode ? actions.EDIT_LABEL_DESIGN : actions.ADD_LABEL_DESIGN,
        actionData: isEditMode
          ? {
              domain,
              company,
              parentUid,
              index,
              currentSettings,
              newLabelDesign,
            }
          : {
              domain,
              company,
              parentUid,
              newLabelDesign,
              currentIdentifier,
            },
        useCheckbox: true,
        checkboxState: false,
        checkboxMessage: <b>{intl.formatMessage({ id: 'labelDesign.updtConfCheck' })}</b>,
        show: true,
      },
    });
  };

  onBarcodePatternChange = (index, barcodePattern) => {
    // Change index barcode data
    const barcodePatterns = [...this.state.barcodePatterns];
    barcodePatterns[index] = barcodePattern;
    this.setState({ barcodePatterns });
  };

  addBarcode = (barcode) => {
    const barcodes = [...this.state.barcodes, barcode];
    this.setState({ barcodes });
  };

  remBarcode = (index) => {
    const barcodes = [...this.state.barcodes];
    barcodes.splice(index, 1);

    const barcodePatterns = [...this.state.barcodePatterns];
    barcodePatterns.splice(index, 1);
    this.setState({ barcodes, barcodePatterns, selectedIndex: -1 });
  };

  setLabel = (label) => {
    let indexesToKeep = [];
    if (label) {
      const labelX = Math.min(label.x, label.x + label.width);
      const labelY = Math.min(label.y, label.y + label.height);
      const labelXMax = Math.abs(label.width) + labelX;
      const labelYMax = Math.abs(label.height) + labelY;

      this.state.barcodes.forEach((bc, index) => {
        const x = Math.min(bc.x, bc.x + bc.width);
        const y = Math.min(bc.y, bc.y + bc.height);
        const bcXMax = Math.abs(bc.width) + x;
        const bcYMax = Math.abs(bc.height) + y;

        if (x > labelX && y > labelY && bcXMax < labelXMax && bcYMax < labelYMax) indexesToKeep.push(index);
      });
    }

    this.setState({
      label,
      barcodes: this.state.barcodes.filter((__, idx) => indexesToKeep.includes(idx)),
      barcodePatterns: this.state.barcodePatterns.filter((__, idx) => indexesToKeep.includes(idx)),
    });
  };

  checkLabelImage = () => {
    const { settingsData, match } = this.props;
    const { company, domain, index } = match.params;

    const { isLoaded: settingsLoaded, data: settings } = settingsData;

    const labelName = getLabelDesignProp(settingsData, index, 'name');
    // Avoid server image fetch if no label design is defined
    if (settingsLoaded) {
      if (this.state.hasTriggeredImageLabelFetch) {
        return;
      } else if (!settings.labelDesigns) {
        this.setState({ hasTriggeredImageLabelFetch: true }, () =>
          this.props.labelImageLoaded({
            identifier: `${domain}${labelName}`,
            image: null,
          })
        );
        return;
      }
    } else {
      return;
    }

    if (labelName) {
      const { mng, auth, profile } = this.props;

      const identifier = `${domain}${labelName}`;
      const labelImageState = mng[identifier];
      // If no label is loaded yet and label design exists, trigger server image fetch
      if (!labelImageState || !(labelImageState.isTriggered || labelImageState.isLoaded)) {
        // Only try to download label images if properly authenticated
        if (!(isLoaded(auth) && auth.uid && isLoaded(profile))) {
          return;
        }

        this.setState({ hasTriggeredImageLabelFetch: true }, () =>
          this.props.fetchLabelImage({
            company,
            domain,
            labelName,
            dimensionCalc: (width, height) => limitDimenKeepRatio(width, height, maxImageWidth, maxImageHeight),
          })
        );
      }
    } else {
      this.setState({ hasTriggeredImageLabelFetch: true }, () =>
        this.props.labelImageLoaded({
          identifier: `${domain}${labelName}`,
          image: null,
        })
      );
    }
  };

  componentDidMount() {
    const { mng, match, settingsData } = this.props;
    const { isLoaded: settingsLoaded } = settingsData;

    this.checkLabelImage();

    // Redimension image to fit label stage in case of component reloading without image reloading
    if (settingsLoaded) {
      const labelName = getLabelDesignProp(settingsData, match.params.index, 'name');
      if (labelName) {
        const isEditPatternMode = this.state.mode === 'pattern';
        const identifier = `${match.params.domain}${labelName}`;
        const labelImageState = mng[identifier];
        if (labelImageState && labelImageState.isLoaded && labelImageState.image && !isEditPatternMode) {
          const img = labelImageState.image;
          const { width, height } = limitDimenKeepRatio(
            img.naturalWidth,
            img.naturalHeight,
            maxImageWidth,
            maxImageHeight
          );
          if (labelImageState.imageWidth !== width || labelImageState.imageHeight !== height) {
            this.props.updateLabelImage({
              identifier,
              imageWidth: width,
              imageHeight: height,
            });
          }
          return;
        }
      }
    }
  }

  componentDidUpdate() {
    this.checkLabelImage();
  }

  render() {
    const { intl, classes, theme, mng, match, location, settingsData } = this.props;

    const isEditPatternMode = this.state.mode === 'pattern';

    const labelName = getLabelDesignProp(settingsData, match.params.index, 'name');
    const labelImageState = mng[`${match.params.domain}${labelName}`];
    const isImageLoaded = labelImageState && labelImageState.isLoaded;

    const type = location && location.state ? location.state.type : null;

    const editOrAdd = (edit, add) => (type === 'edit' ? edit : add);

    const renderFileButton = (marginTop) => (
      <div className={classes.button} style={{ marginTop }}>
        <input
          accept="image/jpeg, image/png"
          style={{ display: 'none' }}
          id="profile-image-btn"
          type="file"
          onChange={this.handleFileChange}
        />
        <label htmlFor="profile-image-btn">
          <Button variant="contained" component="span" color="primary">
            <PublishRoundedIcon style={{ marginRight: theme.spacing(1) }} />
            {intl.formatMessage({ id: 'labelDesign.uploadPicture' })}
          </Button>
        </label>
      </div>
    );

    const labelDesignStage = isImageLoaded ? (
      labelImageState && labelImageState.image && labelImageState.image.src ? (
        <div className={classes.linearVertical}>
          {!isEditPatternMode && (
            <TextField
              error={!isEmpty(this.state.nameError)}
              name="name"
              id="name"
              type="text"
              label={`${intl.formatMessage({ id: 'labelDesign.name' })} *`}
              value={this.state.name}
              onChange={this.handleChange}
              helperText={this.state.nameError}
              style={{ margin: theme.spacing(0, 1, 1, 1), width: 400, alignSelf: 'center' }}
            />
          )}
          <div className={classes.linearVertical} style={{ position: 'relative' }}>
            <img
              alt=""
              src={labelImageState.image.src}
              className={clsx(classes.superImposed)}
              style={{ width: labelImageState.imageWidth, height: labelImageState.imageHeight }}
            />
            <LabelDesignStage
              mode={this.state.mode}
              width={labelImageState.imageWidth}
              height={labelImageState.imageHeight}
              scaleX={this.state.stageScaleX}
              scaleY={this.state.stageScaleY}
              className={classes.superImposed}
              // State handling
              label={this.state.label}
              setLabel={this.setLabel}
              barcodes={this.state.barcodes}
              addBarcode={this.addBarcode}
              remBarcode={this.remBarcode}
              selectedIndex={this.state.selectedIndex}
              setSelectedIndex={(selectedIndex) => this.setState({ selectedIndex })}
            />
          </div>
        </div>
      ) : (
        <div className={clsx(classes.linearVertical, classes.spaceEvenly)}>
          <img src={uploadImage} className={classes.labelUploadImage} alt="" />
          <div className={clsx(classes.linearHorizontal, classes.spaceEvenly)}>
            {renderFileButton(theme.spacing(0.5))}
          </div>
        </div>
      )
    ) : (
      <div className={clsx(classes.linearVertical)} style={{ justifyContent: 'flex-start' }}>
        <Skeleton variant="rect" style={{ width: maxImageWidth * 0.66, height: maxImageHeight * 0.66 }} />
      </div>
    );

    return type ? (
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          {intl.formatMessage(
            { id: 'labelDesign.title' },
            { action: intl.formatMessage({ id: editOrAdd('edit', 'add') }) }
          )}
        </Typography>

        <Typography variant="subtitle1" className={classes.title}>
          {intl.formatMessage({ id: 'labelDesign.subtitle' })}
        </Typography>

        <div className={classes.linearHorizontal}>
          {!isEditPatternMode && labelDesignStage}

          {isImageLoaded && labelImageState && labelImageState.image && labelImageState.image.src && (
            <>
              <div className={classes.linearVertical} style={{ maxWidth: 220, marginLeft: theme.spacing(1) }}>
                {isEditPatternMode ? null : (
                  <>
                    {/* Control buttons */}
                    {renderFileButton(theme.spacing(7))}

                    <Button
                      aria-label={intl.formatMessage({ id: 'labelDesign.defineLabelRegion' })}
                      variant={this.state.mode === 'def_label' ? 'outlined' : 'text'}
                      className={clsx(classes.button, classes.blueButton)}
                      onClick={() => this.setMode('def_label')}
                    >
                      <CropFreeIcon style={{ marginRight: theme.spacing(1), marginLeft: 0 }} />
                      {intl.formatMessage({ id: 'labelDesign.defineLabelRegion' })}
                    </Button>

                    <Button
                      color="secondary"
                      aria-label={intl.formatMessage({ id: 'labelDesign.addBarcodes' })}
                      variant={this.state.mode === 'add_bc' ? 'outlined' : 'text'}
                      className={classes.button}
                      onClick={() => this.setMode('add_bc')}
                    >
                      <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                      {intl.formatMessage({ id: 'labelDesign.addBarcodes' })}
                    </Button>

                    <Button
                      aria-label={intl.formatMessage({ id: 'labelDesign.removeBarcodes' })}
                      variant={this.state.mode === 'delete' ? 'outlined' : 'text'}
                      className={clsx(classes.button, classes.redButton)}
                      onClick={() => this.setMode('delete')}
                    >
                      <RemoveCircleIcon style={{ marginRight: theme.spacing(1) }} />
                      {intl.formatMessage({ id: 'labelDesign.removeBarcodes' })}
                    </Button>

                    <Button
                      aria-label={intl.formatMessage({ id: 'labelDesign.editBarcode' }, { index: 0 })}
                      variant="text"
                      className={clsx(classes.button, classes.blueButton)}
                      onClick={() => this.setMode('pattern')}
                      disabled={!this.state.barcodes || this.state.barcodes.length < 1 || isEmpty(this.state.name)}
                    >
                      <EditRoundedIcon style={{ marginRight: theme.spacing(1) }} />
                      {intl.formatMessage({ id: 'labelDesign.editBarcode' })}
                    </Button>
                  </>
                )}
              </div>

              {/* Pattern editor */}
              {isEditPatternMode && (
                <EditBarcodePattern
                  ui={this.props.ui}
                  index={this.state.selectedIndex}
                  barcodePatterns={this.state.barcodePatterns}
                  domain={match.params.domain}
                  labelName={this.state.name}
                  stageWidget={labelDesignStage}
                  onBackToLabelEditor={() => this.setMode('none')}
                  onBarcodePatternChange={this.onBarcodePatternChange}
                  onSubmit={this.onSubmit}
                />
              )}
            </>
          )}
        </div>
      </Container>
    ) : (
      <BadRequest text={intl.formatMessage({ id: 'sett.badRouting' })} />
    );
  }
}

export default compose(
  connect(
    (state, { match }) => {
      const { company, domain } = match.params;
      return {
        ui: state.ui,
        mng: state.mng,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        settingsData: selectDomainSettings(company, domain, state),
        candidateModeratorsData: selectParentCandidateModerators(state, company, domain),
        // settings: mockSettings,
      };
    },
    (dispatch, ownProps) => ({
      ...ownProps,
      updateUi: (uiData) => dispatch(updateUiAction(uiData)),
      fetchLabelImage: (data) => dispatch(fetchLabelImageAction(data)),
      updateLabelImage: (data) => dispatch({ type: management.LABEL_IMAGE_UPDATED, data }),
      labelImageLoaded: (data) => dispatch({ type: management.LABEL_IMAGE_LOADED, data }),
    })
  ),
  withStyles(styles),
  withTheme,
  injectIntl
)(LabelDesign);
