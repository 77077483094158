import React, { useState, useRef } from 'react';
import { useTheme } from '@material-ui/core';
import { Layer, Rect, Text, Group, Stage } from 'react-konva';

const initialRect = { x: 0, y: 0, width: 0, height: 0 };

const modes = {
  NONE: 'none',
  ADD_BARCODE: 'add_bc',
  DEFINE_LABEL: 'def_label',
  DELETE: 'delete',
  PATTERN: 'pattern',
};

const getRectCenter = (rect) => {
  if (rect) {
    return {
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height / 2,
    };
  }
};

export default function LabelDesignStage(props) {
  const { mode, setLabel, addBarcode, remBarcode, setSelectedIndex, scaleX, scaleY } = props;

  const theme = useTheme();

  const [isDrawing, setIsDrawing] = useState(false);
  const [newRect, setNewRect] = useState(initialRect);

  const newRectRef = useRef();
  const stageRef = useRef();

  const checkWithinLabelLimits = (mode, point) => {
    const label = props.label;
    if (Math.abs(label.width) <= 0 || Math.abs(label.height) <= 0) {
      return false;
    } else {
      const otherVertex = { x: label.x + label.width, y: label.y + label.height };
      const xMin = Math.min(label.x, otherVertex.x);
      const yMin = Math.min(label.y, otherVertex.y);
      const xMax = Math.max(label.x, otherVertex.x);
      const yMax = Math.max(label.y, otherVertex.y);
      if (point.x < xMin || point.x > xMax || point.y < yMin || point.y > yMax) {
        return false;
      }
    }
    return true;
  };

  const onStageMouseDown = (e) => {
    e.cancelBubble = true;
    switch (mode) {
      case modes.DEFINE_LABEL:
      case modes.ADD_BARCODE:
        const pointer = e.currentTarget.getStage().getPointerPosition();
        if (isDrawing) {
          const { x, y } = newRect;
          const endRect = { x, y, width: pointer.x - x, height: pointer.y - y };
          if (mode === modes.DEFINE_LABEL) {
            setLabel(endRect);
          } else {
            // Check if current barcode is inside label region
            if (mode === modes.ADD_BARCODE) {
              if (!checkWithinLabelLimits(mode, pointer)) return;
            }
            addBarcode(endRect);
          }
          setNewRect(initialRect);
          setIsDrawing(false);
        } else {
          // Check if current barcode is inside label region
          if (mode === modes.ADD_BARCODE) {
            if (!checkWithinLabelLimits(mode, pointer)) return;
          }
          setNewRect({ x: pointer.x, y: pointer.y, width: newRect.width, height: newRect.height });
          setIsDrawing(true);
        }
        break;
      case modes.DELETE:
      case modes.NONE:
      default:
        setSelectedIndex(-1);
        break;
    }
  };

  const onStageMouseMove = (e) => {
    e.cancelBubble = true;
    if ((mode === modes.ADD_BARCODE || mode === modes.DEFINE_LABEL) && isDrawing) {
      const pointer = e.currentTarget.getStage().getPointerPosition();
      // Check if current barcode is inside label region
      if (mode === modes.ADD_BARCODE) {
        if (!checkWithinLabelLimits(mode, pointer)) return;
      }

      if (newRectRef.current) {
        newRectRef.current.to({
          duration: 0,
          x: newRect.x,
          y: newRect.y,
          width: pointer.x - newRect.x,
          height: pointer.y - newRect.y,
        });
      }
    }
  };

  const onRectSelection = (index) => (e) => {
    e.cancelBubble = true;
    if (mode !== modes.DELETE) {
      setSelectedIndex(index);
    }
  };

  const onRectAction = (index) => (e) => {
    e.cancelBubble = true;
    switch (mode) {
      case modes.DELETE:
        remBarcode(index);
        break;
      case modes.ADD_BARCODE:
      case modes.NONE:
      default:
        break;
    }
  };

  const renderRectBeingDrawn = () => {
    const isLabel = mode === modes.DEFINE_LABEL;

    const rect = { ...newRect };
    if (mode === modes.PATTERN) {
      rect.x *= scaleX;
      rect.width *= scaleX;
      rect.y *= scaleY;
      rect.height *= scaleY;
    }

    return (
      <Rect
        {...rect}
        fill={theme.palette.info.main}
        fillEnabled={!isLabel}
        dash={[10, 5]}
        dashEnabled={isLabel}
        opacity={0.5}
        stroke={theme.palette.info.main}
        strokeWidth={2}
        listening={false}
        ref={newRectRef}
      />
    );
  };

  const renderLabel = () => {
    const label = { ...props.label };
    if (mode === modes.PATTERN) {
      label.x *= scaleX;
      label.width *= scaleX;
      label.y *= scaleY;
      label.height *= scaleY;
    }
    return <Rect {...label} stroke={theme.palette.info.dark} dash={[10, 10]} dashEnabled={true} strokeWidth={3} />;
  };

  const renderDefinedRectangles = () =>
    props.barcodes.map((rect, index) => {
      const isSelected = index === props.selectedIndex;
      const currRect = { ...rect };
      if (mode === modes.PATTERN) {
        currRect.x *= scaleX;
        currRect.width *= scaleX;
        currRect.y *= scaleY;
        currRect.height *= scaleY;
      }
      const rectCenter = getRectCenter(currRect);

      return (
        <Group
          key={`rect${index}`}
          onMouseDown={onRectSelection(index)}
          onMouseUp={onRectAction(index)}
          onTouchStart={onRectSelection(index)}
          onTouchEnd={onRectAction(index)}
        >
          <Rect {...currRect} fill={theme.palette.secondary.main} opacity={isSelected ? 0.9 : 0.5} />
          <Rect
            {...currRect}
            stroke={isSelected ? theme.palette.secondary.light : theme.palette.secondary.dark}
            listening={false}
            strokeWidth={3}
          />
          <Text
            text={index + 1}
            x={rectCenter.x - 6}
            y={rectCenter.y - 12}
            fontSize={24}
            fontStyle="bold"
            fill="white"
            listening={false}
          />
        </Group>
      );
    });

  if (stageRef.current != null) {
    switch (mode) {
      case 'delete':
        stageRef.current.getContainer().style.cursor = 'no-drop';
        break;
      case 'add_bc':
      case 'def_label':
        stageRef.current.getContainer().style.cursor = 'crosshair';
        break;
      case 'none':
      default:
        stageRef.current.getContainer().style.cursor = 'default';
        break;
    }
  }

  return (
    <Stage
      width={props.width}
      height={props.height}
      onMouseDown={onStageMouseDown}
      onMouseMove={onStageMouseMove}
      onTouchStart={onStageMouseDown}
      onTouchMove={onStageMouseMove}
      onTouchEnd={onStageMouseDown}
      ref={stageRef}
    >
      {/* Drawing layer  */}
      <Layer>
        {/* Rectangle being drawn by user */}
        {isDrawing && renderRectBeingDrawn()}
        {/* Label marker */}
        {renderLabel()}
        {/* Already drawn barcode rectangles */}
        {renderDefinedRectangles()}
      </Layer>
    </Stage>
  );
}
