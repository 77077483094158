import React from 'react';

import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Splash from './Splash';

export default function LandingLoading({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  return JSON.parse(localStorage.getItem('firstTimeInit')) ? (
    <TransitionGroup>
      <CSSTransition key={isLoaded(auth)} timeout={{ enter: 1000, exit: 500 }} classNames={'fade'}>
        {isLoaded(auth) ? children : <Splash />}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    children
  );
}
