import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Skeleton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import 'firebase/firestore';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi } from '../../redux/actions/uiActions';
import { groupByCompany, selectAllApiKeys, selectCompanies } from '../../redux/selectors/userSelectors';
import { copyTextToClipboard } from '../../util/helpers/other';
import { parseTimestampToDate } from '../../util/helpers/server';

const useStyles = makeStyles(({ palette, spacing, container }) => ({
  container: { ...container(2), marginLeft: 0 },
  tabContainer: { maxWidth: spacing(100) },
  table: { minWidth: '1024px' },
  tableHeader: {},
  tableBody: {},
  skeleton: { height: spacing(8), margin: spacing(2, 0) },
}));

const dialogTypes = {
  RENEW_API_KEY: 0,
  REMOVE_API_KEY: 1,
};

export default function ManageApiKeys(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { palette, text, spacing } = useTheme();
  const dispatch = useDispatch();

  const { authCompanies, apiKeysData } = useSelector((s) => ({
    authCompanies: selectCompanies(s),
    apiKeysData: selectAllApiKeys(s),
  }));

  const MsInADay = 1000 * 60 * 60 * 24;
  const MsInAMonth = MsInADay * 30;
  const MsInAYear = MsInADay * 365;

  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem('selectedCompany') || (authCompanies && authCompanies[0]) || ''
  );

  const headers = {
    status: { id: 'manApiKeys.status', size: 1 },
    creationDate: { id: 'manApiKeys.creationDate', size: 1 },
    description: { id: 'description', size: 2 },
    apiKey: { id: 'manApiKeys.shortenedApiKey', size: 2 },
    authDomains: { id: 'manApiKeys.authDomains', size: 4 },
    subColumn: {
      size: 2,
      isSubcolumn: true,
      columns: {
        safetyLevel: { id: 'manApiKeys.safetyLevel', size: 2, justifyContent: 'center' },
        actions: { id: 'manApiKeys.actions', size: 10, justifyContent: 'space-evenly' },
      },
    },
  };

  const handleCopy = (text) => (e) => {
    copyTextToClipboard(text, () => {
      dispatch(
        updateUi({
          snackbar: {
            message: intl.formatMessage({ id: 'manApiKeys.keyCopied' }),
            severity: 'success',
            duration: 3000,
            show: true,
          },
        })
      );
    });
  };

  const showConfirmationDialog = (eventType, apiKeyId, apiKeyData, company) => (e) => {
    if (e) e.preventDefault();

    const parentUid = apiKeyData.parentUid;
    let msgId, confirmAction, actionData;
    switch (eventType) {
      case dialogTypes.RENEW_API_KEY:
        msgId = 'renewApiKey';
        confirmAction = actions.RENEW_API_KEY;
        actionData = {
          apiKeyId,
          description: apiKeyData.description,
          company,
          parentUid,
        };
        break;
      case dialogTypes.REMOVE_API_KEY: {
        msgId = 'removeApiKey';
        confirmAction = actions.REMOVE_API_KEY;
        actionData = { apiKeyId, company, parentUid };
        break;
      }
      default:
        return;
    }

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: `manApiKeys.${msgId}Title` }),
          message: intl.formatMessage(
            { id: `manApiKeys.${msgId}Msg` },
            { description: apiKeyData.description, nl: <br key="nl0" /> }
          ),
          confirmAction,
          actionData,
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{intl.formatMessage({ id: `manApiKeys.${msgId}Check` })}</b>,
          show: true,
        },
      })
    );
  };

  const createRowData = (apiKeyId, apiKeyData, company) => {
    if (!apiKeyData) return null;

    let index = 0;
    const keyBase = apiKeyId.substring(4);
    const getKey = () => `${keyBase}${index++}`;

    const isInvalid = apiKeyData.status === 'INVALID';
    const style = isInvalid ? { color: palette.grey[600] } : {};
    const typogWrap = (content) => (
      <Typography style={style} key={getKey()}>
        {content}
      </Typography>
    );

    return {
      status: typogWrap(intl.formatMessage({ id: `manApiKeys.${apiKeyData.status}` })),
      creationDate: typogWrap(parseTimestampToDate(apiKeyData.creationDate).toLocaleDateString()),
      description: typogWrap(apiKeyData.description),
      authDomains: (
        <span
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', alignItems: 'center' }}
          key={getKey()}
        >
          <Typography style={{ wordWrap: 'anywhere', marginRight: 'auto', ...style }}>
            {apiKeyData.authDomains.length ? (
              apiKeyData.authDomains.map((domain, i) => (
                <Fragment key={`${domain}${i}`}>
                  {domain}
                  <br />
                </Fragment>
              ))
            ) : (
              <Box color={palette.grey[600]}>&lt;{intl.formatMessage({ id: 'none' })}&gt;</Box>
            )}
          </Typography>
          <Tooltip title={intl.formatMessage({ id: 'edit' })}>
            <span>
              <IconButton
                edge="end"
                aria-label={intl.formatMessage({ id: 'edit' })}
                component={Link}
                to={`/managekeys/${apiKeyId}/authDomains`}
                style={{ color: palette.info.main, margin: spacing(0, 1), ...style }}
                disabled={isInvalid}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </span>
      ),
      apiKey: (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'no-wrap',
            alignItems: 'center',
          }}
          key={getKey()}
        >
          <Typography style={{ wordWrap: 'anywhere', ...style }}>
            ...{apiKeyData.token.substring(apiKeyData.token.length - 60)}
          </Typography>
          <Tooltip title={intl.formatMessage({ id: 'copy' })}>
            <span>
              <IconButton
                edge="end"
                aria-label={intl.formatMessage({ id: 'copy' })}
                onClick={handleCopy(apiKeyData.token)}
                style={{ color: palette.info.main, margin: spacing(0, 1), ...style }}
                disabled={isInvalid}
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </span>
      ),
      subColumn: {
        isSubcolumn: true,
        columns: {
          safetyLevel: (() => {
            const creationDate = parseTimestampToDate(apiKeyData.creationDate);
            const timeSinceCreationDate = new Date().getTime() - creationDate.getTime();

            if (isInvalid || timeSinceCreationDate < 9 * MsInAMonth) {
              return <CheckCircleOutlineIcon style={{ color: palette.success.main }} key={getKey()} />;
            } else if (timeSinceCreationDate < MsInAYear) {
              return <WarningRoundedIcon style={{ color: palette.warning.main }} key={getKey()} />;
            } else {
              return <WarningRoundedIcon style={{ color: palette.error.main }} key={getKey()} />;
            }
          })(),
          actions: (
            <Fragment key={getKey()}>
              <Tooltip title={intl.formatMessage({ id: 'renew' })}>
                <span>
                  <IconButton
                    edge="end"
                    aria-label={intl.formatMessage({ id: 'renew' })}
                    style={{ color: palette.info.main, ...style }}
                    onClick={showConfirmationDialog(dialogTypes.RENEW_API_KEY, apiKeyId, apiKeyData, company)}
                    disabled={isInvalid}
                  >
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                <IconButton
                  edge="end"
                  aria-label={intl.formatMessage({ id: 'remove' })}
                  style={{ color: palette.error.main }}
                  onClick={showConfirmationDialog(dialogTypes.REMOVE_API_KEY, apiKeyId, apiKeyData, company)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          ),
        },
      },
    };
  };

  const justifyContentWrapper = (justifyContent, children) => (
    <div style={justifyContent ? { display: 'flex', justifyContent } : {}}> {children} </div>
  );

  const renderHeaders = () =>
    Object.values(headers).map((headerData, i) =>
      headerData.isSubcolumn ? (
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={headerData.size}
          key={`hc${i}`}
        >
          {Object.values(headerData.columns).map((subHeaderData, si) => (
            <Grid item key={`hi${i}${si}`} xs={subHeaderData.size}>
              {justifyContentWrapper(
                subHeaderData.justifyContent,
                <Typography>{intl.formatMessage({ id: subHeaderData.id })}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item key={`hi${i}`} xs={headerData.size} style={{ border: '1px black' }}>
          {justifyContentWrapper(
            headerData.justifyContent,
            <Typography> {intl.formatMessage({ id: headerData.id })}</Typography>
          )}
        </Grid>
      )
    );

  const renderApiKeys = (apiKeys, company) =>
    Object.entries(apiKeys).map(([apiKeyId, apiKeyData], index) => {
      const rowData = createRowData(apiKeyId, apiKeyData, company);
      if (!rowData) return null;

      return Object.entries(headers).map(([headerKey, headerData], i) => {
        const columnData = rowData[headerKey];

        return headerData.isSubcolumn ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={headerData.size}
            key={`hvc${i}`}
          >
            {Object.entries(headerData.columns).map(([headerKey, subHeaderData], si) => {
              const subColumnData = columnData.columns[headerKey];
              return (
                <Grid item key={`${headerKey}${i}${si}`} xs={subHeaderData.size}>
                  {justifyContentWrapper(subHeaderData.justifyContent, subColumnData)}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid item xs={headerData.size} key={`${headerKey}${index}`}>
            {justifyContentWrapper(headerData.justifyContent, columnData)}
          </Grid>
        );
      });
    });

  const renderApiKeysTables = () => {
    const apiKeysPerCompany = groupByCompany(apiKeysData);
    return apiKeysData.isLoaded ? (
      <TabContext value={selectedCompany}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(e, selected) => {
              localStorage.setItem('selectedCompany', selected);
              setSelectedCompany(selected);
            }}
            aria-label="Company tabs"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabContainer}
          >
            {authCompanies.map((company, idx) => (
              <Tab label={company} value={company} key={`label${idx}`} />
            ))}
          </TabList>
        </Box>
        {authCompanies.map((company, idx) => (
          <TabPanel value={company} key={`panel${idx}`}>
            <Grid
              key={`companyCont${Math.random()}`}
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.table}
            >
              {Object.keys(apiKeysPerCompany[company] || {}).length ? (
                <>
                  {renderHeaders()}
                  {renderApiKeys(apiKeysPerCompany[company], company)}
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography>
                    {intl.formatMessage(
                      { id: 'manApiKeys.noKeys' },
                      {
                        clickHere: (
                          <Button component={Link} to="/managekeys/create" size="small" key={'createKeyBtn'}>
                            {intl.formatMessage({ id: 'clickHere' })}
                          </Button>
                        ),
                      }
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </TabPanel>
        ))}
      </TabContext>
    ) : (
      <Skeleton type="rect" className={classes.skeleton} animation="wave" style={{ width: '100%' }} />
    );
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" style={text.title}>
        {intl.formatMessage({ id: 'manApiKeys.title' })}
      </Typography>
      <Typography variant="subtitle1" style={text.subtitle}>
        {intl.formatMessage({ id: 'manApiKeys.subtitle' })}
      </Typography>

      {renderApiKeysTables()}

      <Fab
        variant="extended"
        size="medium"
        color="secondary"
        aria-label="add"
        component={Link}
        to="/managekeys/create"
        style={{ marginTop: spacing(4) }}
      >
        <AddCircleIcon style={{ marginRight: spacing(1) }} />
        {intl.formatMessage({ id: 'manApiKeys.createKey' })}
      </Fab>
    </Container>
  );
}
