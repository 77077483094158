import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { TextField, Typography, Container, makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import { isLoaded } from 'react-redux-firebase';
import { login, logout } from '../../redux/actions/userActions';
import LoadingCircle from '../widgets/LoadingCircle';
import LoadingButton from '../widgets/LoadingButton';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.other.grey.dark,
  },
  container: { ...theme.container(4) },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  const intl = useIntl();
  const { auth, profile, ui } = useSelector(({ firebase: { auth, profile }, ui }) => ({
    auth,
    ui,
    profile,
  }));

  // Logout if not authorized to use this platform (only key users are allowed)
  useEffect(() => {
    if (isLoaded(auth, profile)) {
      if (auth.uid && profile && !profile.keyUser) {
        dispatch(logout(true, intl));
      }
    }
  }, [intl, dispatch, auth, profile]);

  return isLoaded(auth) ? (
    !auth.uid ? (
      <>
        <div className={classes.grow} />
        <Container className={classes.container}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" className={clsx(classes.margin, classes.title)}>
              {intl.formatMessage({ id: 'login' })}
            </Typography>
            <TextField
              fullWidth
              required
              autoComplete="email"
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.margin}
              autoFocus
            />
            <TextField
              fullWidth
              required
              autoComplete="current-password"
              id="password"
              name="password"
              type="password"
              label={intl.formatMessage({ id: 'password' })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.margin}
              inputProps={{ minLength: 6 }}
            />

            <div className={classes.buttonsContainer}>
              <LoadingButton
                isLoading={ui.loadingButton.isLoginLoading}
                content={intl.formatMessage({ id: 'submit' })}
                type="submit"
              />
              <Button
                disabled={ui.loadingButton.isLoginLoading}
                variant="outlined"
                color="primary"
                className={classes.forgotPwButton}
                onClick={() => history.push('/forgotpw')}
              >
                {intl.formatMessage({ id: 'forgotPassword' })}
              </Button>
            </div>
          </form>
        </Container>
        <div className={classes.grow} />
      </>
    ) : Boolean(profile && profile.keyUser) ? (
      <Redirect to="/" />
    ) : (
      <LoadingCircle />
    )
  ) : (
    <LoadingCircle />
  );
}
