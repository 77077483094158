import { removeUser, editUser, logout } from './userActions';
import { removeSessions, restartTask, removeTask, triggerSessionPush } from './operationalActions';
import {
  removeDomain,
  removeLicense,
  editDomainSettings,
  resetPassword,
  removeManualInputField,
  addLabelDesign,
  editLabelDesign,
  removeLabelDesign,
  renewApiKey,
  removeApiKey,
} from './managementActions';
import { ui } from '../types';

export const actions = {
  // User Actions
  EDIT_USER: 'EDIT_USER',
  REMOVE_USER: 'REMOVE_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  LOGOUT: 'LOGOUT',
  // Domain actions
  REMOVE_SESSIONS: 'REMOVE_SESSIONS',
  REMOVE_DOMAIN: 'REMOVE_DOMAIN',
  TRIGGER_SESSION_PUSH: 'TRIGGER_SESSION_PUSH',
  // Inventory task actions
  ADD_INVENTORY_TASK: 'ADD_INVENTORY_TASK',
  RESTART_INVENTORY_TASK: 'RESTART_INVENTORY_TASK',
  REMOVE_INVENTORY_TASK: 'REMOVE_INVENTORY_TASK',
  //Domain Settings Actions
  EDIT_DOMAIN_SETTINGS: 'EDIT_DOMAIN_SETTINGS',
  ADD_LABEL_DESIGN: 'ADD_LABEL_DESIGN',
  EDIT_LABEL_DESIGN: 'EDIT_LABEL_DESIGN',
  REMOVE_LABEL_DESIGN: 'REMOVE_LABEL_DESIGN',
  REMOVE_MANUAL_INPUT: 'REMOVE_MANUAL_INPUT',
  REMOVE_LICENSE: 'REMOVE_LICENSE',
  // API Key management
  RENEW_API_KEY: 'RENEW_API_KEY',
  REMOVE_API_KEY: 'REMOVE_API_KEY',
};

export const handleAction = (dispatch, action, data) => {
  switch (action) {
    // User Actions
    case actions.REMOVE_USER:
      dispatch(removeUser(data));
      break;
    case actions.EDIT_USER:
      dispatch(editUser(data));
      break;
    case actions.RESET_PASSWORD:
      dispatch(resetPassword(data));
      break;
    case actions.LOGOUT:
      dispatch({ type: ui.UPDATE_UI, data: null });
      dispatch(logout());
      break;
    // Domain actions
    case actions.REMOVE_SESSIONS:
      dispatch(removeSessions(data));
      break;
    case actions.REMOVE_DOMAIN:
      dispatch(removeDomain(data));
      break;
    case actions.TRIGGER_SESSION_PUSH:
      dispatch(triggerSessionPush(data));
      break;
    // Inventory task actions
    case actions.RESTART_INVENTORY_TASK:
      dispatch(restartTask(data));
      break;
    case actions.REMOVE_INVENTORY_TASK:
      dispatch(removeTask(data));
      break;
    //Domain Settings Actions
    case actions.EDIT_DOMAIN_SETTINGS:
      dispatch(editDomainSettings(data));
      break;
    case actions.ADD_LABEL_DESIGN:
      dispatch(addLabelDesign(data));
      break;
    case actions.EDIT_LABEL_DESIGN:
      dispatch(editLabelDesign(data));
      break;
    case actions.REMOVE_LABEL_DESIGN:
      dispatch(removeLabelDesign(data));
      break;
    case actions.REMOVE_MANUAL_INPUT:
      dispatch(removeManualInputField(data));
      break;
    case actions.REMOVE_LICENSE:
      dispatch(removeLicense(data));
      break;
    // API Key management
    case actions.RENEW_API_KEY:
      dispatch(renewApiKey(data));
      break;
    case actions.REMOVE_API_KEY:
      dispatch(removeApiKey(data));
      break;
    default:
      return;
  }
};
