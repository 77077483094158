export const storeCheckState = (key, setter) => (e) => {
  setter(e.target.checked);
  localStorage.setItem(key, e.target.checked);
};

export const storeValueState = (key, setter) => (e) => {
  setter(e.target.value);
  localStorage.setItem(key, e.target.value);
};

export const getStoredOrDefault = (key, def) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value !== null ? value : def);
  } catch (e) {
    return value !== null ? value : def;
  }
};
