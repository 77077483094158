import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import managementReducer from './managementReducer';
import userReducer from './userReducer';
import uiReducer from './uiReducer';
import pictureReducer from './pictureReducer';
import localeReducer from './localeReducer';

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  locale: localeReducer,
  mng: managementReducer,
  picture: pictureReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  logger: (state = {}, action) => {
    if (typeof process.env.REACT_APP_ENV === 'string' && !process.env.REACT_APP_ENV.includes('production')) {
      console.log(action.type, action.data);
    }
    return state;
  },
});

export default rootReducer;
