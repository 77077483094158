import React from 'react';
import { Typography, Container, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container(1), marginLeft: 0 },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const profile = useSelector((state) => state.firebase.profile);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.margin}>
        <FormattedMessage id="main.title" values={{ firstName: profile.firstName, lastName: profile.lastName }} />
      </Typography>
      <Typography variant="subtitle1" className={classes.margin}>
        <FormattedMessage id="main.msg" />
      </Typography>
    </Container>
  );
}
