import React from 'react';
import { Container, Typography, makeStyles, Button } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  iconStyle: {
    color: theme.palette.error.main,
    fontSize: 75,
  },
}));

export default function BadRequest(props) {
  const classes = useStyles();
  const { text, iconProps, link, extraView } = props;

  return (
    <Container className={classes.centeredColumn}>
      <WarningRoundedIcon className={classes.iconStyle} style={iconProps} />
      {text && text.length && (
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      )}
      {link && link.text && link.actionCallback && (
        <Button className={classes.link} onClick={link.actionCallback}>
          {link.text}
        </Button>
      )}
      {extraView && extraView()}
    </Container>
  );
}
