import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import * as xlsx from 'xlsx';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi, updateUiNoReset } from '../../redux/actions/uiActions';
import { selectCompanyData, selectDomainSessions, selectDomainSettings } from '../../redux/selectors/userSelectors';
import { ELLIPSIS } from '../../util/helpers/const';
import { getDateMembers, parseEpochFromTimestamp } from '../../util/helpers/other';
import { getSessionsPath } from '../../util/helpers/path';
import { excelTabInvalidChars, getHeaderCellsFromObject, getInflatedSessionData } from '../../util/helpers/table';
import { getStoredOrDefault, storeCheckState, storeValueState } from '../../util/web';
import { parseTableData } from '../../workers/dataWorkerApi';
import EnhancedTable from './EnhancedTable/EnhancedTable';

const getHeaderFields = (usePushStatus) =>
  ['name', usePushStatus ? 'pushStatus' : null, 'startTime', 'total', 'empty', 'invalid', 'endTime'].filter(Boolean);

const dateFormat = 'YYYY/MM/DD HH:MM:SS';

const OUTPUT_TYPES = {
  XLSX_SESSION_PER_TAB: 'XLSX_SESSION_PER_TAB',
  XLSX_SESSION_PER_FILE: 'XLSX_SESSION_PER_FILE',
  CSV_SESSION_PER_FILE: 'CSV_SESSION_PER_FILE',
  XLSX_MERGED_SINGLE_FILE: 'XLSX_MERGED_SINGLE_FILE',
  CSV_MERGED_SINGLE_FILE: 'CSV_MERGED_SINGLE_FILE',
};

export default function Domain(props) {
  const intl = useIntl();
  const theme = useTheme();
  const { match } = props;

  const company = match.params.company;
  const domain = match.params.domain;

  const dispatch = useDispatch();
  const { sessionsData, settingsData, companyData } = useSelector((state) => ({
    companyData: selectCompanyData(company, state).companyData,
    sessionsData: selectDomainSessions(company, domain, state),
    settingsData: selectDomainSettings(company, domain, state),
  }));

  const { isLoaded: areSessionsLoaded, data: sessions } = sessionsData;
  const { isLoaded: areSettingsLoaded, data: settings } = settingsData;

  useFirestoreConnect([
    {
      collection: getSessionsPath(company, domain),
      storeAs: `sess${company}${domain}`,
      orderBy: ['startTime', 'desc'],
      limit: 100,
    },
  ]);

  const [mergeLabels, setMergeLabels] = useState(getStoredOrDefault('exportMergeLabels', true));
  const [showInputData, setShowInputData] = useState(getStoredOrDefault('exportShowInputData', false));
  const [showBaseFields, setShowBaseFields] = useState(getStoredOrDefault('exportShowBaseFields', true));
  const [outputType, setOutputType] = useState(
    getStoredOrDefault('exportOutputTypeRev2', OUTPUT_TYPES.XLSX_SESSION_PER_TAB)
  );

  const [selected, setSelected] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  const onTriggerSessionPushClick = (session) => {
    // Get epoch of next full hour time
    const ref = new Date();
    ref.setHours(ref.getHours() + 1, 0, 0);
    // Calculate the number of minutes until next full hour
    const minToRetry = Math.round((ref.getTime() - Date.now()) / 1000 / 60);

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'domain.triggerSessionPushTitle' }),
          message: intl.formatMessage({ id: 'domain.triggerSessionPushMsg' }, { minToRetry }),
          confirmAction: actions.TRIGGER_SESSION_PUSH,
          actionData: { company, domain, session: session.name },
          show: true,
        },
      })
    );
  };

  const onRemoveTasksClick = (selectedSessions) => (e) => {
    e.preventDefault();

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'domain.remSessionsTitle' }),
          message: intl.formatMessage({ id: 'domain.remSessionsMsg' }),
          checkboxMessage: intl.formatMessage({ id: 'domain.remSessionsCheckbox' }),
          useCheckbox: true,
          confirmAction: actions.REMOVE_SESSIONS,
          actionData: { company, domain, session: selectedSessions },
          show: true,
        },
      })
    );
  };

  const data = React.useMemo(() => {
    let columns = [],
      rows = [];
    if (areSessionsLoaded) {
      const usePushStatus = !!companyData?.hasSessionPushListener;
      const headerFieldsTranslation = {};
      getHeaderFields(usePushStatus).forEach((field) => {
        headerFieldsTranslation[field] = intl.formatMessage({ id: `domain.${field}` });
      });

      columns = getHeaderCellsFromObject(headerFieldsTranslation);
      rows = [];

      for (let key in sessions) {
        let session = sessions[key]; // session
        if (session) {
          rows.push({
            name: key,
            startTime: session.startTime,
            total: session.total ? session.total : null,
            empty: session.empty ? session.empty : '0',
            invalid: session.invalid ? session.invalid : '0',
            endTime: session.endTime,
            pushStatus: usePushStatus ? session.pushStatus : null,
          });
        }
      }
    }
    return { rows, columns };
  }, [areSessionsLoaded, sessions, intl, companyData]);

  const leftAlignedButtons = (
    <Button
      disableElevation
      size="medium"
      aria-label={intl.formatMessage({ id: 'domain.inventoryTasks' })}
      component={Link}
      to={`/company/${company}/domains/${domain}/tasks`}
    >
      <ListIcon style={{ marginRight: theme.spacing(1) }} />
      {intl.formatMessage({ id: 'domain.inventoryTasks' })}
    </Button>
  );

  const missingDataAlternative = () => {
    const path = `/company/${company}/domains/${domain}/tasks`;
    return (
      <Typography variant="h6">
        {intl.formatMessage(
          { id: 'domain.missingDataText' },
          {
            domain,
            btn: (
              <Button component={Link} to={path} size="small" key={path}>
                {intl.formatMessage({ id: 'clickHere' })}
              </Button>
            ),
          }
        )}
      </Typography>
    );
  };

  const showPreparationBackdrop = () =>
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'domain.preparingFiles', msg: 'Preparing file(s) for download...' },
        },
      })
    );

  const hidePreparationBackdrop = () => dispatch(updateUiNoReset({ backdrop: { show: false } }));

  const renderDialog = () => (
    <Dialog open={showDialog} onClose={(e, r) => setShowDialog(false)} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'domain.exportTitle' })}</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: theme.spacing(2) }}>{intl.formatMessage({ id: 'domain.selectExportOptions' })}</div>
        {renderExportOptions()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDialog(false)} color="primary" autoFocus>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          onClick={() => {
            setShowDialog(false);
            exportSessions();
          }}
          color="primary"
        >
          {intl.formatMessage({ id: 'confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderExportOptions = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="export-type-label">{intl.formatMessage({ id: 'domain.exportOutputType' })}</InputLabel>
        <Select
          labelId="export-type-label"
          id="export-type"
          value={outputType}
          onChange={storeValueState('exportOutputType', setOutputType)}
          style={{ marginBottom: theme.spacing(2) }}
        >
          {Object.keys(OUTPUT_TYPES).map((type) => (
            <MenuItem key={type} value={type}>
              {intl.formatMessage({ id: `domain.${type}` })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Checkbox checked={mergeLabels} onChange={storeCheckState('exportMergeLabels', setMergeLabels)} />}
        label={<Typography variant="body2">{intl.formatMessage({ id: 'session.mergeLabels' })}</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox checked={showInputData} onChange={storeCheckState('exportShowInputData', setShowInputData)} />
        }
        label={<Typography variant="body2">{intl.formatMessage({ id: 'session.inputFormat' })}</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox checked={showBaseFields} onChange={storeCheckState('exportShowBaseFields', setShowBaseFields)} />
        }
        label={<Typography variant="body2">{intl.formatMessage({ id: 'session.showBaseFields' })}</Typography>}
      />
    </div>
  );

  const exportSessions = () => {
    switch (outputType) {
      case OUTPUT_TYPES.XLSX_SESSION_PER_TAB: {
        exportSessionPerTab();
        break;
      }
      case OUTPUT_TYPES.XLSX_SESSION_PER_FILE: {
        exportSessionPerFile(true);
        break;
      }
      case OUTPUT_TYPES.CSV_SESSION_PER_FILE: {
        exportSessionPerFile(false);
        break;
      }
      case OUTPUT_TYPES.CSV_MERGED_SINGLE_FILE: {
        exportMergedSessions(false);
        break;
      }
      case OUTPUT_TYPES.XLSX_MERGED_SINGLE_FILE: {
        exportMergedSessions(true);
        break;
      }
      default: {
        alert('Unknown output type!');
        break;
      }
    }
  };

  const exportSessionPerFile = (isXlsx) => {
    if (Array.isArray(selected) && selected.length) {
      let filename = '';
      const extension = isXlsx ? 'xlsx' : 'csv';
      const zip = new JSZip();
      const sett = areSettingsLoaded ? settings : undefined;

      if (!sett) {
        hidePreparationBackdrop();
        return;
      } else {
        showPreparationBackdrop();
      }

      Promise.all(
        selected.map((session, sessionIndex) => {
          if (sessionIndex === 0) {
            filename = session;
          } else if (sessionIndex === selected.length - 1) {
            filename += ` to ${session}`;
          }

          return parseTableData(sessions[session], sett, showInputData, showBaseFields, mergeLabels).then((data) => {
            const { columns: tableColumns, rows: tableRows } = data;

            const columns = tableColumns.map((column) => column.label);
            const rows = tableRows.map((row) => tableColumns.map((column) => row[column.id]));

            let sessionName = session.replace(excelTabInvalidChars, '-');
            if (sessionName.length > 30) {
              sessionName = `${sessionName.substring(0, 29)}${ELLIPSIS}`;
            }

            let wb = xlsx.utils.book_new();
            let ws = xlsx.utils.aoa_to_sheet([columns, ...rows], { dateNF: dateFormat });
            xlsx.utils.book_append_sheet(wb, ws, sessionName);
            const wbout = xlsx.write(wb, { bookType: extension, bookSST: true, type: 'binary' });

            // Write file to zip
            zip.file(`${sessionName}.${extension}`, wbout, { binary: true });
          });
        })
      )
        .then(() => zip.generateAsync({ type: 'blob' }))
        .then((content) => saveAs(content, `${filename}.zip`))
        .catch((err) => console.log(err.message))
        .finally(hidePreparationBackdrop);
    } else {
      console.log('Missing data!');
    }
  };

  const exportSessionPerTab = () => {
    if (Array.isArray(selected) && selected.length) {
      let filename = '';
      let wb = xlsx.utils.book_new();
      const sett = areSettingsLoaded ? settings : undefined;

      if (!sett) {
        hidePreparationBackdrop();
        return;
      } else {
        showPreparationBackdrop();
      }

      Promise.all(
        selected.map((session, sessionIndex) => {
          if (sessionIndex === 0) {
            filename = session;
          } else if (sessionIndex === selected.length - 1) {
            filename += ` to ${session}`;
          }

          return parseTableData(sessions[session], sett, showInputData, showBaseFields, mergeLabels).then((data) => {
            const { columns: tableColumns, rows: tableRows } = data;

            const columns = tableColumns.map((column) => column.label);
            const rows = tableRows.map((row) => tableColumns.map((column) => row[column.id]));

            let sessionName = session.replace(excelTabInvalidChars, '-');
            if (sessionName.length > 30) {
              sessionName = `${sessionName.substring(0, 29)}${ELLIPSIS}`;
            }

            let ws = xlsx.utils.aoa_to_sheet([columns, ...rows], { dateNF: dateFormat });
            xlsx.utils.book_append_sheet(wb, ws, sessionName);
          });
        })
      )
        .then(() => xlsx.writeFile(wb, `${filename}.xlsx`, {}))
        .catch((err) => console.log(err.message))
        .finally(hidePreparationBackdrop);
    } else {
      console.log('Missing data!');
    }
  };

  const exportMergedSessions = (isXlsx) => {
    if (Array.isArray(selected) && selected.length) {
      const extension = isXlsx ? 'xlsx' : 'csv';

      if (settings && sessions) {
        showPreparationBackdrop();
      } else {
        hidePreparationBackdrop();
        return;
      }

      Promise.all(
        selected.map(async (session, sessionIndex) => {
          const sessionData = sessions[session];
          if (!sessionData) {
            console.error(`Missing data for session ${session}`);
            return;
          }

          const inflatedSession = {
            ...sessionData,
            name: session,
            addresses: await getInflatedSessionData(sessionData.addresses),
          };
          return inflatedSession;
        })
      )
        .then(async (sessionDataList) => {
          const checkedAtKey = settings.baseFields.checkedAt;
          let referenceSession = null;
          sessionDataList.forEach(async (data, idx) => {
            if (!data) {
              console.log('Failed parsing data for session!');
              return;
            }

            if (!referenceSession) {
              referenceSession = { ...data };
              return;
            }

            const { addresses } = data;
            Object.entries(addresses).forEach(([address, addressData]) => {
              if (referenceSession.addresses[address]) {
                const latestExistingCapture = referenceSession.addresses[address].reduce((res, curr) => {
                  const thisChecketAt = parseEpochFromTimestamp(curr[checkedAtKey] ?? 0) ?? 0;
                  return Math.max(res, thisChecketAt);
                }, 0);
                const latestNewCapture = addressData.reduce((res, curr) => {
                  const thisChecketAt = parseEpochFromTimestamp(curr[checkedAtKey] ?? 0) ?? 0;
                  return Math.max(res, thisChecketAt);
                }, 0);

                if (latestNewCapture > latestExistingCapture) {
                  referenceSession.addresses[address] = addressData;
                }
              } else {
                referenceSession.addresses[address] = addressData;
              }
            });
          });

          if (!referenceSession) {
            throw Error('Failed exporting data!');
          }

          return parseTableData(referenceSession, settings, showInputData, showBaseFields, mergeLabels);
        })
        .then(async ({ columns: tableColumns, rows: tableRows }) => {
          const { yyyy, mm, dd, epoch } = getDateMembers(new Date());
          const filename = [yyyy, mm, dd, ' - ', epoch].join('');

          const columns = tableColumns.map((column) => column.label);
          const rows = tableRows.map((row) => tableColumns.map((column) => row[column.id]));

          const { utils, writeFile } = xlsx;
          const wb = utils.book_new();
          const ws = utils.aoa_to_sheet([columns, ...rows], {
            dateNF: dateFormat,
          });
          utils.book_append_sheet(wb, ws, filename);

          writeFile(wb, `${filename}.${extension}`, {
            bookType: extension,
            bookSST: true,
          });
        })
        .catch((err) => {
          console.log(err);
          updateUi({
            snackbar: {
              message: err.message,
              show: true,
              severity: 'error',
            },
          });
        })
        .finally(() => hidePreparationBackdrop());
    } else {
      console.log('Missing data!');
    }
  };

  return (
    <>
      <EnhancedTable
        tableId="domain"
        data={data}
        dataLoaded={areSessionsLoaded}
        clickAction={(row, field) => {
          if (field.id === 'name') {
            return {
              isLink: true,
              getLinkPath: (row, field) => `/company/${company}/domains/${domain}/sessions/${row[field.id]}`,
            };
          } else return undefined;
        }}
        title={intl.formatMessage({ id: 'domain.title' }, { domain })}
        showTitle
        onTriggerSessionPushClick={(row) => {
          if (row.pushStatus !== 'PUSHED') onTriggerSessionPushClick(row);
        }}
        toolbarProps={{
          showLink: false,
          linkText: intl.formatMessage({ id: 'domain.btn0' }),
          linkPath: `/company/${company}/domains/${domain}/all`,
          exportButtonIfSelection: true,
          customExports: [
            {
              callback: (e, selected) => {
                setSelected(selected);
                setShowDialog(true);
              },
            },
          ],
          deleteButton: {
            title: intl.formatMessage({ id: 'domain.removeSelectedSessions' }),
            onAction: onRemoveTasksClick,
          },
          leftAlignedButtons,
        }}
        missingDataAlternative={missingDataAlternative}
      />
      {renderDialog()}
    </>
  );
}
