import { user } from '../types';

const initialState = {
  profilePics: {},
  profilePicThumbs: {}
};

const initialPictureData = {
  src: undefined,
  isLoaded: false,
  isLoading: false,
};

const userReducer = (state = initialState, action) => {
  let retState = state;

  switch (action.type) {
    case user.PROFILE_IMAGE_DATA_CHANGED: {
      const uid = action.data.uid
      if (uid) {
        delete action.data.uid
        retState = { ...state };
        const currProfile = retState.profilePics[uid] || { ...initialPictureData };
        retState.profilePics[uid] = Object.assign(currProfile, action.data);
      }
      break;
    }
    case user.PROFILE_IMAGE_THUMB_CHANGED: {
      const uid = action.data.uid
      if (uid) {
        retState = { ...state };
        const currProfile = retState.profilePicThumbs[uid] || { ...initialPictureData };
        retState.profilePicThumbs[uid] = Object.assign(currProfile, action.data);
      }
      break;
    }
    default:
      break;
  }
  return retState;
};

export default userReducer;
