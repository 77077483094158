import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addManualInputField, editManualInputField } from '../../redux/actions/managementActions';
import BadRequest from '../widgets/BadRequest';
import LoadingButton from '../widgets/LoadingButton';
// eslint-disable-next-line
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import { updateUi } from '../../redux/actions/uiActions';
import { selectDomainSettings, selectParentCandidateModerators } from '../../redux/selectors/userSelectors';
import LogoTooltip from './../widgets/LogoTooltip';

const useStyles = makeStyles(({ container, palette, spacing }) => ({
  container: {
    ...container(1),
    marginLeft: 0,
    maxWidth: '1000px',
  },
  title: {
    color: palette.other.grey.dark,
    marginBottom: spacing(2),
  },
  margin: { marginBottom: spacing(2) },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%',
  },
  iconRoot: {
    padding: spacing(1.5),
    backgroundColor: palette.primary.main,
    borderRadius: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: spacing(2),
    height: spacing(2),
  },
}));

const initialMif = {
  // Current field-to-be-added values
  field: '',
  description: '',
  checkField: '',
  useCheckField: false,
  unity: '',
  inputType: 0,
  positiveActionType: 0,
  negativeActionType: 0,
  mandatory: false,
  showHistory: true,
  isSummable: false,
  maxAttempts: 0,
};

const fieldTypes = ['integer', 'decimal', 'text', 'binary'];
const actionTypes = ['none', 'picture', 'picture_on_divergence'];
const isSummable = (typeIndex) => typeIndex < 2;

const getManualInputField = (settings, index) => {
  if (settings?.manualInputFields && index > -1 && index < settings.manualInputFields.length) {
    const mif = settings.manualInputFields[index];
    return { ...initialMif, ...mif };
  } else return undefined;
};

export default function ManualInputField(props) {
  const { company, domain, index } = props.match.params;
  const { ui, settingsData, candidateModeratorsData } = useSelector((state) => {
    return {
      ui: state.ui,
      settingsData: selectDomainSettings(company, domain, state),
      candidateModeratorsData: selectParentCandidateModerators(state, company, domain),
    };
  });

  const { isLoaded: settLoaded, data: sett } = settingsData;
  const { isLoaded: modsLoaded, data: candidateModerators } = candidateModeratorsData;

  const getInitialMif = () => {
    if (settLoaded) {
      const manualInputfield = getManualInputField(sett, index);
      if (!!manualInputfield) {
        manualInputfield.inputType = fieldTypes.indexOf(manualInputfield.inputType);
        manualInputfield.positiveActionType = actionTypes.indexOf(manualInputfield.positiveActionType);
        manualInputfield.negativeActionType = actionTypes.indexOf(manualInputfield.negativeActionType);
        return { ...manualInputfield };
      }
    }
    return { ...initialMif };
  };

  const [mif, setMif] = useState(getInitialMif());
  const [isCheckFieldFocused, setIsCheckFieldFocused] = useState(false);

  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const onFocusListener = () => setIsCheckFieldFocused(document.activeElement.id === 'checkField');

  useEffect(() => {
    document.addEventListener('focusin', onFocusListener);
    return () => document.removeEventListener('focusin', onFocusListener);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const [key, value] = [e.target.name, e.target.value];

    let sideEffects = {};
    // Force summable to false if not a summable type
    if (key === 'inputType' && !isSummable(value)) {
      sideEffects.isSummable = false;
    }
    setMif({ ...mif, [key]: value, ...sideEffects });
  };

  const handleCheckboxChange = (name) => (event) => setMif({ ...mif, [name]: event.target.checked });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { state } = props.location;

    const type = state && state.type;
    if (isEmpty(type || !modsLoaded)) return;

    const newData = { ...mif };

    newData.maxAttempts = Number(newData.maxAttempts);
    newData.inputType = fieldTypes[newData.inputType];
    newData.positiveActionType = actionTypes[newData.positiveActionType];
    newData.negativeActionType = actionTypes[newData.negativeActionType];
    newData.checkField = newData.useCheckField ? newData.checkField || newData.field : '';

    const candidatesCount = Object.keys(candidateModerators).length;
    if (candidatesCount !== 1) {
      dispatch(
        updateUi({
          snackbar: {
            message: intl.formatMessage({
              id: candidatesCount > 1 ? 'manDom.errMultipleParents' : 'manDom.errMissingParent',
            }),
            severity: 'error',
            show: true,
          },
        })
      );
      return;
    }
    const parentUid = Object.keys(candidateModerators)[0];

    if (type === 'add') {
      dispatch(addManualInputField({ newData, company, domain, parentUid }));
    } else if (type === 'edit') {
      dispatch(
        editManualInputField({
          newData,
          currentData: sett && sett.manualInputFields,
          company,
          domain,
          parentUid,
          index,
        })
      );
    }
  };

  const type = props.location && props.location.state ? props.location.state.type : null;

  const editOrAdd = (edit, add) => (type === 'edit' ? edit : add);

  return type ? (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {intl.formatMessage(
          { id: 'mif.title' },
          {
            action: intl.formatMessage({ id: editOrAdd('edit', 'add') }),
            atTo: intl.formatMessage({ id: editOrAdd('at', 'for') }),
            domain,
          }
        )}
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield, inputType | action, Max attempts */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label={intl.formatMessage({ id: 'mif.inputType' })}
              variant="outlined"
              value={mif.inputType}
              onChange={handleChange}
              inputProps={{ name: 'inputType', id: 'type-select' }}
            >
              {fieldTypes.map((value, index) => (
                <MenuItem value={index} key={value}>
                  {intl.formatMessage({ id: `mif.${fieldTypes[index]}` })}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="field"
              name="field"
              type="text"
              label={intl.formatMessage({ id: 'mif.field' })}
              value={mif.field}
              onChange={handleChange}
              className={classes.margin}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="description"
              name="description"
              type="text"
              label={intl.formatMessage({ id: 'description' })}
              value={mif.description}
              onChange={handleChange}
              className={classes.margin}
              autoFocus
            />
          </Grid>
          {mif.inputType !== fieldTypes.indexOf('binary') ? (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                id="unity"
                name="unity"
                type="text"
                label={`${intl.formatMessage({ id: 'mif.unity' })} (${intl.formatMessage({
                  id: 'optional',
                })})`}
                value={mif.unity}
                onChange={handleChange}
                className={classes.margin}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label={intl.formatMessage({ id: 'mif.positiveActionType' })}
                variant="outlined"
                value={mif.positiveActionType}
                onChange={handleChange}
                inputProps={{ name: 'positiveActionType', id: 'pos-action-select' }}
              >
                {actionTypes.map((value, index) => (
                  <MenuItem value={index} key={value}>
                    {intl.formatMessage({ id: `mif.${actionTypes[index]}` })}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                label={intl.formatMessage({ id: 'mif.negativeActionType' })}
                variant="outlined"
                value={mif.negativeActionType}
                onChange={handleChange}
                style={{ marginTop: theme.spacing(2) }}
                inputProps={{ name: 'negativeActionType', id: 'neg-action-select' }}
              >
                {actionTypes.map((value, index) => (
                  <MenuItem value={index} key={value}>
                    {intl.formatMessage({ id: `mif.${actionTypes[index]}` })}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} lg={mif.useCheckField ? 5 : 12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mif.useCheckField}
                  color="secondary"
                  onChange={handleCheckboxChange('useCheckField')}
                />
              }
              label={intl.formatMessage({ id: 'mif.useCheckField' })}
              labelPlacement="end"
              className={classes.margin}
            />
          </Grid>
          {mif.useCheckField && (
            <>
              <Grid item xs={12} md={7} lg={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="checkField"
                  name="checkField"
                  type="text"
                  label={
                    isCheckFieldFocused
                      ? intl.formatMessage({ id: 'mif.checkField' })
                      : !!mif.checkField
                      ? intl.formatMessage({ id: 'mif.checkField' })
                      : (mif.checkField && `<${mif.checkField}>`) ||
                        (mif.field && `<${mif.field}>`) ||
                        intl.formatMessage({ id: 'mif.checkField' })
                  }
                  value={mif.checkField}
                  onChange={handleChange}
                  className={classes.margin}
                />
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="maxAttempts"
                  name="maxAttempts"
                  type="number"
                  label={intl.formatMessage({ id: 'mif.maxAttempts' })}
                  value={mif.maxAttempts}
                  onChange={handleChange}
                  className={classes.margin}
                  inputProps={{ min: 0, max: 3 }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox checked={mif.showHistory} color="secondary" onChange={handleCheckboxChange('showHistory')} />
              }
              label={intl.formatMessage({ id: 'mif.showHistory' })}
              labelPlacement="end"
              className={classes.margin}
            />
          </Grid>
          {isSummable(mif.inputType) /* Only for numeric input types: integer or decimal */ && (
            <Grid container item xs={12} lg={6} alignItems="baseline">
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mif.isSummable}
                      color="secondary"
                      onChange={handleCheckboxChange('isSummable')}
                    />
                  }
                  label={intl.formatMessage({ id: 'mif.isSummable' })}
                  labelPlacement="end"
                  className={classes.margin}
                />
              </Grid>
              <Grid>
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">{intl.formatMessage({ id: 'mif.isSummableInfo' })}</Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Grid>
              <Grid style={{ alignSelf: 'flex-start', marginTop: theme.spacing(1) }}>
                <LogoTooltip
                  tooltip={intl.formatMessage({ id: 'affectsCountidOnly' })}
                  logo={cidLogo}
                  iconRootClass={classes.iconRoot}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox checked={mif.mandatory} color="secondary" onChange={handleCheckboxChange('mandatory')} />
              }
              label={intl.formatMessage({ id: 'mif.mandatory' })}
              labelPlacement="end"
              className={classes.margin}
            />
          </Grid>
        </Grid>
        <LoadingButton
          isLoading={ui.loadingButton.isManualInputFieldLoading}
          content={intl.formatMessage({ id: editOrAdd('saveChanges', 'add') })}
          type="submit"
        />
      </form>
    </Container>
  ) : (
    <BadRequest text={intl.formatMessage({ id: 'sett.badRouting' })} />
  );
}
