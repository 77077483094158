import { management } from '../types';

const imageInitialState = {
  image: null,
  imageWidth: 0,
  imageHeight: 0,
  isTriggered: false,
  isLoaded: false,
};

const initialState = {};

const managementReducer = (state = initialState, action) => {
  let retState = { ...state };

  const identifier = action.data && action.data.identifier;

  switch (action.type) {
    case management.LABEL_IMAGE_LOADED:
      delete action.data.identifier;
      retState[identifier] = {
        ...retState[identifier],
        ...action.data,
        isTriggered: false,
        isLoaded: true,
      };
      break;
    case management.LABEL_IMAGE_FETCH_TRIGGERED:
      retState[identifier] = {
        ...retState[identifier],
        isTriggered: true,
        isLoaded: false,
      };
      break;
    case management.LABEL_IMAGE_UPDATED:
      delete action.data.identifier;
      retState[identifier] = {
        ...retState[identifier],
        ...action.data,
      };
      break;
    case management.LABEL_IMAGE_REMOVED:
      retState[action.data.identifier] = { ...imageInitialState };
      break;
    case management.LABEL_IMAGE_ADDED:
      const newIdentifier = action.data && action.data.identifier;
      delete action.data.newIdentifier;
      // change current identifier state into new indentifier and delete old one
      retState[newIdentifier] = { ...retState[identifier] };
      delete retState[identifier];
      break;
    default:
      break;
  }

  return retState;
};

export default managementReducer;
