const defaultConfig = {
  apiKey: 'AIzaSyAW_UIz1c0AnohViFTFvOkylHXAhr9Pwss',
  authDomain: 'pid-flyid.firebaseapp.com',
  databaseURL: 'https://pid-flyid.firebaseio.com',
  projectId: 'pid-flyid',
  storageBucket: 'pid-flyid.appspot.com',
  messagingSenderId: '1095201324812',
  appId: '1:1095201324812:web:180be206c1f9bde4a61329',
  measurementId: 'G-T3E23EMXLY'
};
type FirebaseConfig = typeof defaultConfig;

let config: FirebaseConfig = defaultConfig;
if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'staging_local') {
  config = {
    apiKey: 'AIzaSyCrpkP5ZLVtMqU7Kc87OmxkeR0WN1YoEts',
    authDomain: 'flyid-staging.firebaseapp.com',
    databaseURL: 'https://flyid-staging.firebaseio.com',
    projectId: 'flyid-staging',
    storageBucket: 'flyid-staging.appspot.com',
    messagingSenderId: '875691399627',
    appId: '1:875691399627:web:428abc1fa3d3e710963e4e',
    measurementId: 'G-B0KGWK6HCG'
  };
} else if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'production_local') {
  config = defaultConfig;
}

module.exports = config;
