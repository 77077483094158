const { wrap, releaseProxy } = require('comlink');
const { useEffect, useState, useMemo } = require('react');

export function useTaskInputFileParser(inputFile, settings, preScript) {
  const [data, setData] = useState({
    isWorking: false,
    parsedData: undefined,
    error: undefined,
  });

  const { workerApi } = useWorker();

  useEffect(() => {
    setData({ isWorking: !!inputFile, parsedData: undefined, error: undefined });

    if (!!inputFile && !!settings) {
      // We're starting the calculation here
      workerApi
        .parseTaskInputFile(inputFile, settings, preScript)
        .then(({ parsedData, error }) => {
          setData({ isWorking: false, parsedData, error });
        })
        .catch(console.log);
    }
  }, [workerApi, setData, inputFile, settings, preScript]);

  return data;
}

export function useTableDataParser(
  sessionData,
  settings,
  showInputData,
  showBaseFields,
  mergeLabels
) {
  // We'll want to expose a wrapping object so we know when parsing is in progress
  const [data, setData] = useState({
    isWorking: false,
    rowsAndColumns: undefined,
  });

  // acquire our worker
  const { workerApi } = useWorker();

  useEffect(() => {
    // We're starting the calculation here
    setData({ isWorking: true, rowsAndColumns: undefined });

    workerApi
      .getTableData(sessionData, settings, { showInputData, showBaseFields, mergeLabels })
      .then((rowsAndColumns) => {
        setData({ isWorking: false, rowsAndColumns });
      })
      .catch(console.log);
  }, [workerApi, setData, sessionData, settings, showInputData, showBaseFields, mergeLabels]);

  return data;
}

// Without hooks
export async function parseTableData(
  sessionData,
  settings,
  showInputData,
  showBaseFields,
  mergeLabels
) {
  const { workerApi, cleanup } = makeWorkerApiAndCleanup();

  return workerApi
    .getTableData(sessionData, settings, { showInputData, showBaseFields, mergeLabels })
    .then((rowsAndColumns) => {
      cleanup();
      return rowsAndColumns;
    })
    .catch((err) => {
      console.log(err);
      cleanup();
    });
}

function useWorker() {
  // memoise a worker so it can be reused; create one worker up front
  // and then reuse it subsequently instead of creating new workers each time
  const workerApiAndCleanup = useMemo(() => makeWorkerApiAndCleanup(), []);

  useEffect(() => {
    // cleanup our worker when we're done with it
    return () => workerApiAndCleanup.cleanup();
  }, [workerApiAndCleanup]);

  return workerApiAndCleanup;
}

/** Creates a worker, a cleanup function and returns it */
function makeWorkerApiAndCleanup() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker('../workers/dataWorker', {
    name: 'dataWorker',
    type: 'module',
  });

  const workerApi = wrap(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
}
