import * as xlsx from 'xlsx';
import pako from 'pako';
import { isEmpty, pickBy } from 'lodash';

const dateFormat = 'YYYY/MM/DD HH:MM:SS';

export const acceptableTaskErrors = [
  // Task contains addresses in different streets
  '947',
];

/**
 * Returns parsed data as a list of expected data objects or a message with error description if failed.
 * Returns undefined if there is no input data.
 *
 * @param {*} inputFileData decoded file from FileReader
 * @param {*} settings domain settings
 */
export async function parseTaskInputFile(inputFileData, settings, preScript) {
  if (!inputFileData) {
    return undefined;
  }

  // Collect expected data from settings
  if (!!settings) {
    // Parse input file data
    const workbook = xlsx.read(inputFileData.arrayBuffer, { raw: true, cellDates: true });
    if (!workbook) {
      return {
        error: {
          msgCode: 'createInventoryTask.failedParsingInputFile',
          msg: 'The file is not a valid XLS, XLSX or CSV!',
        },
      };
    }

    const tasksJson = {};
    for (const sheetName of workbook.SheetNames) {
      // Transform sheet to json format
      let sheetData = xlsx.utils.sheet_to_json(
        workbook.Sheets[sheetName],
        { defval: '', raw: true, dateNF: dateFormat }
      );
      if (!sheetData) {
        return {
          error: {
            msgCode: 'createInventoryTask.failedParsingFile',
            msg: "Failed parsing the input file on sheet '%s'!",
            args: [sheetName],
          },
        };
      }

      tasksJson[sheetName] = sheetData;
    }

    // eslint-disable-next-line
    if (preScript) eval(preScript);

    const returnData = {
      parsedData: {},
    };
    for (const sheetName of workbook.SheetNames) {
      const { parsedData, error } = parseTaskSheet(sheetName, tasksJson[sheetName], settings);
      returnData.parsedData[sheetName] = parsedData;
      if (error) {
        returnData.error = error;
        if (error.msgCode && !acceptableTaskErrors.includes(error.msgCode)) break;
      }
    }
    return returnData;
  }

  return undefined;
}

function parseTaskSheet(sheetName, filteredTaskData, settings) {
  let numberOfStartEndExamples = 2;

  const addressColumn = settings.baseFields.address;
  const addressPattern = settings.addrPattern;

  const streetIndexes = addressPattern
    .split('')
    .map((v, idx) => (v === 'R' ? idx : null))
    .filter((v) => v !== null);

  filteredTaskData = filteredTaskData
    .filter((line) => {
      // Guarantee data is in string format
      Object.keys(line).forEach((key) => (line[key] = String(line[key])));
      // Check if all lines have a valid address
      const lineAddress = line[addressColumn];
      return lineAddress && lineAddress.length === addressPattern.length;
    }) // Remove columns with empty header
    .map((line) => pickBy(line, (_, k) => !k.startsWith('__EMPTY')));

  if (!filteredTaskData || !filteredTaskData.length) {
    return {
      error: {
        msgCode: 'createInventoryTask.noValidInputAddresses',
        msg: "The input file has no valid input addresses on sheet '%s'!",
        args: [sheetName],
      },
    };
  }

  const repeatedAddressesTracking = {};
  filteredTaskData.forEach((line) => {
    const lineAddress = line[addressColumn];
    if (repeatedAddressesTracking[lineAddress]) {
      repeatedAddressesTracking[lineAddress] += 1;
    } else {
      repeatedAddressesTracking[lineAddress] = 1;
    }
  });
  const repeatedAddresses = Object.entries(repeatedAddressesTracking)
    .filter(([_, count]) => count > 1)
    .map(([addr]) => addr);
  if (repeatedAddresses.length) {
    return {
      error: {
        msgCode: '946',
        msg: 'The task contains the following repeated addresses: %s',
        args: [`[${repeatedAddresses.join(', ')}]`],
      },
    };
  }

  var acceptableError = undefined;
  const streetsFound = new Set();
  filteredTaskData.forEach((line) => {
    streetsFound.add(
      line[addressColumn]
        .split('')
        .filter((c, i) => streetIndexes.includes(i))
        .join('')
    );
  });
  if (streetsFound.size > 1) {
    acceptableError = {
      msgCode: '947',
      msg: 'Addresses in more than on street have been found (Streets %s)!',
      args: [[...streetsFound].join(' & ')],
    };
  }

  const { expectedBarcodeCheckFields, expectedMifCheckFields } = getExpectedColumns(settings);

  const firstLine = Object.keys(filteredTaskData[0]);
  // Check if expected data can be found on file
  const missingFields = [];
  for (const column of new Set([addressColumn, ...expectedBarcodeCheckFields, ...expectedMifCheckFields])) {
    if (!firstLine.includes(column)) {
      missingFields.push(column);
    }
  }

  if (missingFields.length) {
    return {
      error: {
        msgCode: 'createInventoryTask.failedParsingColumns',
        msg: "Input file is missing the following mandatory columns: %s on sheet '%s'!",
        args: [missingFields.join(', '), sheetName],
      },
    };
  } else {
    const addressesList = [];

    for (const fileLine of filteredTaskData) {
      const expectedBarcodeData = []; // Each element contains an object of expected data per label
      const expectedMifData = {};

      // Populate expected barcode field data for each label
      for (const label of settings.labelDesigns) {
        const labelExpectedBarcodeData = {};
        for (const bcp of label.barcodePatterns) {
          for (const column of expectedBarcodeCheckFields) {
            if (bcp.dataFields.some((field) => field.checkField === column)) {
              labelExpectedBarcodeData[column] = fileLine[column];
            }
          }
        }

        if (!isEmpty(labelExpectedBarcodeData)) {
          expectedBarcodeData.push(labelExpectedBarcodeData);
        }
      }

      // Populate expected MIF data
      for (const mif of settings.manualInputFields) {
        for (const column of expectedMifCheckFields) {
          if (mif.checkField === column) {
            expectedMifData[column] = fileLine[column];
          }
        }
      }

      addressesList.push({
        address: fileLine[addressColumn],
        expectedBarcodeData,
        expectedMifData,
        inputData: fileLine,
      });
    }

    const exampleAddressesStart = [];
    const exampleAddressesEnd = [];
    numberOfStartEndExamples = Math.trunc(
      addressesList.length > numberOfStartEndExamples ? numberOfStartEndExamples : addressesList.length / 2
    );
    if (numberOfStartEndExamples > 0) {
      for (let i = 0; i < numberOfStartEndExamples; i++) {
        exampleAddressesStart.push(addressesList[i].address);
        exampleAddressesEnd.push(addressesList[addressesList.length - numberOfStartEndExamples + i].address);
      }
    } // There's just one address
    else {
      exampleAddressesStart.push(addressesList[0].address);
    }

    return {
      parsedData: {
        addressesCount: addressesList.length,
        exampleAddresses: {
          start: exampleAddressesStart,
          end: exampleAddressesEnd,
          count: 2 * numberOfStartEndExamples,
        },
        deflatedAddressesList: Buffer.from(pako.deflate(JSON.stringify(addressesList))).toString('base64'),
        orderedColumns: [...firstLine],
      },
      error: acceptableError,
    };
  }
}

/**
 * @param {*} settings Domain settings
 * @returns {expectedDataColumns, expectedBarcodeCheckFields, expectedMifCheckFields}
 */
export function getExpectedColumns(settings) {
  // Collect expected fields from settings
  const expectedDataColumns = new Set();
  const expectedBarcodeCheckFields = new Set();
  for (const label of settings.labelDesigns) {
    for (const bcp of label.barcodePatterns) {
      for (const field of bcp.dataFields) {
        if (field.type === 'data') {
          expectedDataColumns.add(field.name);
          if (field.useCheckField) {
            expectedBarcodeCheckFields.add(field.checkField);
          }
        }
      }
    }
  }

  const expectedMifCheckFields = new Set();
  for (const mif of settings.manualInputFields) {
    expectedDataColumns.add(mif.field);
    if (mif.useCheckField) {
      expectedMifCheckFields.add(mif.checkField);
    }
  }
  return { expectedDataColumns, expectedBarcodeCheckFields, expectedMifCheckFields };
}

/**
 * Returns a summary of the addresses contained in the given task data
 *
 * @param {object} parsedInputData
 * @param {boolean} vertical
 */
export function getTaskExampleAddresses(taskData, vertical = false) {
  const joiner = vertical ? ', \n' : ', ';
  const dot = vertical ? '.\n' : '.';

  const exampleAddressesInput = taskData.exampleAddresses;

  let exampleAddresses = '[';
  // let exampleAddresses = '';
  if (taskData.addressesCount > exampleAddressesInput.count) {
    exampleAddresses += exampleAddressesInput.start.join(joiner);
    exampleAddresses += [joiner, dot, dot, dot, joiner].join('');
    exampleAddresses += exampleAddressesInput.end.join(joiner);
  } else {
    exampleAddresses += [...exampleAddressesInput.start, exampleAddressesInput.end].join(joiner);
  }
  exampleAddresses += ']';

  return exampleAddresses;
}
