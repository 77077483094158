export const user = {
  PROFILE_IMAGE_DATA_CHANGED: 'PROFILE_IMAGE_DATA_CHANGED',
  PROFILE_IMAGE_THUMB_CHANGED: 'PROFILE_IMAGE_THUMB_CHANGED',
};

export const management = {
  LABEL_IMAGE_ADDED: 'LABEL_IMAGE_ADDED',
  LABEL_IMAGE_LOADED: 'LABEL_IMAGE_LOADED',
  LABEL_IMAGE_UPDATED: 'LABEL_IMAGE_UPDATED',
  LABEL_IMAGE_REMOVED: 'LABEL_IMAGE_REMOVED',
  LABEL_IMAGE_FETCH_TRIGGERED: 'LABEL_IMAGE_FETCH_TRIGGERED',
};

export const ui = {
  /** Used when ui must be reset to initial state and then changes applied */
  UPDATE_UI: 'UPDATE_UI',
  /** Used when UI states must not be changed but the given data */
  UPDATE_UI_NO_RESET: 'UPDATE_UI_NO_RESET',
};

export const picture = {
  /** Must be called when an image has started downloading */
  ON_PICTURE_LOADING: 'ON_PICTURE_LOADING',
  /** Must be called when an image has finished loading, successfully or not */
  ON_PICTURE_LOADED: 'ON_PICTURE_LOADED',
};

export const locale = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
};
