import axios from 'axios';
import { ui } from '../types';
// eslint-disable-next-line
import history from '../../history';
import { getLabelImagePath } from '../../util/helpers/path';
import { urlDataReader } from '../../util/workers/fileWorkerUtils';
import { management } from '../types';
import { getSnackbar } from './uiActions';

export const addDomain =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isAddDomainLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/domains`,
          method: 'post',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.push('/managedomains');
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const removeDomain =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI_NO_RESET,
      data: {
        backdrop: {
          show: true,
          message: {
            msgCode: 'manDom.removingDomain',
            msg: 'Deleting domain data, it may take a while...',
          },
        },
        dialog: {
          show: false,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/domains`,
          method: 'delete',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        window.location.reload();
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const editDomainSettings =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isEditDomainLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/domains`,
          method: 'patch',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const addLabelDesign =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isBarcodePatternLoading: true,
        },
      },
    });

    const { company, parentUid, currentIdentifier } = data;
    delete data.currentIdenfier;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/labelDesigns`,
          method: 'post',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: management.LABEL_IMAGE_ADDED,
          data: {
            identifier: currentIdentifier,
            newIdentifier: `${data.domain}${data.newLabelDesign.name}`,
          },
        });
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.push(`/company/${company}/domain/${data.domain}/settings`);
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const editLabelDesign =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isBarcodePatternLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/labelDesigns`,
          method: 'patch',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.push(`/company/${company}/domain/${data.domain}/settings`);
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const removeLabelDesign =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'dSett.removingLabelDesign', msg: 'Deleting code pattern...' },
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/labelDesigns`,
          method: 'delete',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: management.LABEL_IMAGE_REMOVED,
          data: {
            identifier: `${data.domain}${data.labelName}`,
          },
        });
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const addManualInputField =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isManualInputFieldLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/mif`,
          method: 'post',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.push(`/company/${company}/domain/${data.domain}/settings`);
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const editManualInputField =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isManualInputFieldLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/mif`,
          method: 'patch',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.goBack();
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const removeManualInputField =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'dSett.removingMif', msg: 'Deleting manual input field...' },
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/mif`,
          method: 'delete',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const addLicense =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        loadingButton: {
          isAddLicenseLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/licenses`,
          method: 'post',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
        history.push(`/company/${company}/domain/${data.domain}/settings`);
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const removeLicense =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'dSett.removingLicense', msg: 'Removing license...' },
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/licenses`,
          method: 'delete',
          data,
          headers: { Authorization: `Bearer ${token}`, company, parentUid },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const resetPassword =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI_NO_RESET,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'prof.resettingPassword', msg: 'Resetting password...' },
        },
        dialog: { show: false },
      },
    });

    const company = data.company;
    delete data.company;

    getFirebase()
      .auth()
      .currentUser.getIdToken(true)
      .then((token) =>
        axios({
          url: `${process.env.REACT_APP_API_ENTRY_POINT}/users/pw`,
          method: 'put',
          data,
          headers: { Authorization: `Bearer ${token}`, company },
          withCredentials: true,
        })
      )
      .then((res) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(res) },
        });
      })
      .catch((err) => {
        dispatch({
          type: ui.UPDATE_UI,
          data: { snackbar: getSnackbar(err) },
        });
      });
  };

export const fetchLabelImage =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: management.LABEL_IMAGE_FETCH_TRIGGERED });

    const { company, domain, labelName, dimensionCalc } = data;
    const identifier = `${domain}${labelName}`;

    getFirebase()
      .storage()
      .ref(getLabelImagePath(company, domain))
      .child(labelName)
      .getDownloadURL()
      .then((url) =>
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
      )
      .then(
        (res) =>
          new Promise((resolve) => {
            urlDataReader(res.data, {
              onload: (result) => {
                const img = new Image();
                img.onload = () => {
                  const { width, height } = dimensionCalc(img.naturalWidth, img.naturalHeight);
                  dispatch({
                    type: management.LABEL_IMAGE_LOADED,
                    data: {
                      identifier,
                      image: img,
                      imageWidth: width,
                      imageHeight: height,
                      isLoaded: true,
                    },
                  });
                  resolve();
                };
                img.src = result;
              },
            });
          })
      )
      .catch((err) => {
        console.log(err);
        dispatch({
          type: management.LABEL_IMAGE_LOADED,
          data: { identifier, image: null },
        });
      });
  };

export const createApiKey =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.creatingApiKey', msg: 'Creating API Key...' },
        },
        loadingButton: {
          isCreateApiKeyLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    makeKeyApiCall(
      'post',
      data,
      getFirebase,
      dispatch,
      {
        onSuccess: () => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { loadingButton: { isCreateApiKeyLoading: true } },
          });
          history.goBack();
        },
      },
      { company, parentUid }
    );
  };

export const editApiKeyAuthDomains =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: {
            msgCode: 'manApiKeys.editingApiKeyAuthDomains',
            msg: 'Editing API Key authorized domains...',
          },
        },
        loadingButton: {
          isEditApiKeyAuthDomainsLoading: true,
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    makeKeyApiCall(
      'patch',
      data,
      getFirebase,
      dispatch,
      {
        onSuccess: () => {
          history.goBack();
        },
      },
      { company, parentUid }
    );
  };

export const renewApiKey =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.renewingApiKey', msg: 'Renewing API Key...' },
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    makeKeyApiCall(
      'put',
      data,
      getFirebase,
      dispatch,
      {
        onSuccess: () => window.location.reload(),
      },
      { company, parentUid }
    );
  };

export const removeApiKey =
  (data) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: ui.UPDATE_UI,
      data: {
        backdrop: {
          show: true,
          message: { msgCode: 'manApiKeys.removingApiKey', msg: 'Removing API Key...' },
        },
      },
    });

    const { company, parentUid } = data;
    delete data.company;
    delete data.parentUid;

    makeKeyApiCall('delete', data, getFirebase, dispatch, {}, { company, parentUid });
  };

function makeKeyApiCall(method, data, getFirebase, dispatch, callbacks = {}, headers) {
  return getFirebase()
    .auth()
    .currentUser.getIdToken(true)
    .then((token) =>
      axios({
        url: `${process.env.REACT_APP_API_ENTRY_POINT}/keys`,
        method: method,
        data,
        headers: { Authorization: `Bearer ${token}`, ...headers },
        withCredentials: true,
      })
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: ui.UPDATE_UI,
        data: { snackbar: getSnackbar(res) },
      });
      if (!!callbacks.onSuccess) callbacks.onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ui.UPDATE_UI,
        data: { snackbar: getSnackbar(err) },
      });
      if (!!callbacks.onFailure) callbacks.onFailure();
    });
}
