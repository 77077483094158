import axios from 'axios';
import { actionTypes } from 'redux-firestore';
import { picture, ui, user } from '../types';
import history from './../../history';

/* eslint-disable-next-line */
import imageUnavailable from '../../assets/images/img_unavailable_white.png';
import { getProfilePicsBucket } from '../../util/firebase';
import { getPicturesPath, getStorageUserProfileImagePath, getStorageUserProfileThumbPath } from '../../util/helpers/path';
import { formatTimeDate } from '../../util/helpers/string';
import { urlDataReader } from '../../util/workers/fileWorkerUtils';
import { getSnackbar } from './uiActions';

export const login =
  (credentials) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({ type: ui.UPDATE_UI, data: { loadingButton: { isLoginLoading: true } } });

      getFirebase()
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((user) => {
          if (user.uid) {
            dispatch({
              type: ui.UPDATE_UI,
              data: null,
            });
            history.push('/');
          }
        })
        .catch((err) =>
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(err),
              loadingButton: {
                isLoginLoading: false,
              },
            },
          })
        );
    };

export const logout =
  (notKeyUser, intl) =>
    (dispatch, getState, { getFirebase }) => {
      getFirebase()
        .logout()
        .then((res) => {
          dispatch({ type: actionTypes.CLEAR_DATA, res });
          if (notKeyUser) {
            setTimeout(
              () => {
                dispatch({
                  type: ui.UPDATE_UI,
                  data: {
                    snackbar: {
                      message: intl.$t({ id: 'onlyKeyUserAllowed' },
                        { msg: intl.$t({ id: "redirectCommon" }) }),
                      severity: 'error',
                      show: true,
                    },
                  },
                })
                setTimeout(() => window.location.replace(process.env.REACT_APP_COMMON_REDIRECT), 1000)
              },
              1000
            );
          }
        })
        .catch((err) =>
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(err) },
          })
        );
    };

export const addUser =
  (data, parentUid) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({ type: ui.UPDATE_UI, data: { loadingButton: { isAddUserLoading: true } } });

      getFirebase()
        .auth()
        .currentUser.getIdToken(true)
        .then((token) =>
          axios({
            url: `${process.env.REACT_APP_API_ENTRY_POINT}/users`,
            method: 'post',
            data: { data },
            headers: { Authorization: `Bearer ${token}`, company: data.company, parentUid },
            withCredentials: true,
          })
        )
        .then((res) => {
          if (res.status === 200) {
            history.push('/manageusers');
          }
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(res),
              loadingButton: {
                isAddUserLoading: false,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(err),
              loadingButton: {
                isAddUserLoading: false,
              },
            },
          });
        });
    };

export const editUser =
  (data) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({ type: ui.UPDATE_UI, data: { loadingButton: { isEditUserLoading: true } } });

      const { company, parentUid } = data;
      delete data.parentUid;
      delete data.company;

      getFirebase()
        .auth()
        .currentUser.getIdToken(true)
        .then((token) =>
          axios({
            url: `${process.env.REACT_APP_API_ENTRY_POINT}/users`,
            method: 'patch',
            data: { data },
            headers: { Authorization: `Bearer ${token}`, company, parentUid },
            withCredentials: true,
          })
        )
        .then((res) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(res),
              loadingButton: {
                isEditUserLoading: false,
              },
            },
          });

          // Force refetching picture if it changed
          if (data.userData.profilePicFile) {
            dispatch({
              type: user.PROFILE_IMAGE_THUMB_CHANGED,
              data: { uid: data.uid, isLoaded: false, isLoading: false },
            });
            dispatch({
              type: user.PROFILE_IMAGE_DATA_CHANGED,
              data: { uid: data.uid, isLoaded: false, isLoading: false },
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(err),
              loadingButton: {
                isEditUserLoading: false,
              },
            },
          });
        });
    };

export const removeUser =
  (data) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({
        type: ui.UPDATE_UI,
        data: {
          backdrop: {
            message: { msgCode: 'manUsr.removingUsr' },
            show: true,
          },
        },
      });

      const { company, parentUid } = data;
      delete data.parentUid;
      delete data.company;

      getFirebase()
        .auth()
        .currentUser.getIdToken(true)
        .then((token) =>
          axios({
            url: `${process.env.REACT_APP_API_ENTRY_POINT}/users`,
            method: 'delete',
            data,
            headers: { Authorization: `Bearer ${token}`, company, parentUid },
            withCredentials: true,
          })
        )
        .then((res) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(res) },
          });
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(err) },
          });
        });
    };

export const changePassword =
  (data) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({ type: ui.UPDATE_UI, data: { loadingButton: { isChangePasswordLoading: true } } });

      let { currentPassword, newPassword, company } = data;
      getFirebase()
        .auth()
        .currentUser.getIdToken(true)
        .then((token) =>
          axios({
            url: `${process.env.REACT_APP_API_ENTRY_POINT}/users/pw`,
            method: 'patch',
            data: { currentPassword, newPassword },
            headers: { Authorization: `Bearer ${token}`, company },
            withCredentials: true,
          })
        )
        .then((res) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(res) },
          });
          dispatch(logout());
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(err) },
          });
        });
    };

export const resetPassword =
  (data) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({
        type: ui.UPDATE_UI,
        data: {
          backdrop: {
            message: { msgCode: 'setPw.resettingPassword' },
            show: true,
          },
        },
      });

      let { newPassword, actionCode } = data;
      axios({
        url: `${process.env.REACT_APP_API_ENTRY_POINT}/users/resetpw`,
        method: 'patch',
        data: { newPassword, actionCode },
      })
        .then((res) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(res) },
          });
          history.push('/');
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: { snackbar: getSnackbar(err) },
          });
        });
    };

export const fetchProfileImages =
  (data) =>
    (dispatch, getState, { getFirebase }) => {
      const { profileUids, company, isThumb } = { isThumb: true, ...data }

      const profilePicsState = getState().user[isThumb ? 'profilePicThumbs' : 'profilePics'];

      const profilePicsBucket = getProfilePicsBucket(getFirebase())

      profileUids.forEach((uid) => {
        const thisProfilePic = profilePicsState[uid];
        // Only fetch profile if not loading nor loaded
        if (!thisProfilePic || (!thisProfilePic.isLoading && !thisProfilePic.isLoaded)) {
          const actionType = isThumb
            ? user.PROFILE_IMAGE_THUMB_CHANGED
            : user.PROFILE_IMAGE_DATA_CHANGED

          dispatch({
            type: actionType,
            data: { uid, isLoaded: false, isLoading: true },
          });

          profilePicsBucket
            .ref(isThumb
              ? getStorageUserProfileThumbPath(company, uid)
              : getStorageUserProfileImagePath(company, uid)
            )
            .getDownloadURL()
            .then((url) => axios({ url, method: 'GET', responseType: 'blob' }))
            .then((res) =>
              urlDataReader(res.data, {
                onload: (result) => {
                  dispatch({
                    type: actionType,
                    data: { uid, src: result, isLoaded: true, isLoading: false },
                  });
                },
                onerror: () => {
                  throw new Error();
                },
              })
            )
            .catch((err) => {
              dispatch({
                type: actionType,
                data: { uid, src: null, isLoaded: true, isLoading: false },
              });
            });
        }
      });
    };

export const fetchStoragePicture =
  (company, domain, address, checkedAtMs, pictureFilename, addressField, intl) =>
    (dispatch, getState, { getFirebase }) => {
      const state = getState();

      const pictures = state.picture;
      if (pictures && pictures[pictureFilename]) {
        const imageData = pictures[pictureFilename];
        // If loading or successfully loaded, do not trigger it again
        if (imageData.isLoading) return;
        if (pictures[pictureFilename].isLoaded && !!imageData.src) {
          dispatch({
            type: ui.UPDATE_UI,
            data: { imageViewer: { show: true, selectedId: pictureFilename } },
          });
          return;
        }
      }

      dispatch({
        type: ui.UPDATE_UI,
        data: {
          backdrop: {
            show: true,
            message: { msgCode: 'img.loading' },
          },
          imageViewer: {
            show: false,
          },
        },
      });

      // Handle image loading states
      getFirebase()
        .storage()
        .ref(getPicturesPath(company, domain))
        .child(pictureFilename)
        .getDownloadURL()
        .then((url) => {
          dispatch({
            type: picture.ON_PICTURE_LOADING,
            data: { id: pictureFilename },
          });
          return axios({
            url,
            method: 'GET',
            responseType: 'blob',
          });
        })
        .then(
          (res) =>
            new Promise((resolve) => {
              urlDataReader(res.data, {
                onload: (result) => {
                  dispatch({
                    type: picture.ON_PICTURE_LOADED,
                    data: {
                      id: pictureFilename,
                      src: result,
                      alt: `${addressField}: ${address} (${formatTimeDate(
                        new Date(checkedAtMs),
                        intl
                      )})`,
                    },
                  });
                  dispatch({
                    type: ui.UPDATE_UI,
                    data: {
                      imageViewer: { show: true, selectedId: pictureFilename },
                      backdrop: { show: false },
                    },
                  });
                  resolve();
                },
                onerror: () => {
                  throw new Error('Failed parsing picture');
                },
              });
            })
        )
        .catch((err) => {
          console.error(err);
          dispatch({
            type: picture.ON_PICTURE_LOADED,
            data: {
              id: pictureFilename,
              src: imageUnavailable,
              alt: intl.formatMessage({ id: 'img.unavailable' }),
            },
          });
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              imageViewer: { show: true, selectedId: pictureFilename },
              backdrop: { show: false },
            },
          });
        });
    };

export const resendEmailVerification =
  (locale) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({
        type: ui.UPDATE_UI,
        data: {
          backdrop: {
            message: { msgCode: 'sendingEmailVerification' },
            show: true,
          },
        },
      });

      const languageCode = locale.substr(0, 2);

      const auth = getFirebase().auth();
      auth.languageCode = languageCode;
      const user = auth.currentUser;

      user
        .sendEmailVerification()
        .then(() => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: {
                message: { msgCode: '418', msg: 'Email verification sent to %s', args: [user.email] },
                duration: 3000,
                severity: 'success',
                show: true,
              },
            },
          });
        })
        .catch((err) =>
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(err),
            },
          })
        );
    };

export const sendPasswordResetEmail =
  (email, locale) =>
    (dispatch, getState, { getFirebase }) => {
      dispatch({ type: ui.UPDATE_UI, data: { loadingButton: { isForgetPasswordLoading: true } } });

      const languageCode = locale.substr(0, 2);

      const auth = getFirebase().auth();
      auth.languageCode = languageCode;

      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: {
                message: { msgCode: 'forgotPw.sent' },
                duration: 3000,
                severity: 'success',
                show: true,
              },
            },
          });
          history.push('/');
        })
        .catch((err) => {
          dispatch({
            type: ui.UPDATE_UI,
            data: {
              snackbar: getSnackbar(err),
            },
          });
          history.push('/');
        });
    };
