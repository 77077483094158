import {
  Avatar,
  Box,
  Collapse,
  Container,
  Fab,
  IconButton,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tab,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Skeleton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { isObject } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import noImg from '../../assets/images/avatar-no-img.png';
import useStateReducer from '../../hooks/useStateReducer';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi } from '../../redux/actions/uiActions';
import { fetchProfileImages } from '../../redux/actions/userActions';
import { groupByCompany, selectAllUsers, selectCompanies } from '../../redux/selectors/userSelectors';

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container(2), marginLeft: 0 },
  listContainer: {
    maxWidth: theme.spacing(90),
    marginBottom: theme.spacing(2),
  },
  tabContainer: { maxWidth: theme.spacing(100) },
  listItem: {
    paddingRight: theme.spacing(10),
    margin: theme.spacing(2),
    background: lighten(theme.palette.other.grey.light, 0.85),
  },
  childListItem: {
    maxWidth: theme.spacing(86),
    paddingRight: theme.spacing(10),
    margin: theme.spacing(2, 6),
  },
  itemText: {
    margin: theme.spacing(1, 6, 1, 1),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(0.5),
  },
  skeletonContainer: {
    maxWidth: theme.spacing(90),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  skeletonItemText: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8),
  },
}));

const pilotKey = 'pilot';
const checkerKey = 'checker';
const assistantKey = 'assistant';
const moderatorKey = 'moderator';
const permissionTypes = [pilotKey, checkerKey, assistantKey, moderatorKey];

export function groupUsersByParent(userProfiles) {
  const usersByParents = {
    moderators: [],
    // [parentUid]: [[uid1, userData1], ..., [uidN, userDataN]]
  };

  if (typeof userProfiles === 'object') {
    Object.entries(userProfiles).forEach(([uid, userData]) => {
      const parent = userData?.parent;
      if (!parent && userData?.moderator) {
        usersByParents.moderators.push([uid, userData]);
      } else if (parent) {
        if (Array.isArray(usersByParents[parent])) {
          usersByParents[parent].push([uid, userData]);
        } else {
          usersByParents[parent] = [[uid, userData]];
        }
      } else {
        console.log('failed selecting user:', userData);
      }
    });
    usersByParents.moderators.sort(([, userData0], [, userData1]) =>
      `${userData0.firstName} ${userData0.lastName}`.localeCompare(`${userData1.firstName} ${userData1.lastName}`)
    );

    usersByParents.moderators.forEach(([parentUid]) => {
      const parentsUsers = usersByParents[parentUid];
      if (Array.isArray(parentsUsers)) {
        parentsUsers.sort(([, userData0], [, userData1]) =>
          `${userData0.firstName} ${userData0.lastName}`.localeCompare(`${userData1.firstName} ${userData1.lastName}`)
        );
      }
    });
  }
  return usersByParents;
}

export default function ManageUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [openChildrenList, setOpenChildrenList] = useStateReducer({});

  const { userProfilesData, user, authCompanies } = useSelector((s) => {
    const managerUid = s.firebase.auth.uid;
    return {
      userProfilesData: selectAllUsers(s, managerUid),
      authCompanies: selectCompanies(s),
      user: s.user,
    };
  });

  const { isLoaded: isUserProfilesLoaded, data: userProfiles } = userProfilesData;

  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem('selectedCompany') || (authCompanies && authCompanies[0]) || ''
  );

  const companyProfiles = useMemo(
    () => groupByCompany(userProfilesData)[selectedCompany],
    [selectedCompany, userProfilesData]
  );

  const usersByParent = useMemo(() => groupUsersByParent(companyProfiles), [companyProfiles]);

  const toggleOpenChildrenList = (uid) => setOpenChildrenList({ [uid]: !Boolean(openChildrenList[uid]) });

  useEffect(() => {
    if (isUserProfilesLoaded && isObject(companyProfiles)) {
      // Child user profiles to be fetched
      dispatch(fetchProfileImages({ profileUids: Object.keys(companyProfiles), company: selectedCompany }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfiles]);

  const showRemoveUserDialogConfirmation = (uid) => {
    const userData = userProfiles[uid];
    if (!userData) {
      return;
    }

    const actionData = { uid, company: userData.company };
    if (!userData[moderatorKey]) {
      actionData.parentUid = userData.parent;
    } else {
      dispatch(
        updateUi({
          snackbar: {
            message: intl.formatMessage({ id: 'manUsr.remMod' }),
            severity: 'error',
            show: true,
          },
        })
      );
      return;
    }

    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'manUsr.remUserTitle' }),
          message: intl.formatMessage(
            { id: 'manUsr.remUserMsg' },
            {
              name: <b key="mub0">{`${userData.firstName} ${userData.lastName}`}</b>,
              nl: <br key="munl0" />,
            }
          ),
          confirmAction: actions.REMOVE_USER,
          actionData,
          show: true,
        },
      })
    );
  };

  const renderCompanyUsers = useCallback(() => {
    const moderators = usersByParent.moderators;

    const renderUser = (uid, profile, isModerator) => {
      const profileImageThumb = user.profilePicThumbs[uid];
      const labelId = `label${uid}`;

      const permissions = [];
      permissionTypes.forEach((permission) => {
        if (profile[permission]) {
          permissions.push(intl.formatMessage({ id: permission }));
        }
      });

      const hasChildren = isModerator && Boolean(usersByParent[uid]?.length);
      return (
        <ListItem
          key={uid}
          button
          alignItems="flex-start"
          className={isModerator ? classes.listItem : classes.childListItem}
          onClick={hasChildren ? () => toggleOpenChildrenList(uid) : undefined}
        >
          <ListItemAvatar>
            {profileImageThumb && profileImageThumb.isLoaded ? (
              <Avatar
                alt={intl.formatMessage({ id: 'altUsrProfImage' })} //todo change
                src={profileImageThumb.src || noImg}
                className={classes.avatar}
              />
            ) : (
              <Skeleton variant="circle" className={classes.avatar} animation="wave" />
            )}
          </ListItemAvatar>
          <ListItemText
            id={labelId}
            className={classes.itemText}
            primary={`${profile.firstName} ${profile.lastName}`}
            secondary={intl.formatMessage(
              { id: 'manUsr.userDesc' },
              {
                domains: profile?.authDomains.join(', ') ?? '',
                nl: <br key={`${uid}nl`} />,
                perm: permissions?.join(', ') ?? '',
              }
            )}
          />
          <ListItemSecondaryAction styles={{ marginLeft: theme.spacing(2) }}>
            <Tooltip title={intl.formatMessage({ id: isModerator ? 'nav.profile' : 'edit' })}>
              <IconButton component={Link} to={`/profile/${uid}`} style={{ color: theme.palette.info.main }}>
                {isModerator ? <VisibilityIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
            {!isModerator ? (
              <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                <IconButton
                  style={{ color: theme.palette.error.main }}
                  onClick={() => showRemoveUserDialogConfirmation(uid)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  hasChildren
                    ? intl.formatMessage({ id: openChildrenList[uid] ? 'collapse' : 'expand' })
                    : intl.formatMessage({ id: 'manUsr.parentHasNoUsers' })
                }
              >
                <IconButton
                  style={{ color: hasChildren ? theme.palette.other.grey.main : theme.palette.other.grey.light }}
                  onClick={hasChildren ? () => toggleOpenChildrenList(uid) : undefined}
                >
                  {openChildrenList[uid] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      );
    };

    return (
      <List className={classes.listContainer} key={`users${selectedCompany}`}>
        {!isEmpty(moderators) ? (
          moderators.map(([parentUid, profile]) => (
            <Fragment key={parentUid}>
              {renderUser(parentUid, profile, true)}
              <Collapse in={Boolean(openChildrenList[parentUid])} timeout="auto" unmountOnExit>
                {usersByParent[parentUid]?.map(([uid, profile]) => renderUser(uid, profile, false))}
              </Collapse>
            </Fragment>
          ))
        ) : (
          <ListItem key="emptyUsers" alignItems="flex-start" className={classes.childListItem}>
            <ListItemText
              id="emptyUsers"
              className={classes.itemText}
              primary={intl.formatMessage({ id: 'manUsr.noUsers' })}
            />
          </ListItem>
        )}
      </List>
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, usersByParent, user.profilePics]);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" style={theme.text.title}>
        {intl.formatMessage({ id: 'manUsr.title' })}
      </Typography>
      <Typography variant="subtitle1" style={theme.text.subtitle}>
        {intl.formatMessage({ id: 'manUsr.subtitle' })}
      </Typography>
      {isUserProfilesLoaded ? (
        <TabContext value={selectedCompany}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, selected) => {
                localStorage.setItem('selectedCompany', selected);
                setSelectedCompany(selected);
              }}
              className={classes.tabContainer}
              aria-label="Company tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {authCompanies.map((company, idx) => (
                <Tab label={company} value={company} key={`label${idx}`} />
              ))}
            </TabList>
          </Box>
          {authCompanies.map((company, idx) => (
            <TabPanel value={company} key={`panel${idx}`}>
              {renderCompanyUsers(company)}
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <>
          <Skeleton variant="rect" style={{ width: theme.spacing(16), height: theme.spacing(3) }} />
          {[0, 1, 2].map((i) => (
            <Container className={clsx(classes.listItem, classes.skeletonContainer)} key={`skel${i}`}>
              <Skeleton variant="circle" animation="wave" className={classes.avatar} />
              <Skeleton variant="text" animation="wave" className={classes.skeletonItemText} />
            </Container>
          ))}
        </>
      )}
      <List>
        <ListItem>
          <Fab variant="extended" size="medium" color="secondary" aria-label="add" component={Link} to="/adduser">
            <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
            {intl.formatMessage({ id: 'manUsr.addUsr' })}
          </Fab>
        </ListItem>
      </List>
    </Container>
  );
}
