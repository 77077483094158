export const matchRegex = /^[^@]+@([^.]+)\.([^.]+)$/;

// Storage - Profile Pics bucket
export const getStorageUserProfileImagesPath = (company) => company;

export const getStorageUserProfileImagePath = (company, uid) =>
  `${getStorageUserProfileImagesPath(company)}/${uid}`;

export const getStorageUserProfileThumbsPath = (company) =>
  `${getStorageUserProfileImagesPath(company)}/thumbs`;

export const getStorageUserProfileThumbPath = (company, uid) =>
  `${getStorageUserProfileThumbsPath(company)}/${uid}`;

// Storage - Default
export const getStorageDomainPath = (company, domain) => {
  return `/companies/${company}/${domain}`;
};

export const getPicturesPath = (company, domain) => {
  return `${getStorageDomainPath(company, domain)}/pictures`;
};

export const getLabelImagePath = (company, domain) => {
  return `${getStorageDomainPath(company, domain)}/labelImages`;
};

// Firestore
export const getCompany = (profile) => {
  if (profile) {
    return profile.company;
  }
  return null;
};

export const getCompaniesPath = () => "/companies";

export const getCompanyPath = (company) => {
  return company ? `${getCompaniesPath()}/${company}` : null;
};

export const getDomainPath = (company, domain) => {
  return company ? `${getCompanyPath(company)}/domains/${domain}` : null;
};

export const getDomainsPath = (company) => {
  return company ? `${getCompanyPath(company)}/domains` : null;
};

export const getSessionsPath = (company, domain) => {
  return company && domain ? `${getDomainPath(company, domain)}/sessions` : null;
};

export const getInventoryTasksPath = (company, domain) => {
  return company && domain ? `${getDomainPath(company, domain)}/tasks` : null;
};

export const getAddressesPath = (company, domain) => {
  return company && domain ? `${getDomainPath(company, domain)}/addresses` : null;
};

export const getSessionAddressesPath = (company, domain, session) => {
  return company && domain && session
    ? `${getSessionsPath(company, domain)}/${session}/addresses`
    : null;
};

export const getSettingsPath = (company, domain) => {
  return company && domain ? `${getDomainPath(company, domain)}/settings` : null;
};

export const getDomainSettingsPath = (company, domain) => {
  return company && domain ? `${getDomainPath(company, domain)}/settings` : null;
};