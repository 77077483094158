exports.isModeratorClaims = (profile) => {
  return profile && profile.token && profile.token.claims && profile.token.claims.moderator;
};

exports.isKeyUserProf = (profile) => {
  return profile && profile && profile.keyUser === true;
};

exports.isModeratorProf = (profile) => {
  return profile && profile && profile.moderator === true;
};

exports.isAssistantProf = (profile) => {
  return profile && profile && profile.assistant === true;
};

exports.isPilotProf = (profile) => {
  return profile && profile && profile.pilot === true;
};

exports.hasApiKeys = (profile) =>
  profile &&
  profile.token &&
  profile.token.claims &&
  profile.token.claims.ownedAPIKeys &&
  profile.token.claims.ownedAPIKeys.length;
