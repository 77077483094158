import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers/rootReducer';
import firebase from '../util/firebase';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore, reduxFirestore } from 'redux-firestore';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rrfConfig = {
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  userProfile: 'users',
  enableClaims: true,
};

const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware), reduxFirestore(firebase))
);
