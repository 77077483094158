import { createTheme } from '@material-ui/core/styles';
import { ptBR, enUS, esES } from '@material-ui/core/locale';

const theme = createTheme({
  palette: {
    primary: {
      light: '#8c5ac2',
      main: '#5B2E91',
      dark: '#2b0062',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#9ef062',
      main: '#6bbd30',
      dark: '#368c00',
      contrastText: '#FFFFFF',
    },
    other: {
      grey: {
        light: '#AAAAAA',
        main: '#777777',
        dark: '#444444',
        contrastText: '#6B5B95',
      },
      white: '#FFFFFF',
      transparent: '#00000000',
    },
  },
  drawer: {
    width: 280,
  },
});

const resizeableContainer = (horizontalPadding, verticalPadding = 1) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(
      2 * verticalPadding,
      7 * horizontalPadding,
      2 * verticalPadding,
      7 * horizontalPadding
    ),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(
      1.5 * verticalPadding,
      5 * horizontalPadding,
      1.5 * verticalPadding,
      5 * horizontalPadding
    ),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(
      verticalPadding,
      2 * horizontalPadding,
      verticalPadding,
      2 * horizontalPadding
    ),
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(verticalPadding, horizontalPadding, verticalPadding, horizontalPadding),
  },
});

const text = {
  title: {
    flex: '1 1 100%',
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  tableTitle: {
    flex: '1 1 100%',
    fontWeight: 'bolder',
    color: theme.palette.other.grey.dark,
  },
};

const muiThemeLocaleByLocale = {
  'en-GB': enUS,
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES,
};

const getTheme = (locale) => {
  return createTheme(
    theme,
    {
      container: resizeableContainer,
      text,
    },
    muiThemeLocaleByLocale[locale]
  );
};

export default getTheme;
