export const ReaderStatus = {
  SUCCESS: 0,
  ABORTED: 1,
  ERROR: 2,
};

async function awaitReaderFor(readFun) {
  const reader = new FileReader();

  let _status = undefined;
  let data = undefined;

  await new Promise((resolve) => {
    reader.onabort = async () => {
      _status = ReaderStatus.ABORTED;
      resolve();
    };
    reader.onerror = async () => {
      _status = ReaderStatus.ERROR;
      resolve();
    };

    reader.onloadend = async () => {
      data = reader.result;
      _status = !!data ? ReaderStatus.SUCCESS : ReaderStatus.ERROR;
      resolve();
    };

    // Start reading
    readFun(reader);
  });

  return [_status, data];
}

export const readAsDataUrl = async (urlData) => {
  return awaitReaderFor((reader) => reader.readAsDataURL(urlData));
};

export const readAsArrayBuffer = async (bufferData) => {
  return awaitReaderFor((reader) => reader.readAsArrayBuffer(bufferData));
};
