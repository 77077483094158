import { Button, Container, Grid, IconButton, TextField, Tooltip, Typography, withTheme } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HistoryIcon from '@material-ui/icons/History';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import { isEmpty, isString } from 'lodash';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import {
  selectDomainSettings,
  selectLicensesPerDomain,
  selectParentCandidateModerators,
} from '../../redux/selectors/userSelectors';
import { buildLicenseDescription, buildMifDescription } from '../../util/helpers/other';
import LoadingButton from '../widgets/LoadingButton';

const styles = (theme) => ({
  root: {
    ...theme.container(2),
    marginLeft: 0,
    flexGrow: 1,
  },
  inputContainer: {
    ...theme.container(1, 0),
    marginBottom: theme.spacing(4),
  },
  mainContainer: {
    maxWidth: '600px',
    marginLeft: '0',
  },
  button: {
    alignSelf: 'flex-end',
  },
  title: {
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    alignSelf: 'center',
  },
  settingsSkeleton: {
    height: theme.spacing(4),
  },
  licenseSkeleton: {
    height: theme.spacing(4),
  },
  subtitleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 0,
  },
});

const baseFields = ['address', 'checkedAt', 'employeeId', 'employeeName'];
const resultFields = ['empty', 'invalid', 'positive', 'negative', 'null'];
const nullableFields = [false, false, false, false, true];
const dialogTypes = {
  EDIT_SETTINGS: 0,
  REMOVE_LABEL_DESIGN: 1,
  REMOVE_LICENSE: 2,
  REMOVE_MANUAL_INPUT: 3,
};

const getSettingsField = (settings, field) => {
  return settings[field];
};

const getFieldsOrDefault = (settings, fieldsType, field, intl) => {
  const fields = getSettingsField(settings, fieldsType);
  return fields && isString(fields[field]) ? fields[field] : intl.formatMessage({ id: field });
};

class DomainSettings extends Component {
  state = {
    addrPattern: '',
    addrPatternUser: '',
    // baseFields
    address: '',
    checkedAt: '',
    employeeId: '',
    employeeName: '',
    // resultFields
    empty: '',
    invalid: '',
    positive: '',
    negative: '',
    null: '',
    // State control
    init: false,
  };

  static getDerivedStateFromProps(props, state) {
    const intl = props.intl;
    if (!state.init) {
      const { isLoaded: settingsLoaded, data: settings } = props.settingsData;
      if (settingsLoaded) {
        const data = {
          addrPattern: getSettingsField(settings, 'addrPattern'),
          addrPatternUser: getSettingsField(settings, 'addrPatternUser'),
          init: true,
        };
        baseFields.forEach((field) => (data[field] = getFieldsOrDefault(settings, 'baseFields', field, intl)));
        resultFields.forEach((field) => (data[field] = getFieldsOrDefault(settings, 'resultFields', field, intl)));
        return data;
      }
    }
    // Null indicates no state change due to changes in properties
    return null;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showConfirmationDialog = (event, action, data) => {
    if (event) {
      event.preventDefault();
    }

    const { intl, domainLicenses, match, settingsData, candidateModeratorsData } = this.props;
    const { isLoaded: settingsLoaded, data: settings } = settingsData;

    const company = match.params.company;
    const domain = match.params.domain;

    const { data: candidateModerators } = candidateModeratorsData;

    // Guarantee valid ownership
    const candidatesCount = Object.keys(candidateModerators).length;
    if (candidatesCount !== 1) {
      this.props.updateUi({
        snackbar: {
          message: intl.formatMessage({
            id: candidatesCount > 1 ? 'manDom.errMultipleParents' : 'manDom.errMissingParent',
          }),
          severity: 'error',
          show: true,
        },
      });
      return;
    }
    const parentUid = Object.keys(candidateModerators)[0];

    let confirmAction, actionData, messageParams, msgId;
    switch (action) {
      case dialogTypes.EDIT_SETTINGS:
        const newSettings = this.buildDatabaseSettings();
        if (!settingsLoaded || !newSettings) return;
        if (this.state.positive === this.state.negative) {
          this.props.updateUi({
            snackbar: {
              message: intl.formatMessage(
                { id: 'dSett.NegEqPosError' },
                {
                  neg: intl.formatMessage({ id: 'negative' }),
                  pos: intl.formatMessage({ id: 'positive' }),
                }
              ),
              severity: 'error',
              show: true,
            },
          });
          return;
        }

        msgId = 'updtConf';
        confirmAction = actions.EDIT_DOMAIN_SETTINGS;
        actionData = {
          company,
          domain,
          parentUid,
          currentSettings: settings,
          newSettings,
        };
        break;
      case dialogTypes.REMOVE_LABEL_DESIGN: {
        const index = data;
        if (!settings || !settings.labelDesigns || index < 0 || index >= settings.labelDesigns.length) {
          return;
        }
        const labelName = settings.labelDesigns[index].name;
        confirmAction = actions.REMOVE_LABEL_DESIGN;
        msgId = 'labelRemConf';
        messageParams = {
          label: (
            <ul key="labelRemConf">
              <li>
                <b>{labelName}</b>
              </li>
            </ul>
          ),
        };
        actionData = {
          company,
          domain,
          parentUid,
          currentLabels: settings.labelDesigns,
          labelName,
          index,
        };
        break;
      }
      case dialogTypes.REMOVE_LICENSE: {
        const licenseKey = data;
        if (!licenseKey) {
          return;
        }
        msgId = 'licRemConf';
        confirmAction = actions.REMOVE_LICENSE;
        messageParams = {
          domain: (
            <ul key="licRemConf">
              <li>
                <b>{buildLicenseDescription(domainLicenses[licenseKey], licenseKey, this.props.intl)}</b>
              </li>
            </ul>
          ),
        };
        actionData = {
          company,
          domain,
          parentUid,
          licenseKey,
        };
        break;
      }
      case dialogTypes.REMOVE_MANUAL_INPUT: {
        const index = data;
        if (!settings || !settings.manualInputFields || index < 0 || index >= settings.manualInputFields.length) {
          return;
        }
        msgId = 'MifRemConf';
        confirmAction = actions.REMOVE_MANUAL_INPUT;
        messageParams = {
          field: (
            <ul key="MifRemConf">
              <li>
                <b>{buildMifDescription(settings.manualInputFields[index], this.props.intl)}</b>
              </li>
            </ul>
          ),
        };
        actionData = {
          company,
          domain,
          parentUid,
          currentData: settings.manualInputFields,
          index,
        };
        break;
      }
      default:
    }

    this.props.updateUi({
      dialog: {
        title: intl.formatMessage({ id: `dSett.${msgId}Title` }),
        message: intl.formatMessage({ id: `dSett.${msgId}Msg` }, messageParams),
        confirmAction,
        actionData,
        useCheckbox: true,
        checkboxState: false,
        checkboxMessage: <b>{intl.formatMessage({ id: `dSett.${msgId}Check` })}</b>,
        show: true,
      },
    });
  };

  buildDatabaseSettings = () => {
    const stateData = { ...this.state };
    const intl = this.props.intl;

    delete stateData.init;
    for (let key in stateData) {
      if (key === 'null') continue; // Null result can be empty

      if (isEmpty(stateData[key])) {
        this.props.updateUi({
          snackbar: {
            message: intl.formatMessage({ id: 'dSett.reqField' }, { field: key }),
            severity: 'error',
            show: true,
          },
        });

        return null;
      }
    }

    return {
      addrPattern: stateData.addrPattern,
      addrPatternUser: stateData.addrPatternUser,
      baseFields: {
        address: stateData.address,
        checkedAt: stateData.checkedAt,
        employeeId: stateData.employeeId,
        employeeName: stateData.employeeName,
      },
      resultFields: {
        empty: stateData.empty,
        invalid: stateData.invalid,
        positive: stateData.positive,
        negative: stateData.negative,
        null: stateData.null,
      },
    };
  };

  render() {
    const { classes, match, theme, settingsData, licensesLoaded, domainLicenses, ui, intl } = this.props;
    const domain = match.params.domain;

    const { isLoaded: settingsLoaded, data: _settings } = settingsData;
    let settings = settingsLoaded ? _settings : null;

    const renderSkeletons = (amount) => {
      const array = [...Array(amount).keys()];
      return (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.inputContainer}
        >
          {array.map((idx) => (
            <Grid item xs={12} key={`sskel${idx}`}>
              <Skeleton type="rect" className={classes.settingsSkeleton} animation="wave" />
            </Grid>
          ))}
        </Grid>
      );
    };

    return (
      <Container className={classes.root}>
        <form onSubmit={(e) => this.showConfirmationDialog(e, dialogTypes.EDIT_SETTINGS)}>
          <Typography variant="h4" className={classes.title}>
            {intl.formatMessage({ id: 'dSett.title' }, { domain })}
          </Typography>

          <Container className={classes.mainContainer}>
            {/* Addressing pattern title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5" className={classes.title}>
                {intl.formatMessage({ id: 'dSett.addrPatternTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <>
                      <Typography variant="subtitle2">
                        {intl.formatMessage({ id: 'dSett.addrPatternInfo' }, { nl: <br key="nl0" /> })}
                        <br />
                        {intl.formatMessage({ id: 'dSett.addrPatternChars' })}
                        <ul>
                          {['R', 'C', 'N', 'A', `*(${intl.formatMessage({ id: 'asterisk' })})`, null].map(
                            (bold, idx) => (
                              <li key={`${bold}${idx}`}>
                                {bold ? <b>{bold}</b> : ''} {intl.formatMessage({ id: `dSett.addrPatternChar${idx}` })}
                              </li>
                            )
                          )}
                        </ul>
                      </Typography>
                      <Typography variant="subtitle2">
                        {intl.formatMessage({ id: 'dSett.addrPatternEgMsg' })}
                        <ul>
                          {[0, 1].map((idx) => (
                            <li key={`eg${idx}`}>{intl.formatMessage({ id: `dSett.addrPatternEg${idx}` })}</li>
                          ))}
                        </ul>
                      </Typography>
                    </>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>
            </Container>
            <Grid //Addressing pattern data
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {settings ? (
                <>
                  <Grid item xs={5}>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      id="addrPattern"
                      name="addrPattern"
                      type="text"
                      label={intl.formatMessage({ id: 'dSett.barcodePattern' })}
                      value={this.state.addrPattern}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <ArrowRightAltIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      id="addrPatternUser"
                      name="addrPatternUser"
                      type="text"
                      label={intl.formatMessage({ id: 'dSett.usrReadablePattern' })}
                      value={this.state.addrPatternUser}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </>
              ) : (
                renderSkeletons(1)
              )}
            </Grid>

            {/* Base codes title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5" className={classes.title}>
                {intl.formatMessage({ id: 'dSett.baseCodesTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">{intl.formatMessage({ id: 'dSett.baseCodesInfo' })}</Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>
            </Container>
            <Grid // Base codes data
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {settings
                ? baseFields.map((field, index) => (
                    <Fragment key={`${field}${index}`}>
                      <Grid item xs={5}>
                        <Typography variant="body1">{intl.formatMessage({ id: field })}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <ArrowRightAltIcon />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          variant="outlined"
                          size="small"
                          id={field}
                          name={field}
                          type="text"
                          value={this.state[field]}
                          onChange={this.handleChange}
                        />
                      </Grid>
                    </Fragment>
                  ))
                : renderSkeletons(2)}
            </Grid>

            {/* Result codes title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5" className={classes.title}>
                {intl.formatMessage({ id: 'dSett.resultsTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">
                      {intl.formatMessage({ id: 'dSett.resultsInfo' }, { nl: <br key="nl1" /> })}
                    </Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>
            </Container>
            <Grid // Result codes data
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {settings
                ? resultFields.map((field, index) => {
                    const isNotNull = !nullableFields[index];
                    return (
                      <Fragment key={`${field}${index}`}>
                        <Grid item xs={5}>
                          <Typography variant="body1">{intl.formatMessage({ id: field })}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <ArrowRightAltIcon />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required={isNotNull} // allow empty null field
                            placeholder={
                              isNotNull
                                ? `<${intl.formatMessage({ id: 'required' })}>`
                                : `<${intl.formatMessage({ id: 'dSett.emptyText' })}>`
                            }
                            variant="outlined"
                            size="small"
                            id={field}
                            name={field}
                            type="text"
                            value={this.state[field]}
                            onChange={this.handleChange}
                          />
                        </Grid>
                      </Fragment>
                    );
                  })
                : renderSkeletons(2)}
            </Grid>

            {/* Label designs title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5">
                {intl.formatMessage({ id: 'dSett.labelDesignsTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">{intl.formatMessage({ id: 'dSett.labelDesignsInfo' })}</Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>

              {settings && (
                <Button
                  color="secondary"
                  aria-label="add"
                  component={Link}
                  to={{
                    pathname: `label/${(settings.labelDesigns && settings.labelDesigns.length) || 0}`,
                    state: { type: 'add' },
                  }}
                >
                  <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                  {intl.formatMessage({ id: 'add' })}
                </Button>
              )}
            </Container>
            <Grid // Label designs data
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {settings ? (
                settings.labelDesigns && settings.labelDesigns.length ? (
                  settings.labelDesigns.map((labelDesign, index) => (
                    <Fragment key={`$ld${index}`}>
                      <Grid item xs={10}>
                        <Typography>{`${index + 1}: ${labelDesign.name}`}</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Tooltip title={intl.formatMessage({ id: 'edit' })}>
                          <IconButton
                            component={Link}
                            to={{ pathname: `label/${index}`, state: { type: 'edit' } }}
                            edge="end"
                            aria-label={intl.formatMessage({ id: 'edit' })}
                            style={{ color: theme.palette.info.main }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={1}>
                        <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                          <IconButton
                            edge="end"
                            aria-label={intl.formatMessage({ id: 'remove' })}
                            style={{ color: theme.palette.error.main }}
                            onClick={(e) => this.showConfirmationDialog(e, dialogTypes.REMOVE_LABEL_DESIGN, index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography>{intl.formatMessage({ id: 'dSett.noLabelAdded' })}</Typography>
                  </Grid>
                )
              ) : (
                renderSkeletons(2)
              )}
            </Grid>

            {/* Manual input fields title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5">
                {intl.formatMessage({ id: 'dSett.manInputTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">
                      {intl.formatMessage({ id: 'dSett.manInputInfo' }, { nl: <br key="nl3" /> })}
                    </Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>

              {settings && (
                <Button
                  color="secondary"
                  aria-label="add"
                  component={Link}
                  to={{
                    pathname: `manualInputField/${
                      (settings.manualInputFields && settings.manualInputFields.length) || 0
                    }`,
                    state: { type: 'add' },
                  }}
                >
                  <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                  {intl.formatMessage({ id: 'add' })}
                </Button>
              )}
            </Container>
            <Grid // Manual input data
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {settings ? (
                settings.manualInputFields && settings.manualInputFields.length ? (
                  settings.manualInputFields.map((mif, index) => (
                    <Fragment key={`$cp${index}`}>
                      <Grid container xs={10} alignItems="center">
                        <Grid item>
                          <Typography>
                            {index + 1}: {buildMifDescription(mif, intl)}
                          </Typography>
                        </Grid>
                        {(mif.showHistory ?? true) && (
                          <Grid item alignContent="center">
                            <Tooltip
                              title={intl.formatMessage({ id: 'mif.showHistory' })}
                              style={{ margin: theme.spacing(1) }}
                            >
                              <HistoryIcon />
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={1}>
                        <Tooltip title={intl.formatMessage({ id: 'edit' })}>
                          <IconButton
                            component={Link}
                            to={{
                              pathname: `manualInputField/${index}`,
                              state: { type: 'edit' },
                            }}
                            edge="end"
                            aria-label={intl.formatMessage({ id: 'edit' })}
                            style={{ color: theme.palette.info.main }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={1}>
                        <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                          <IconButton
                            edge="end"
                            aria-label={intl.formatMessage({ id: 'remove' })}
                            style={{ color: theme.palette.error.main }}
                            onClick={(e) => this.showConfirmationDialog(e, dialogTypes.REMOVE_MANUAL_INPUT, index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography>{intl.formatMessage({ id: 'dSett.noMifAdded' })}</Typography>
                  </Grid>
                )
              ) : (
                renderSkeletons(2)
              )}
            </Grid>

            {/* Licenses title */}
            <Container className={classes.subtitleContainer}>
              <Typography variant="h5">
                {intl.formatMessage({ id: 'dSett.authLicsTitle' })}
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">{intl.formatMessage({ id: 'dSett.authLicsSubtitle' })}</Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Typography>

              {licensesLoaded && (
                <Button
                  component={Link}
                  to="addLicense"
                  color="secondary"
                  aria-label={intl.formatMessage({ id: 'add' })}
                >
                  <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                  {intl.formatMessage({ id: 'add' })}
                </Button>
              )}
            </Container>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              {licensesLoaded ? (
                isEmpty(domainLicenses) ? (
                  <Grid item xs={12}>
                    <Typography>{intl.formatMessage({ id: 'dSett.noLicSet' })}</Typography>
                  </Grid>
                ) : (
                  Object.keys(domainLicenses).map((licKey, index) => {
                    return (
                      <Fragment key={licKey}>
                        <Grid item xs={11}>
                          <Typography>{buildLicenseDescription(domainLicenses[licKey], licKey, intl)}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                            <IconButton
                              edge="end"
                              aria-label={intl.formatMessage({ id: 'remove' })}
                              style={{ color: theme.palette.error.main }}
                              onClick={(e) => this.showConfirmationDialog(e, dialogTypes.REMOVE_LICENSE, licKey)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Fragment>
                    );
                  })
                )
              ) : (
                <Grid item xs={12}>
                  <Skeleton type="rect" className={classes.licenseSkeleton} animation="wave" />
                </Grid>
              )}
            </Grid>
            {isLoaded(settings) && licensesLoaded && (
              <LoadingButton
                variant="contained"
                color="secondary"
                className={classes.button}
                isLoading={ui.loadingButton.isEditDomainLoading}
                content={intl.formatMessage({ id: 'saveChanges' })}
                type="submit"
              />
            )}

            {/* Submit input is necessary for firefox to allow submit triggers */}
            <input type="submit" style={{ display: 'none' }} />
          </Container>
        </form>
      </Container>
    );
  }
}

export default compose(
  connect(
    (state, { match }) => {
      const domain = match.params.domain;
      const company = match.params.company;

      const { isLoaded: licLoaded, data: domainLicenses } = selectLicensesPerDomain(domain, state);
      const settingsData = selectDomainSettings(company, domain, state);

      return {
        ui: state.ui,
        settingsData,
        domainLicenses,
        candidateModeratorsData: selectParentCandidateModerators(state, company, domain),
        licensesLoaded: licLoaded, // for loaded check
      };
    },
    (dispatch, ownProps) => ({
      ...ownProps,
      updateUi: (uiData) => dispatch(updateUiAction(uiData)),
    })
  ),
  withStyles(styles),
  withTheme,
  injectIntl
)(DomainSettings);
