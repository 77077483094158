import React from 'react';
import { PropTypes } from 'prop-types';
import {
  TableCell,
  TableRow,
  TableHead,
  Checkbox,
  TableSortLabel,
  Tooltip,
  lighten,
  IconButton,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  header: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.85),
  },
  headerText: {
    fontWeight: 'bold',
  },
}));

const EnhancedTableHead = (props) => {
  const { onSelectAllClick, order, orderBy, selectedCount, rowsCount, onRequestSort, columns } = props;
  const classes = useStyles(useStyles);
  const intl = useIntl();
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.header}>
        <Tooltip title={intl.formatMessage({ id: 'eth.selectAll' })}>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selectedCount > 0 && selectedCount < rowsCount}
              checked={selectedCount === rowsCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rows' }}
            />
          </TableCell>
        </Tooltip>
        {props.showPicture && (
          <Tooltip title={intl.formatMessage({ id: 'eth.assPicture' })}>
            <TableCell padding="none">
              <IconButton aria-label="imageColumn" style={{ marginRight: theme.spacing(2), padding: 0 }}>
                <WallpaperIcon fontSize="large" />
              </IconButton>
            </TableCell>
          </Tooltip>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              className={classes.headerText}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  selectedCount: PropTypes.number.isRequired,
  rowsCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
};

export default EnhancedTableHead;
