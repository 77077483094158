import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Badge, Button, Checkbox, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from '@material-ui/core';
// @ts-ignore
import FilterListIcon from '@material-ui/icons/FilterList';
import { useIntl } from 'react-intl';

type Props = {
  /** All filters available for selection */
  availableFilters: string[] | Record<string, string>;
  /** Callback for filter changes */
  onFilterChange: (selected: string[]) => void;
  /** The initially selected filters, if any */
  initialState?: string[];
};

/**
 * Filter component for selection of elements using a with multi-select dropdown.
 */
const DropdownFilter: React.FC<Props> = ({ availableFilters, initialState, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { $t } = useIntl();
  const { spacing } = useTheme();

  const [selectedFilters, setSelectedFilters] = useState<string[]>(initialState ?? []);

  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (selected: string) => {
    setSelectedFilters((prev) => {
      const newSelected = prev.includes(selected) ? prev.filter((f) => f !== selected) : [...prev, selected];
      return newSelected;
    });
  };

  const handleClearFilters: React.MouseEventHandler<HTMLButtonElement> = () => {
    setSelectedFilters([]);
  };

  useEffect(() => {
    onFilterChange(selectedFilters);
  }, [selectedFilters, onFilterChange]);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Button variant="text" onClick={handleClick} startIcon={<FilterListIcon />} style={{ paddingRight: spacing(2) }}>
        {$t({ id: 'filters' })}
        {selectedFilters.length > 0 && (
          <Badge
            badgeContent={selectedFilters.length}
            color="secondary"
            style={{ paddingLeft: spacing(1.5), paddingRight: spacing(1) }}
          />
        )}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {(Array.isArray(availableFilters) ? availableFilters : Object.keys(availableFilters)).map((key) => (
          <MenuItem key={key} onClick={() => handleFilterChange(key)}>
            <ListItemIcon>
              <Checkbox checked={selectedFilters.includes(key)} />
            </ListItemIcon>
            <ListItemText primary={Array.isArray(availableFilters) ? key : availableFilters[key]} />
          </MenuItem>
        ))}
      </Menu>

      {selectedFilters.length > 0 && (
        <Button variant="outlined" onClick={handleClearFilters} style={{ marginLeft: spacing(2) }}>
          {$t({ id: 'clearFilters' })}
        </Button>
      )}
    </div>
  );
};

export default DropdownFilter;
