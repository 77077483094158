import {
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { deburr, isObject } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createApiKey } from '../../redux/actions/managementActions';
import { updateUi } from '../../redux/actions/uiActions';
import {
  groupByCompany,
  selectCompanies,
  selectCompaniesDomains,
  selectModerators,
} from '../../redux/selectors/userSelectors';
import { checkValidId } from '../../util/helpers/validation';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = makeStyles(({ spacing, container, palette }) => ({
  container: {
    ...container(2),
    marginLeft: 0,
    maxWidth: '650px',
  },
  button: {
    margin: spacing(2, 0, 2, 0),
  },
  title: {
    color: palette.other.grey.dark,
    marginBottom: spacing(2),
  },
  input: {
    marginBottom: spacing(2),
  },
  formControl: {
    width: '100%',
    marginBottom: spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

export default function CreateApiKey(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [company, setCompany] = useState('');
  const [modUid, setModUid] = useState('');
  const [description, setDescription] = useState('');
  const [domains, setDomains] = useState([]);

  const { ui, companies, domainsPerCompanyData, moderatorProfilesData } = useSelector((state) => {
    const companies = selectCompanies(state);
    return {
      ui: state.ui,
      companies,
      domainsPerCompanyData: selectCompaniesDomains(companies, state),
      moderatorProfilesData: selectModerators(state),
    };
  });

  // Get domains
  const { isLoaded: isDomainsPerCompanyLoaded, data: domainsByCompany } = domainsPerCompanyData;
  let companyDomains = (isDomainsPerCompanyLoaded && company && domainsByCompany[company]) || [];

  // Get moderators by company
  const { isLoaded: areModeratorProfilesLoaded } = moderatorProfilesData;
  const moderatorsByCompany = groupByCompany(moderatorProfilesData);

  // Select company moderators:
  const canSelectModerators = areModeratorProfilesLoaded && company;
  const companyModerators = canSelectModerators ? moderatorsByCompany[company] || {} : {};

  const changeCompany = (company) => {
    setCompany(company);
    setModUid('');
    setDomains([]);
  };

  const changeModUid = (modUid) => {
    setModUid(modUid);
    setDomains([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const error = checkValidId(description);
    if (isObject(error)) {
      dispatch(
        updateUi({
          snackbar: {
            message: error,
            severity: 'error',
            show: true,
          },
        })
      );

      return;
    }

    dispatch(createApiKey({ description, authDomains: domains, company, parentUid: modUid }));
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={clsx(classes.title)}>
          {intl.formatMessage({ id: 'createApiKey.title' })}
        </Typography>
        <TextField
          select
          fullWidth
          required
          name="company"
          label={intl.formatMessage({ id: 'company' })}
          value={company}
          className={classes.input}
          onChange={(e) => changeCompany(e.target.value)}
        >
          {companies.map((company) => (
            <MenuItem value={company} key={company}>
              {company}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          disabled={!canSelectModerators}
          fullWidth
          required
          label={intl.formatMessage({ id: 'moderator' })}
          value={modUid}
          className={classes.input}
          onChange={(e) => changeModUid(e.target.value)}
          inputProps={{ name: 'inputType', id: 'type-select' }}
        >
          {Object.entries(companyModerators).map(([uid, profile]) => (
            <MenuItem value={uid} key={uid}>
              {`${profile.firstName} ${profile.lastName}`}
            </MenuItem>
          ))}
        </TextField>

        {!company || !modUid || !isEmpty(companyDomains) ? (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="authDomains">{intl.formatMessage({ id: 'editApiKeyAuthDom.authDomains' })}</InputLabel>
            <Select
              label="authDomains"
              name="authDomains"
              id="authDomains-select"
              multiple
              disabled={!company || !modUid}
              value={domains}
              onChange={(e) => setDomains(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {companyDomains.map((domain) => (
                <MenuItem key={domain} value={domain}>
                  <Checkbox checked={domains.indexOf(domain) > -1} />
                  <ListItemText primary={domain} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography className={classes.input}>
            {intl.formatMessage({ id: 'editApiKeyAuthDom.noDomainsAvailable' })}
          </Typography>
        )}

        <TextField
          fullWidth
          required
          id="description"
          name="description"
          type="text"
          label={intl.formatMessage({ id: 'createApiKey.input' })}
          value={description}
          onChange={(e) => setDescription(deburr(e.target.value))}
          className={classes.input}
          autoFocus
        />
        <LoadingButton
          isLoading={ui.loadingButton.isCreateApiKeyLoading}
          content={intl.formatMessage({ id: 'createApiKey.title' })}
          type="submit"
        />
      </form>
    </Container>
  );
}
