import messages_pt from '../locale/pt.json';
import messages_en from '../locale/en.json';
import messages_es from '../locale/es.json';

export const supportedMessages = {
  'pt-BR': messages_pt,
  'en-GB': messages_en,
  'en-US': messages_en,
  'es-ES': messages_es,
};

export const LOCALE_READABLE = {
  'pt-BR': 'Português (BR)',
  'en-GB': 'English (GB)',
  'en-US': 'English (US)',
  'es-ES': 'Español (ES)',
};
export const SUPPORTED_LOCALES = ['pt-BR', 'en-GB', 'en-US', 'es-ES'];

const DEFAULT_LOCALE = 'en-US';

const LOCALE_ALIASES = {
  en: 'en-US',
  pt: 'pt-BR',
  'pt-PT': 'pt-BR',
  es: 'es-ES',
};

const getBrowserLocales = () => {
  let locales = [].concat(
    navigator.languages,
    navigator.language,
    navigator.userLanguage,
    navigator.browserLanguage,
    navigator.systemLanguage
  );
  locales = locales.filter((locale) => locale).map((locale) => LOCALE_ALIASES[locale] || locale);

  return [...new Set([locales])];
};

export const pickLocale = (proposedLocales) => {
  const browserLocales = proposedLocales || getBrowserLocales();
  let result = null;

  for (let i = 0; i < browserLocales.length && result === null; i++) {
    if (SUPPORTED_LOCALES.indexOf(browserLocales[i]) !== -1) {
      result = browserLocales[i];
    }
  }

  if (result === null) {
    result = DEFAULT_LOCALE;
  }

  return result;
};

export const localeToUse = pickLocale();
export const messages = supportedMessages[localeToUse];
