import React from 'react';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from './../../assets/images/logo/logo_fly_id.png';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  splashStyle: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    position: 'absolute',
    maxWidth: 'none',
  },
  logo: {
    width: 272,
    height: 120,
  },
}));

export default function Splash(props) {
  const classes = useStyles();
  setTimeout(localStorage.setItem('firstTimeInit', false), 1000);

  return (
    <div className={classes.splashStyle}>
      {logo ? (
        <CardMedia component="img" alt="Company Logo" className={classes.logo} image={logo} title="Company Logo" />
      ) : (
        <Skeleton variant="rect" className={classes.logo} />
      )}
    </div>
  );
}
