import { wrap, releaseProxy } from 'comlink';
import { ReaderStatus } from '../helpers/files';

function getWorker() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker('../../workers/fileWorker.js', {
    name: 'fileWorker',
    type: 'module',
  });

  const workerApi = wrap(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
}

// Simple file reader readAsDataUrl method running on a worker
export function urlDataReader(urlData, callbacks = {}) {
  const { workerApi, cleanup } = getWorker();
  handleReaderResult(workerApi.readAsDataUrl(urlData), cleanup, callbacks);
}

// Simple file reader readAsDataUrl method running on a worker
export function arrayBufferDataReader(bufferData, callbacks = {}) {
  const { workerApi, cleanup } = getWorker();
  handleReaderResult(workerApi.readAsArrayBuffer(bufferData), cleanup, callbacks);
}

function handleReaderResult(promise, cleanup, callbacks) {
  promise
    .then(([stat, result]) => {
      switch (stat) {
        case ReaderStatus.ERROR:
        case ReaderStatus.ABORTED: {
          if (!!callbacks.onerror) callbacks.onerror(`Failed to read data: ${result}`);
          break;
        }
        case ReaderStatus.SUCCESS: {
          if (!!callbacks.onload) callbacks.onload(result);
          break;
        }
        default:
          if (!!callbacks.onerror) callbacks.onerror('Unknown state!');
          break;
      }
      cleanup();
    })
    .catch((err) => {
      if (!!callbacks.onerror) callbacks.onerror(err);
      cleanup();
    });
}
