import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@material-ui/core/styles';
import getTheme from '../../theme/theme';

const onError =
  process.env.REACT_APP_ENV === 'production'
    ? (err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          // console.warn('Missing translation', err.message);
          return;
        }
        throw err;
      }
    : undefined;

export default function Inter(props) {
  const { children } = props;
  const { locale, messages } = useSelector(({ locale }) => locale);

  return (
    <ThemeProvider theme={getTheme(locale)}>
      <IntlProvider locale={locale} messages={messages} onError={onError}>
        {children}
      </IntlProvider>
    </ThemeProvider>
  );
}
