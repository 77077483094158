import { isEmpty, isObject, isString } from 'lodash';
import pako from 'pako';
import { parseTimestampToDate } from '../../util/helpers/server';
import { sortObjectByKeys } from './other';

const flags = ['__scannedCode', '__hasPicture', '__pictureFilename'];
const multipleProductPerPosFlag = '__multipleProductPerPos';
const dateFields = ['checkedAt', 'startTime', 'endTime'];

export const excelTabInvalidChars = /[:/\\*?[\]]/g;

/**
 * @param {*} dataFields Header fields array
 */
export function getHeaderCellsFromArray(dataFields) {
  let headerCells = [];
  for (let i = 0; i < dataFields.length; i++) {
    let isFirst = i === 0;
    let field = dataFields[i];

    headerCells.push({
      id: field,
      numeric: !isFirst,
      isDate: dateFields.indexOf(field) > -1,
      disablePadding: isFirst,
      label: field
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .toUpperCase(),
    });
  }
  return headerCells;
}

/**
 * @param {*} dataFields Header fields object in the format {[fieldName]: fieldLabel}
 */
export function getHeaderCellsFromObject(dataFields) {
  if (isEmpty(dataFields)) {
    return [];
  }

  let headerCells = [];
  let isFirst = true;
  Object.keys(dataFields).forEach((field) => {
    headerCells.push({
      id: field,
      numeric: !isFirst,
      disablePadding: isFirst,
      isDate: dateFields.indexOf(field) > -1,
      label: dataFields[field],
    });
    isFirst = false;
  });

  return headerCells;
}

export function getTableData(sessionData, sett, options) {
  const defaultOptions = {
    showInputData: false,
    showBaseFields: false,
    mergeLabels: false,
  };

  let rows = [],
    columns = [],
    dataFields = {},
    rowsToReturn = new Map();

  const { showInputData, showBaseFields, mergeLabels } = Object.assign(defaultOptions, options);
  const addresses = sessionData && sessionData.addresses;

  let shouldMergeLabels = mergeLabels;
  if (!!sett && !!addresses) {
    const nullResult = (sett.resultFields && sett.resultFields.null) || '';

    let barcodePatterns = [];
    sett.labelDesigns.forEach((label) => barcodePatterns.push(...label.barcodePatterns));

    const baseFieldsWithoutAddress = { ...sett.baseFields };
    delete baseFieldsWithoutAddress.address;

    const addressResult = sett.baseFields.address;
    const sortedBaseFieldsWithoutAddress = sortObjectByKeys(baseFieldsWithoutAddress);

    // If showInputData is selected, show results in the exact order as input data, otherwise
    // just add address column
    if (showInputData && sessionData.orderedInputColumns)
      addFields(dataFields, sessionData.orderedInputColumns, addressResult);
    else addFields(dataFields, { address: addressResult });
    // Add code patterns and mif in between address and other base fields
    addBarcodePatternFields(dataFields, barcodePatterns);
    addManualInputFields(dataFields, sett.manualInputFields);
    // If showBaseFields is true, then add checktime and user related basefields
    if (showBaseFields) addFields(dataFields, sortedBaseFieldsWithoutAddress);

    const decodedAddresses = getInflatedSessionData(addresses);

    // Columns must contain address
    columns = !isEmpty(dataFields) ? getHeaderCellsFromObject(dataFields) : [];

    // Do not merge address data when there is any multipleProductPerPosFlag set, since
    // data would have been captured on multipleProductPerPos mode, which resolves to merged data.
    if (mergeLabels) {
      for (let decodedAddressData of Object.values(decodedAddresses)) {
        if (!Array.isArray(decodedAddressData)) {
          if (decodedAddressData[multipleProductPerPosFlag]) {
            shouldMergeLabels = false;
            break;
          }
        } else {
          if (decodedAddressData.some((d) => d[multipleProductPerPosFlag])) {
            shouldMergeLabels = false;
            break;
          }
        }
      }
    }

    const capturableDataFields = shouldMergeLabels
      ? [
        ...getBarcodePatternFields(barcodePatterns),
        ...getManualInputFields(sett.manualInputFields),
        // some flags must be updated on merge as well
        ...flags.slice(1),
      ]
      : [];

    for (let address in decodedAddresses) {
      let decodedAddressData = decodedAddresses[address];
      // Handle data before multiple codes per address
      if (!Array.isArray(decodedAddressData)) {
        decodedAddressData = [decodedAddressData];
      }

      for (const addressData of decodedAddressData) {
        if (addressData) {
          addressData.address = address;
          if (addressData.checkedAt) {
            addressData.checkedAt = parseTimestampToDate(addressData.checkedAt);
          }

          if (shouldMergeLabels) {
            if (rowsToReturn.has(address)) {
              let currentData = rowsToReturn.get(address);
              for (let dataField of capturableDataFields) {
                let currValue = addressData[dataField];
                if (currValue !== nullResult && currValue !== undefined) {
                  currentData[dataField] = currValue;
                }
              }
              if (addressData.checkedAt > currentData.checkedAt) {
                currentData.checkedAt = addressData.checkedAt;
              }
            } else {
              rowsToReturn.set(address, { ...addressData });
            }
          } else {
            rows.push(addressData);
          }
        }
      }
    }
  }

  return {
    rows: shouldMergeLabels ? Array.from(rowsToReturn.values()) : rows,
    columns,
  };
}

export function addFields(dataFields, fieldsList, addressResult) {
  if (!isEmpty(fieldsList)) {
    if (Array.isArray(fieldsList)) {
      // List of fields to add, no translation/corresponding value
      fieldsList.forEach((field) => {
        if (!Object.hasOwnProperty(dataFields, field)) {
          if (field !== addressResult) dataFields[field] = field;
          // If field is an address result, make correct replacement
          else dataFields.address = addressResult;
        }
      });
    } else {
      // Keys and corresponding value
      Object.entries(fieldsList).forEach(([field, value]) => {
        if (!Object.hasOwnProperty(dataFields, field)) {
          dataFields[field] = value;
        }
      });
    }
  }
}

export function addManualInputFields(dataFields, manualInputFields) {
  if (!isEmpty(manualInputFields)) {
    manualInputFields.forEach((mif) => {
      if (!Object.hasOwnProperty(dataFields, mif.field)) {
        dataFields[mif.field] = mif.field;
      }
    });
  }
}

export function addBarcodePatternFields(dataFields, barcodePatterns) {
  if (!isEmpty(barcodePatterns)) {
    barcodePatterns.forEach((barcodePattern) => {
      if (!isEmpty(barcodePattern.dataFields)) {
        barcodePattern.dataFields.forEach((field) => {
          if (field.type === 'data' && !Object.hasOwnProperty(dataFields, field.name)) {
            dataFields[field.name] = field.name;
          }
        });
      }
    });
  }
}

export function getBarcodePatternFields(barcodePatterns) {
  const ret = new Set();
  if (!isEmpty(barcodePatterns)) {
    barcodePatterns.forEach((barcodePattern) => {
      if (!isEmpty(barcodePattern.dataFields)) {
        barcodePattern.dataFields.forEach((field) => {
          if (field.type === 'data') {
            ret.add(field.name);
          }
        });
      }
    });
  }
  return Array.from(ret.values());
}

export function getManualInputFields(manualInputFields) {
  const ret = new Set();
  if (!isEmpty(manualInputFields)) {
    manualInputFields.forEach((mif) => {
      ret.add(mif.field);
    });
  }
  return Array.from(ret.values());
}

export const getInflatedSessionData = (data) => {
  if (isString(data)) {
    try {
      // Handle deflated data
      return JSON.parse(
        pako.inflate(Buffer.from(data, 'base64'), {
          to: 'string',
        })
      );
    } catch (e) {
      // Handle non-deflated data
      return JSON.parse(data);
    }
  } // handle non deflated collection-document based data
  else if (isObject(data)) {
    return data;
  }
}