import React from 'react';
import { Container, Typography, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loadingText: {
    margin: theme.spacing(2),
  },
  progressCircle: {
    margin: theme.spacing(2),
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
}));

export default function LoadingCircle(props) {
  const classes = useStyles();
  const { text } = props;

  return (
    <Container className={classes.centeredColumn}>
      <CircularProgress
        className={classes.progressCircle}
        style={{
          width: 50,
          height: 50,
        }}
      />
      {text && text.length && (
        <Typography variant="h5" className={classes.loadingText}>
          {text}
        </Typography>
      )}
    </Container>
  );
}
