// Libraries & Modules
import React from 'react';
import './App.css';

// Components
import { Router, Route, Switch } from 'react-router-dom';
//@ts-ignore
import NavBar from './components/layout/NavBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import history from './history';

// Pages
//@ts-ignore
import LandingLoading from './components/layout/LandingLoading';
//@ts-ignore
import ProtectedRoute from './components/utils/ProtectedRoute';

//@ts-ignore
import EmailActionsHandler from './components/utils/EmailActionsHandler';

//@ts-ignore
import Main from './components/dashboard/Main';

//@ts-ignore
import Domain from './components/dashboard/Domain';
//@ts-ignore
import Company from './components/dashboard/Company';
//@ts-ignore
import Session from './components/dashboard/Session';
//@ts-ignore
import InventoryTasks from './components/dashboard/InventoryTasks';
//@ts-ignore
import CreateInventoryTask from './components/dashboard/Tasks/CreateInventoryTask';
//@ts-ignore
// import AllAddresses from './components/dashboard/AllAddresses';

//@ts-ignore
import Login from './components/user/Login';
//@ts-ignore
import AddUser from './components/user/AddUser';
//@ts-ignore
import SetPassword from './components/user/SetPassword';
//@ts-ignore
import ForgotPassword from './components/user/ForgotPassword';
//@ts-ignore
import UserProfile from './components/user/UserProfile';
//@ts-ignore
import ChangePassword from './components/user/ChangePassword';

//@ts-ignore
import ManageUsers from './components/management/ManageUsers';
//@ts-ignore
import ManageDomains from './components/management/ManageDomains';
//@ts-ignore
import CreateApiKey from './components/management/CreateApiKey';
//@ts-ignore
import ManageApiKeys from './components/management/ManageApiKeys';
//@ts-ignore
import EditApiKeyAuthDomains from './components/management/EditApiKeyAuthDomains';
//@ts-ignore
import AddDomain from './components/management/AddDomain';
//@ts-ignore
import DomainSettings from './components/management/DomainSettings';
//@ts-ignore
import LabelDesign from './components/management/labeldesign/LabelDesign';
//@ts-ignore
import AddLicense from './components/management/AddLicense';
//@ts-ignore
import ManualInputField from './components/management/ManualInputField';

//Tests
// import TypescriptTest from './components/test/TypescriptTest';

const App = () => (
  <LandingLoading>
    <Router history={history}>
      <div className="App">
        <CssBaseline />
        <NavBar>
          <Switch>
            {/* Email actions handling routes */}
            <Route exact path="/emailActions" component={EmailActionsHandler} />
            {/* Dashboard routes */}
            {/* <ProtectedRoute path="/domains/:domain/all" component={AllAddresses} /> */}
            <ProtectedRoute exact path="/company/:company" component={Company} />
            <ProtectedRoute exact path="/company/:company/domains/:domain" component={Domain} />
            <ProtectedRoute exact path="/company/:company/domains/:domain/tasks" component={InventoryTasks} />
            <ProtectedRoute
              exact
              path="/company/:company/domains/:domain/tasks/create"
              component={CreateInventoryTask}
            />
            <ProtectedRoute exact path="/company/:company/domains/:domain/sessions/:session" component={Session} />
            {/* User related routes */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpw" component={ForgotPassword} />
            <ProtectedRoute exact path="/setpw" component={SetPassword} />
            <ProtectedRoute exact path="/changepw" component={ChangePassword} />
            <ProtectedRoute exact path="/profile" component={UserProfile} />
            {/* Management routes (only for keyUsers */}
            <ProtectedRoute kuOnly exact path="/manageusers" component={ManageUsers} />
            <ProtectedRoute kuOnly exact path="/managedomains" component={ManageDomains} />
            <ProtectedRoute kuOnly exact path="/managekeys" component={ManageApiKeys} />
            <ProtectedRoute kuOnly exact path="/managekeys/create" component={CreateApiKey} />
            <ProtectedRoute kuOnly exact path="/managekeys/:apiKeyId/authDomains" component={EditApiKeyAuthDomains} />
            <ProtectedRoute kuOnly exact path="/addDomain" component={AddDomain} />
            <ProtectedRoute kuOnly exact path="/adduser" component={AddUser} />
            <ProtectedRoute kuOnly exact path="/company/:company/domain/:domain/settings" component={DomainSettings} />
            <ProtectedRoute kuOnly exact path="/company/:company/domain/:domain/addLicense" component={AddLicense} />
            <ProtectedRoute
              kuOnly
              exact
              path="/company/:company/domain/:domain/manualInputField/:index"
              component={ManualInputField}
            />
            <ProtectedRoute kuOnly exact path="/company/:company/domain/:domain/label/:index" component={LabelDesign} />
            <ProtectedRoute kuOnly exact path="/profile/:uid" component={UserProfile} />
            {/* Main */}
            <ProtectedRoute path="/" component={Main} />
          </Switch>
        </NavBar>
      </div>
    </Router>
  </LandingLoading>
);

export default App;
