import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { sprintf } from 'sprintf-js';
import { createTask } from '../../../redux/actions/operationalActions';
import { updateUi } from '../../../redux/actions/uiActions';
import { getFirst } from '../../../util/helpers/other';
import { getDomainsPath } from '../../../util/helpers/path';
import { acceptableTaskErrors, getExpectedColumns, getTaskExampleAddresses } from '../../../util/helpers/tasks';
import { arrayBufferDataReader } from '../../../util/workers/fileWorkerUtils';
import { useTaskInputFileParser } from '../../../workers/dataWorkerApi';
import LoadingButton from '../../widgets/LoadingButton';
import { checkValidId } from './../../../util/helpers/validation';
import { isObject } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.container(2),
    maxWidth: '1200px',
    marginLeft: theme.spacing(2),
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  tooltip: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  tooltipRoot: {
    maxWidth: 500,
  },
}));

export default function CreateInventoryTask(props) {
  const [name, setName] = useState('');
  const [inputFileData, setInputFileData] = useState();
  const [errorAccepted, setErrorAccepted] = useState(false);

  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const match = props.match;
  const domain = match.params.domain;
  const company = match.params.company;

  const { ui, settings, domainData } = useSelector(({ ui, firestore: { data }, firebase: { profile } }) => ({
    ui,
    settings: match ? getFirst(data[`${company}-sett${domain}`]) : null,
    profile,
    domainData: data[`preScript${domain}`],
  }));

  useFirestoreConnect([
    {
      collection: getDomainsPath(company),
      doc: domain,
      storeAs: `preScript${domain}`,
    },
  ]);

  const {
    isWorking,
    parsedData: parsedInputData,
    error: taskError,
  } = useTaskInputFileParser(
    inputFileData,
    isLoaded(settings) ? settings : undefined,
    isLoaded(domainData) && domainData?.taskPreprocessingScript
  );

  const hasParsedData = !!parsedInputData;
  const isErrorAcceptable = !!taskError && acceptableTaskErrors.includes(taskError.msgCode);

  const tasksCount = hasParsedData ? Object.keys(parsedInputData).length : 0;

  // Expected columns to be used as reference for the user
  let expectedColumns = null;
  if (!!settings) {
    const { expectedDataColumns } = getExpectedColumns(settings);
    expectedColumns = [settings.baseFields.address, ...expectedDataColumns];
  }

  const clearInputFile = () => {
    setInputFileData(null);
    inputRef.current.value = null;
  };

  const removeFileExtension = (_filename) => {
    let filename = _filename.split('.');
    if (filename.length > 1) {
      filename = filename.slice(0, filename.length - 1);
    }
    return filename.join('.');
  };

  const getCurrenTaskName = () => {
    let nameToUse = name;
    if (tasksCount < 0) return;
    else if (tasksCount === 1 && !nameToUse) {
      if (!inputFileData || !inputFileData.name) return;
      // Set task name the same as file name, removing file extension if any
      nameToUse = removeFileExtension(inputFileData.name);
    }
    return nameToUse;
  };

  const handleFileChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    if (file) {
      arrayBufferDataReader(file, {
        onload: (result) =>
          setInputFileData({
            name: file.name,
            size: file.size,
            type: file.type,
            arrayBuffer: result,
          }),
        onerror: console.error,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const nameToUse = getCurrenTaskName()?.trim();
    if (!nameToUse) return;

    const error = checkValidId(nameToUse);
    if (isObject(error)) {
      dispatch(
        updateUi({
          snackbar: {
            message: error,
            severity: 'error',
            show: true,
          },
        })
      );
      return;
    }

    if (!hasParsedData || (taskError && (!isErrorAcceptable || !errorAccepted))) {
      dispatch(
        updateUi({
          snackbar: {
            message: intl.formatMessage({ id: 'createInventoryTask.missingValidFile' }),
            severity: 'error',
            duration: 2000,
            show: true,
          },
        })
      );
      return;
    }

    const taskData = {
      name: nameToUse,
      company,
      domain,
      inputFileData,
    };
    dispatch(createTask(taskData));
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" style={theme.text.title}>
        {intl.formatMessage({ id: 'createInventoryTask.title' }, { domain: domain })}
      </Typography>
      <Typography variant="subtitle1" style={theme.text.subtitle}>
        {intl.formatMessage({ id: 'createInventoryTask.subtitle' })}
        <Tooltip
          className={classes.tooltip}
          classes={{ tooltip: classes.tooltipRoot }}
          title={
            expectedColumns ? (
              <Typography variant="subtitle2">
                {intl.formatMessage({ id: 'createInventoryTask.tooltip' }, { columns: expectedColumns.join(', ') })}
              </Typography>
            ) : (
              ''
            )
          }
        >
          <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
        </Tooltip>
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield and max attempts */}
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              placeholder={tasksCount > 1 ? '' : removeFileExtension(inputFileData ? inputFileData.name : '')}
              id="name"
              name="name"
              type="text"
              InputLabelProps={{ shrink: true }}
              label={intl.formatMessage({
                id: tasksCount > 1 ? 'createInventoryTask.taskBaseName' : 'createInventoryTask.taskName',
              })}
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.horizontalFlex}>
              <input
                disabled={isWorking || !isLoaded(domainData)}
                accept=".csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: 'none' }}
                id="file-input-btn"
                type="file"
                onChange={isWorking || !isLoaded(domainData) ? undefined : handleFileChange}
                ref={inputRef}
              />
              <label htmlFor="file-input-btn">
                <Button
                  disabled={isWorking || !isLoaded(domainData)}
                  variant="contained"
                  component="span"
                  disableElevation
                  style={{ minWidth: theme.spacing(20) }}
                >
                  {intl.formatMessage({ id: 'createInventoryTask.uploadFile' })}
                </Button>
              </label>

              {isWorking ? (
                <CircularProgress style={{ marginLeft: theme.spacing(2) }} />
              ) : (
                inputFileData && (
                  <>
                    <Typography variant="body1" className={classes.marginLeft}>
                      {inputFileData.name}
                    </Typography>
                    <IconButton aria-label={'someMsg'} onClick={clearInputFile}>
                      <ClearIcon style={{ color: theme.palette.error.main }} />
                    </IconButton>
                  </>
                )
              )}
            </div>
          </Grid>
        </Grid>

        {taskError ? (
          <>
            <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
              <Typography style={{ color: theme.palette.error.main }}>
                {intl.formatMessage(
                  { id: 'createInventoryTask.failedParsingData' },
                  {
                    error: sprintf(
                      intl.formatMessage({ id: taskError.msgCode, defaultMessage: taskError.msg }),
                      ...(Array.isArray(taskError.args) ? taskError.args : [])
                    ),
                  }
                )}
              </Typography>
            </Grid>
            {isErrorAcceptable && (
              <FormControlLabel
                style={{ marginTop: theme.spacing(2) }}
                control={<Checkbox checked={errorAccepted} onChange={(e) => setErrorAccepted(e.target.checked)} />}
                label={
                  <Typography variant="body2">
                    {intl.formatMessage({ id: 'createInventoryTask.errorAcceptance' })}
                  </Typography>
                }
              />
            )}
          </>
        ) : (
          hasParsedData && (
            <>
              <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
                <Typography style={{ color: theme.palette.success.main }}>
                  {intl.formatMessage(
                    { id: 'createInventoryTask.parsedDataInfoTitle' },
                    { count: Object.keys(parsedInputData).length }
                  )}
                </Typography>
              </Grid>
              {Object.entries(parsedInputData).map(([sheetName, sheetData]) => (
                <Grid item xs={12} style={{ marginTop: theme.spacing(2) }} key={sheetName}>
                  <Typography style={{ color: theme.palette.success.main }}>
                    {intl.formatMessage(
                      { id: 'createInventoryTask.parsedDataInfo' },
                      {
                        sheetName,
                        taskName:
                          tasksCount > 1 ? (name ? `${name}${sheetName}` : sheetName) : name || getCurrenTaskName(),
                        size: sheetData.addressesCount,
                        exampleAddresses: getTaskExampleAddresses(sheetData),
                      }
                    )}
                  </Typography>
                </Grid>
              ))}
            </>
          )
        )}
        <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
          <LoadingButton
            isLoading={ui.loadingButton.isCreateTaskLoading}
            content={intl.formatMessage({ id: 'create' })}
            type="submit"
          />
        </Grid>
      </form>
    </Container>
  );
}
