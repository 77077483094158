import {
  Box,
  Container,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import { Skeleton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, pickBy } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi } from '../../redux/actions/uiActions';
import { selectCompanies, selectCompaniesDomains, selectModerators } from '../../redux/selectors/userSelectors';

const useStyles = makeStyles((theme) => ({
  container: { ...theme.container(2), marginLeft: 0 },
  listContainer: {
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(70),
    marginBottom: theme.spacing(2),
  },
  tabContainer: { maxWidth: theme.spacing(100) },
  listItem: {
    paddingRight: theme.spacing(15),
    width: 'auto',
  },
  itemText: {
    margin: theme.spacing(1, 1),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(0.5),
  },
  iconContainer: {
    display: 'flex',
    alignContent: 'center',
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  skeletonItemText: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8),
  },
}));

export default function ManageDomains(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { companies, authDomainsData, moderatorProfilesData } = useSelector((s) => {
    const companies = selectCompanies(s);
    return {
      companies,
      authDomainsData: selectCompaniesDomains(companies, s),
      moderatorProfilesData: selectModerators(s),
    };
  });

  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem('selectedCompany') || (companies && companies[0]) || ''
  );

  const showRemoveDomainDialogConfirmation = (company, domain) => {
    const { isLoaded: areModProfilesLoaded, data: modProfiles } = moderatorProfilesData;
    if (!areModProfilesLoaded || !company) return;
    const domainOwnerCandidates = pickBy(
      modProfiles,
      (prof) => prof.company === company && prof.authDomains && prof.authDomains.includes(domain)
    );

    const candidatesCount = Object.keys(domainOwnerCandidates).length;
    if (candidatesCount !== 1) {
      dispatch(
        updateUi({
          snackbar: {
            message: intl.formatMessage({
              id: candidatesCount > 1 ? 'manDom.errMultipleParents' : 'manDom.errMissingParent',
            }),
            severity: 'error',
            show: true,
          },
        })
      );
      return;
    }

    const [ownerUid, ownerProfile] = Object.entries(domainOwnerCandidates)[0];
    dispatch(
      updateUi({
        dialog: {
          title: intl.formatMessage({ id: 'manDom.remConfTitle' }),
          message: intl.formatMessage(
            { id: 'manDom.remConfMsg' },
            { nl: <br key={`mdnl${domain}`} />, domain: <b key={`mdb0${domain}`}>{domain}</b> }
          ),
          confirmAction: actions.REMOVE_DOMAIN,
          actionData: {
            domain,
            parentUid: ownerUid,
            currentDomains: ownerProfile.authDomains,
            company,
          },
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{intl.formatMessage({ id: 'manDom.remConfCheckboxMsg' })}</b>,
          show: true,
        },
      })
    );
  };

  const renderCompanyDomains = (company) => (
    <List className={classes.listContainer} key={`list${company}`}>
      {!isEmpty(companiesAuthDomains[company]) ? (
        [...companiesAuthDomains[company]].sort().map((domain, index) => {
          return (
            <ListItem key={`${company}${domain}${index}`} button alignItems="flex-start" className={classes.listItem}>
              <ListItemText id={domain} className={classes.itemText} primary={domain} />
              <ListItemSecondaryAction>
                <Tooltip title={intl.formatMessage({ id: 'manDom.editSett' })}>
                  <IconButton
                    component={Link}
                    to={`/company/${company}/domain/${domain}/settings`}
                    edge="start"
                    aria-label={intl.formatMessage({ id: 'edit' })}
                    style={{ color: theme.palette.info.main }}
                  >
                    <PhonelinkSetupIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                  <IconButton
                    edge="end"
                    aria-label={intl.formatMessage({ id: 'remove' })}
                    style={{ color: theme.palette.error.main }}
                    onClick={() => showRemoveDomainDialogConfirmation(company, domain)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      ) : (
        <ListItem key="emptyDomains" alignItems="flex-start" className={classes.listItem}>
          <ListItemText
            id="emptyDomains"
            className={classes.itemText}
            primary={intl.formatMessage({ id: 'manDom.noDomain' })}
          />
        </ListItem>
      )}
    </List>
  );

  const { isLoaded: areCompaniesAuthDomainsLoaded, data: companiesAuthDomains } = authDomainsData;
  return (
    <Container className={classes.container}>
      <Typography variant="h4" style={theme.text.title}>
        {intl.formatMessage({ id: 'manDom.title' })}
      </Typography>
      <Typography variant="subtitle1" style={theme.text.subtitle}>
        {intl.formatMessage({ id: 'manDom.subtitle' })}
      </Typography>
      {areCompaniesAuthDomainsLoaded ? (
        <TabContext value={selectedCompany}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              className={classes.tabContainer}
              onChange={(e, selected) => {
                localStorage.setItem('selectedCompany', selected);
                setSelectedCompany(selected);
              }}
              aria-label="Companies"
              variant="scrollable"
              scrollButtons="auto"
            >
              {companies.map((company, idx) => (
                <Tab label={company} value={company} key={`label${idx}`} />
              ))}
            </TabList>
          </Box>
          <>
            {companies.map((company, idx) => (
              <TabPanel value={company} key={`panel${idx}`}>
                {renderCompanyDomains(company)}
              </TabPanel>
            ))}
            <List>
              <ListItem>
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="add"
                  component={Link}
                  to="/addDomain"
                >
                  <AddCircleIcon style={{ marginRight: theme.spacing(1) }} />
                  {intl.formatMessage({ id: 'manDom.addDomain' })}
                </Fab>
              </ListItem>
            </List>
          </>
        </TabContext>
      ) : (
        <>
          <Skeleton variant="rect" style={{ width: theme.spacing(16), height: theme.spacing(3) }} />
          {[0, 1, 2].map((v) => (
            <Container className={clsx(classes.listItem, classes.skeletonContainer)} key={`skel${v}`}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonItemText} />
            </Container>
          ))}
        </>
      )}
    </Container>
  );
}
