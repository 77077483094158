import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { TextField, Typography, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import BadRequest from '../widgets/BadRequest';
import {
  changePassword as changePasswordAction,
  resetPassword as resetPasswordAction,
} from '../../redux/actions/userActions';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import LoadingButton from '../widgets/LoadingButton';
import { injectIntl } from 'react-intl';
import { isPilotProf } from '../../util/helpers/user';
import { isObject } from 'lodash';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.other.grey.dark,
  },
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '1000px',
  },
});

class SetPassword extends Component {
  state = {
    newPassword: '',
    confirmation: '',
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (isPasswordReset) => (e) => {
    e.preventDefault();

    const { newPassword, confirmation } = this.state;
    let actionCode = this.props.resetData && this.props.resetData.actionCode;

    // Password reset doesn't require a logged in user, therefore profile will be undefined and
    // password check for pilot profile has to be delegated to the backend
    if (!isPasswordReset) {
      if (isPilotProf(this.props.profile)) {
        if (!/^\d{6}$/.test(newPassword)) {
          this.props.updateUi({
            snackbar: {
              message: this.props.intl.formatMessage({ id: 'err.pilotPassword' }),
              severity: 'error',
              show: true,
            },
          });
          return;
        }
      }
    }

    if (newPassword && confirmation && newPassword === confirmation) {
      if (isPasswordReset) {
        this.props.resetPassword({ newPassword, actionCode });
      } else {
        this.props.changePassword({ newPassword, company: this.props.profile.company[0] });
      }
      return;
    }

    this.props.updateUi({
      snackbar: {
        message: this.props.intl.formatMessage({ id: 'err.pwNoMatch' }),
        severity: 'error',
        show: true,
      },
    });
  };

  render() {
    const { profile, classes, ui, intl, resetData } = this.props;

    const pwSetCondition = profile.pwUpdated === false;
    const pwResetCondition = isObject(resetData) && resetData.actionCode && resetData.email;

    return pwSetCondition || pwResetCondition ? (
      <>
        <Container className={classes.container}>
          <form onSubmit={this.handleSubmit(pwResetCondition)}>
            <Typography variant="h4" className={clsx(classes.margin, classes.title)}>
              {intl.formatMessage({ id: `setPw.${pwResetCondition ? 'reset' : 'set'}Title` })}
            </Typography>
            <Typography variant="body1" className={classes.margin}>
              {intl.formatMessage(
                { id: `setPw.${pwResetCondition ? 'reset' : 'set'}Subtitle` },
                { email: resetData && resetData.email }
              )}
            </Typography>
            <TextField
              fullWidth
              required
              autoComplete="new-password"
              id="newPassword"
              name="newPassword"
              type="password"
              label={intl.formatMessage({ id: 'password' })}
              value={this.state.newPassword}
              onChange={this.handleChange}
              className={classes.margin}
              inputProps={{ minLength: 6 }}
            />
            <TextField
              fullWidth
              required
              autoComplete="new-password"
              id="confirmation"
              name="confirmation"
              type="password"
              label={intl.formatMessage({ id: 'chgPw.confirmation' })}
              value={this.state.confirmation}
              onChange={this.handleChange}
              className={classes.margin}
              inputProps={{ minLength: 6 }}
            />
            <Typography variant="caption" display="block" className={classes.margin}>
              <b>* </b>
              {intl.formatMessage({ id: 'setPw.reqFields' })}
            </Typography>

            <LoadingButton
              isLoading={ui.loadingButton.isChangePasswordLoading}
              content={intl.formatMessage({ id: 'submit' })}
              type="submit"
            />
          </form>
        </Container>
      </>
    ) : (
      <BadRequest text={intl.formatMessage({ id: 'unauthorized' })} />
    );
  }
}

SetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  updateUi: PropTypes.func.isRequired,
};

export default compose(
  withFirebase,
  connect(
    ({ firebase: { auth, profile }, ui }) => ({
      auth,
      profile,
      ui,
    }),
    (dispatch, ownProps) => ({
      ...ownProps,
      changePassword: (data) => dispatch(changePasswordAction(data)),
      resetPassword: (data) => dispatch(resetPasswordAction(data)),
      updateUi: (data) => dispatch(updateUiAction(data)),
    })
  ),
  withStyles(styles),
  injectIntl
)(SetPassword);
