import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import { addUser as addUserAction } from '../../redux/actions/userActions';
import {
  groupByCompany,
  selectCompanies,
  selectCompaniesDomains,
  selectModerators,
} from '../../redux/selectors/userSelectors';
import LoadingButton from '../widgets/LoadingButton';
import LogoTooltip from './../widgets/LogoTooltip';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import fidLogo from '../../assets/images/logo/monograma_flyid.png';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  title: {
    color: theme.palette.other.grey.dark,
  },
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '800px',
  },
  hidden: {
    display: 'none',
  },
  countidIconRootClass: {
    backgroundColor: theme.palette.primary.main,
  },
  flyidIconRootClass: {
    backgroundColor: theme.palette.primary.dark,
  },
});

const isAllowedEmailCharacter = (str) => /^[a-zA-Z0-9+_.-]*$/.test(str);
const getFakeEmailDomain = (company) => `@${company?.replace(new RegExp('_', 'g'), '.')}.flyid.com`;
const replaceSpacesByDots = (str) => str?.replace(new RegExp('\\s', 'g'), '.');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

const initialUserData = {
  firstName: '',
  lastName: '',
  employeeId: '',
  permissions: [],
  authDomains: [],
  email: '',
  emailConfirmation: '',
  password: '',
  confirmation: '',
  company: '',
  parentUid: '',
  lacksInstitutionalEmail: false,
};
const pilotKey = 'pilot';
const checkerKey = 'checker';
const assistantKey = 'assistant';
const moderatorKey = 'moderator';
const permissionsRef = [pilotKey, checkerKey, assistantKey, moderatorKey];

const isArrayEmpty = (arr) => {
  return !arr || !arr.length;
};

class AddUser extends Component {
  state = {
    ...initialUserData,
    // UI Flags
    missingCompany: false,
    missingPermissions: false,
    missingDomains: false,
    showConfirmation: false,
  };

  hasPermission = (key) => this.state.permissions.includes(key);

  handleSubmit = (e) => {
    e.preventDefault();
    const { addUser } = this.props;

    const userData = {};
    Object.keys(initialUserData).forEach((key) => (userData[key] = this.state[key]));
    let missingDomains = isArrayEmpty(userData.authDomains) && !this.hasPermission(moderatorKey);
    let missingPermissions = isArrayEmpty(userData.permissions);
    let missingCompany = !Boolean(userData.company);
    if (missingDomains || missingPermissions || missingCompany) {
      this.setState({
        missingPermissions,
        missingDomains,
        missingCompany,
      });
      return;
    }

    if (this.state.lacksInstitutionalEmail) {
      // Moderator cannot lack institutional email
      if (this.hasPermission(moderatorKey)) {
        this.props.updateUi({
          snackbar: {
            message: this.props.intl.formatMessage({ id: '420' }),
            severity: 'error',
            show: true,
          },
        });
        return;
      }
      userData.email = userData.email + getFakeEmailDomain(this.state.company);
    }

    //Email confirmation match
    if (!this.state.lacksInstitutionalEmail && userData.email !== userData.emailConfirmation) {
      this.props.updateUi({
        snackbar: {
          message: this.props.intl.formatMessage({ id: 'addUsr.emailNoMatch' }),
          severity: 'error',
          show: true,
        },
      });
      return;
    }

    // 6 digit password check if pilot
    const userPerm = userData.permissions;
    const isPilotOrChecker = userPerm.includes(pilotKey) || userPerm.includes(checkerKey);
    if (userData.password) {
      if (isPilotOrChecker && !/^\d{6}$/.test(userData.password)) {
        this.props.updateUi({
          snackbar: {
            message: this.props.intl.formatMessage({ id: 'err.pilotPassword' }),
            severity: 'error',
            show: true,
          },
        });
        return;
      }
      if (userData.password !== userData.confirmation) {
        this.props.updateUi({
          snackbar: {
            message: this.props.intl.formatMessage({ id: 'err.pwNoMatch' }),
            severity: 'error',
            show: true,
          },
        });
        return;
      }
    }

    // Transform permissions
    permissionsRef.forEach((perm) => {
      userData[perm] = userData.permissions.includes(perm);
    });
    delete userData.permissions;

    // Collect parentUid if existing
    const parentUid = userData.parentUid;
    delete userData.parentUid;

    addUser(userData, userData[moderatorKey] ? undefined : parentUid);
  };

  handleCheckboxChange = (event) => {
    const { firstName, lastName } = this.state;
    const data = { [event.target.name]: event.target.checked };
    if (event.target.name === 'lacksInstitutionalEmail') {
      data.email = Boolean(event.target.checked && firstName && lastName)
        ? replaceSpacesByDots(`${firstName.toLowerCase()}.${lastName.toLowerCase()}`)
        : '';
    }
    this.setState(data);
  };

  handleChange = (e) => {
    const changes = {
      showConfirmation:
        e.target.name === 'password' ? (e.target.value.length > 0 ? true : false) : this.state.showConfirmation,
    };

    switch (e.target.name) {
      case 'company': {
        changes.parentUid = '';
        changes.authDomains = [];
        break;
      }
      case 'parentUid': {
        changes.authDomains = [];
        break;
      }
      case 'permissions': {
        if (e.target.value.includes(moderatorKey)) {
          changes.lacksInstitutionalEmail = false;
          changes.parentUid = '';
          changes.authDomains = [];
        }
        break;
      }
      case 'email': {
        if (this.state.lacksInstitutionalEmail && !isAllowedEmailCharacter(e.target.value)) {
          this.props.updateUi({
            snackbar: {
              message: this.props.intl.formatMessage({ id: 'err.invalidEmailCharacter' }),
              severity: 'error',
              show: true,
            },
          });
          return;
        }
        break;
      }
      default:
        break;
    }

    changes[e.target.name] = e.target.value;
    this.setState(changes);
  };

  render() {
    const { classes, ui, intl, companies, modProfilesData, domainsPerCompanyData } = this.props;
    const { company, parentUid } = this.state;

    // Get domains
    const { isLoaded: isDomainsPerCompanyLoaded, data: domainsByCompany } = domainsPerCompanyData;
    let companyDomains = (isDomainsPerCompanyLoaded && company && domainsByCompany[company]) || [];

    // Get moderators by company
    const { isLoaded: areModeratorProfilesLoaded } = modProfilesData;
    const moderatorsByCompany = groupByCompany(modProfilesData);

    // Select company moderators
    const canSelectModerators = areModeratorProfilesLoaded && company;
    const companyModerators = canSelectModerators ? moderatorsByCompany[company] || {} : {};

    // Filter auth domains per parent authorizations
    if (parentUid && !this.hasPermission(moderatorKey)) {
      const modProfile = companyModerators[parentUid];
      companyDomains = companyDomains.filter((domain) => modProfile.authDomains.includes(domain));
    }

    return (
      <Container className={classes.container}>
        <form onSubmit={this.handleSubmit}>
          <Typography variant="h4" className={clsx(classes.margin, classes.title)}>
            {intl.formatMessage({ id: 'addUsr.title' })}
          </Typography>
          <FormControl required fullWidth className={classes.margin} error={this.state.missingCompany}>
            <InputLabel id="company-label">{intl.formatMessage({ id: 'company' })}</InputLabel>
            <Select
              labelId="company-label"
              id="company"
              name="company"
              value={company}
              onChange={this.handleChange}
              onClose={() => {
                if (this.state.missingCompany) {
                  this.setState({
                    missingCompany: Boolean(company),
                  });
                }
              }}
              // input={<Input />}
              MenuProps={MenuProps}
              autoWidth
            >
              {companies.map((company) => (
                <MenuItem value={company} key={`comp${company}`}>
                  {company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl required fullWidth className={classes.margin} error={this.state.missingPermissions}>
            <InputLabel id="permissions-label">{intl.formatMessage({ id: 'permissions' })}</InputLabel>
            <Select
              labelId="permissions-label"
              id="permissions"
              name="permissions"
              multiple
              value={this.state.permissions}
              onChange={this.handleChange}
              onClose={() => {
                if (this.state.missingPermissions) {
                  this.setState({
                    missingPermissions: isArrayEmpty(this.state.permissions),
                  });
                }
              }}
              input={<Input />}
              renderValue={(selected) => selected.map((perm) => intl.formatMessage({ id: perm })).join(', ')}
              MenuProps={MenuProps}
              autoWidth
            >
              {permissionsRef.map((perm) => (
                <MenuItem key={perm} value={perm}>
                  <Checkbox checked={this.state.permissions.indexOf(perm) > -1} />
                  <ListItemText primary={intl.formatMessage({ id: perm })} />
                  {perm === checkerKey && (
                    <LogoTooltip
                      tooltip={intl.formatMessage({ id: 'grantsCountidAccess' })}
                      logo={cidLogo}
                      iconRootClass={classes.countidIconRootClass}
                    />
                  )}
                  {perm === pilotKey && (
                    <LogoTooltip
                      tooltip={intl.formatMessage({ id: 'grantsFlyidAccess' })}
                      logo={fidLogo}
                      iconRootClass={classes.flyidIconRootClass}
                    />
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!this.hasPermission(moderatorKey) && (
            <TextField
              className={classes.margin}
              select
              fullWidth
              required
              disabled={!canSelectModerators}
              label={intl.formatMessage({ id: 'parentMod' })}
              variant="standard"
              value={this.state.parentUid}
              onChange={this.handleChange}
              inputProps={{ name: 'parentUid', id: 'type-select' }}
            >
              {Object.entries(companyModerators).map(([uid, profile]) => (
                <MenuItem value={uid} key={uid}>
                  {`${profile.firstName} ${profile.lastName}`}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            fullWidth
            required
            id="firstName"
            name="firstName"
            type="text"
            label={intl.formatMessage({ id: 'firstName' })}
            value={this.state.firstName}
            onChange={this.handleChange}
            className={classes.margin}
            autoFocus
          />
          <TextField
            fullWidth
            required
            id="lastName"
            name="lastName"
            type="text"
            label={intl.formatMessage({ id: 'lastName' })}
            value={this.state.lastName}
            onChange={this.handleChange}
            className={classes.margin}
          />
          <TextField
            fullWidth
            required
            id="employeeId"
            name="employeeId"
            type="text"
            label={intl.formatMessage({ id: 'employeeId' })}
            value={this.state.employeeId}
            onChange={this.handleChange}
            className={classes.margin}
          />
          {!this.hasPermission(moderatorKey) && (
            <>
              <FormControl required fullWidth className={classes.margin} error={this.state.missingDomains}>
                <InputLabel id="authdomains-label">{intl.formatMessage({ id: 'authDomains' })}</InputLabel>
                <Select
                  labelId="authdomains-label"
                  id="authdomains"
                  name="authDomains"
                  multiple
                  value={this.state.authDomains}
                  onChange={this.handleChange}
                  onClose={() => {
                    if (this.state.missingDomains) {
                      this.setState({
                        missingDomains: isArrayEmpty(this.state.authDomains),
                      });
                    }
                  }}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  autoWidth
                >
                  {companyDomains.map((domain) => (
                    <MenuItem key={domain} value={domain}>
                      <Checkbox checked={this.state.authDomains.indexOf(domain) > -1} />
                      <ListItemText primary={domain} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        fullWidth
                        name="lacksInstitutionalEmail"
                        checked={this.state.lacksInstitutionalEmail}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'addUsr.lacksInstitutionalEmail' })}
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            </>
          )}

          <TextField
            fullWidth={!this.state.lacksInstitutionalEmail}
            inputProps={{ autoComplete: 'off' }}
            required
            id="email"
            name="email"
            type={this.state.lacksInstitutionalEmail ? 'text' : 'email'}
            label={intl.formatMessage({ id: 'addUsr.email' })}
            value={this.state.email}
            onChange={this.handleChange}
            className={classes.margin}
          />
          {this.state.lacksInstitutionalEmail ? (
            <TextField
              disabled={true}
              label=" "
              value={getFakeEmailDomain(this.state.company)}
              className={classes.margin}
            />
          ) : (
            <TextField
              fullWidth
              inputProps={{ autoComplete: 'off' }}
              required={!this.state.lacksInstitutionalEmail}
              id="emailConfirmation"
              name="emailConfirmation"
              type="emailConfirmation"
              label={intl.formatMessage({ id: 'addUsr.emailConfirmation' })}
              value={this.state.emailConfirmation}
              onChange={this.handleChange}
              className={classes.margin}
            />
          )}

          <TextField
            fullWidth
            inputProps={{ autoComplete: 'new-password' }}
            id="password"
            name="password"
            type="password"
            label={intl.formatMessage({ id: 'addUsr.pw' })}
            value={this.state.pin}
            onChange={this.handleChange}
            className={classes.margin}
          />
          <TextField
            fullWidth
            inputProps={{ autoComplete: 'new-password' }}
            id="confirmation"
            name="confirmation"
            type="password"
            label={intl.formatMessage({ id: 'addUsr.pwconf' })}
            value={this.state.confirmation}
            onChange={this.handleChange}
            required={this.state.showConfirmation}
            className={clsx(classes.margin, this.state.showConfirmation ? null : classes.hidden)}
          />

          <LoadingButton
            isLoading={ui.loadingButton.isAddUserLoading}
            content={intl.formatMessage({ id: 'submit' })}
            type="submit"
          />
        </form>
      </Container>
    );
  }
}

AddUser.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(
    (state) => {
      const companies = selectCompanies(state);
      return {
        ui: state.ui,
        companies,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        modProfilesData: selectModerators(state),
        domainsPerCompanyData: selectCompaniesDomains(companies, state),
      };
    },
    (dispatch, ownProps) => ({
      ...ownProps,
      addUser: (userData, parentUid) => dispatch(addUserAction(userData, parentUid)),
      updateUi: (data) => dispatch(updateUiAction(data)),
    })
  ),
  withStyles(styles),
  withTheme,
  injectIntl
)(AddUser);
