import { locale } from '../types';
import { localeToUse, pickLocale, supportedMessages } from '../../util/locale';

const savedLocale = localStorage.getItem('locale');
const initialLocale = savedLocale ? pickLocale([savedLocale]) : localeToUse;

const initialState = {
  messages: supportedMessages[initialLocale],
  locale: initialLocale,
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case locale.CHANGE_LANGUAGE:
      const lang = action.data.language;
      if (lang) {
        localStorage.setItem('locale', lang);
        const locale = pickLocale([lang]);
        return {
          messages: supportedMessages[locale],
          locale,
        };
      }
      break;

    default:
      break;
  }

  return {
    ...state,
  };
};

export default localeReducer;
