import { isObject } from 'lodash';

/** Returns the function name */
export function nameOf(f) {
  f.toString().replace(/[ |()=>]/g, '');
}

/**
 * Returns the first element of the object
 *
 * @param {Object} object object to fetch from
 * @returns the first element of the object or null
 */
export function getFirst(object) {
  return isObject(object) ? Object.values(object)[0] : null;
}

export function buildLicenseDescription(license, id, intl, cropLimit) {
  const _id = cropLimit ? id.substr(0, cropLimit) : id;
  return `${license.description} - S/N: ${_id}${cropLimit ? '...' : ''} - exp: ${intl.formatDate(
    new Date(license.expDate.seconds * 1000)
  )}`;
}

export function buildShortLicenseDescription(license, id, cropLimit = 4) {
  const _id = cropLimit ? id.substr(0, cropLimit) : id;
  return `${license.description} - ${_id}${cropLimit ? '...' : ''}`;
}

export function buildMifDescription(mif, intl) {
  let desc = mif.field;
  if (mif.useCheckField) {
    desc = desc.concat(` | ${intl.formatMessage({ id: 'checkField' })}: ${mif.checkField}`);
    if (mif.maxAttempts > 0) {
      desc = desc.concat(
        ` | ${intl.formatMessage({ id: 'dSett.repeat' }, { maxAttempts: mif.maxAttempts })}`
      );
    }
  }
  return desc;
}

/** Fallback in case navigator.clipboard is not available */
export function fallbackCopyTextToClipboard(text, callback) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    if (successful && callback) {
      callback();
    }
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text, callback) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      if (callback) callback();
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export const sortObjectByKeys = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce((o, key) => {
      o[key] = obj[key];
      return o;
    }, {});

export const sortObjectByValues = (obj) =>
  Object.entries(obj)
    .sort(([k1, v1], [k2, v2]) => v1.localeCompare(v2))
    .reduce((o, [key, value]) => {
      o[key] = value;
      return o;
    }, {});

export function getDateMembers(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth();
  mm = (mm > 9 ? mm : '0' + mm);
  let dd = date.getUTCDate();
  dd = (dd > 9 ? dd : '0' + dd);
  const epoch = date.getTime();
  return { yyyy, mm, dd, epoch };
}

export const parseDateFromTimestamp = (timestamp, offsetMinutes = 0) => {
  let ret;
  if (isTimestamp(timestamp)) ret = timestamp.toDate();
  else if (timestamp instanceof Date) ret = timestamp;
  else if (typeof timestamp === 'number' || typeof timestamp === 'string')
    // If number or string, try parsing
    ret = new Date(timestamp);
  else {
    // If everything fails, fallback to 'now' to avoid breaking.
    ret = new Date();
  }
  return offsetMinutes ? new Date(ret.getTime() + offsetMinutes * 60 * 1000) : ret;
};

export const parseEpochFromTimestamp = (timestamp) =>
  parseDateFromTimestamp(timestamp).getTime();

export const isTimestamp = (timestamp) => typeof timestamp?.toDate === 'function';
