import { Container, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import { changePassword as changePasswordAction } from '../../redux/actions/userActions';
import { isPilotProf } from '../../util/helpers/user';
import LoadingButton from '../widgets/LoadingButton';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.other.grey.dark,
  },
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '1000px',
  },
  inputContainer: {
    marginLeft: 0,
    maxWidth: '300px',
  },
});

const dataFields = {
  currentPassword: '',
  newPassword: '',
  confirmation: '',
};
class ChangePassword extends Component {
  state = {
    ...dataFields,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { currentPassword, newPassword, confirmation } = this.state;
    const { profile } = this.props;

    if (isPilotProf(profile)) {
      if (!/^\d{6}$/.test(newPassword)) {
        this.props.updateUi({
          snackbar: {
            message: this.props.intl.formatMessage({ id: 'err.pilotPassword' }),
            severity: 'error',
            show: true,
          },
        });
        return;
      }
    }

    if (currentPassword && newPassword && confirmation && newPassword === confirmation) {
      this.props.changePassword({
        currentPassword,
        newPassword,
      });
      return;
    }

    this.props.updateUi({
      snackbar: {
        message: this.props.intl.formatMessage({ id: 'chgPw.noMatch' }),
        severity: 'error',
        show: true,
      },
    });
  };

  render() {
    const { classes, ui, intl } = this.props;

    return (
      <Container className={classes.container}>
        <Typography variant="h4" className={clsx(classes.margin, classes.title)}>
          {intl.formatMessage({ id: 'chgPw.title' })}
        </Typography>
        <form onSubmit={this.handleSubmit} className={classes.inputContainer}>
          {Object.keys(dataFields).map((field) => (
            <TextField
              key={field}
              required
              fullWidth
              variant="outlined"
              id={field}
              name={field}
              type="password"
              label={intl.formatMessage({ id: `chgPw.${field}` })}
              value={this.state[camelCase(field)]}
              onChange={this.handleChange}
              className={classes.margin}
              inputProps={{ minLength: 6 }}
            />
          ))}

          <LoadingButton
            isLoading={ui.loadingButton.isChangePasswordLoading}
            content={intl.formatMessage({ id: 'submit' })}
            type="submit"
          />
        </form>
      </Container>
    );
  }
}

ChangePassword.propTypes = {
  ui: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
};

export default compose(
  withFirebase,
  connect(
    ({ firebase: { profile }, ui }) => ({ profile, ui }),
    (dispatch, ownProps) => ({
      ...ownProps,
      changePassword: (credentials) => dispatch(changePasswordAction(credentials)),
      updateUi: (data) => dispatch(updateUiAction(data)),
    })
  ),
  withStyles(styles),
  injectIntl
)(ChangePassword);
