import { Checkbox, Container, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { deburr, isObject } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addDomain } from '../../redux/actions/managementActions';
import { updateUi } from '../../redux/actions/uiActions';
import {
  groupByCompany,
  selectCompanies,
  selectCompaniesDomains,
  selectModerators,
} from '../../redux/selectors/userSelectors';
import { checkValidId } from '../../util/helpers/validation';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '600px',
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  title: {
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function AddDomain(props) {
  const [domain, setDomain] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();

  const [company, setCompany] = useState('');
  const [modUid, setModUid] = useState('');
  const [copyDomainSettings, setCopyDomainSettings] = useState(false);
  const [domainSettingsToUse, setDomainSettingsToUse] = useState('');

  const { ui, companies, domainsPerCompanyData, moderatorProfilesData } = useSelector((state) => {
    const companies = selectCompanies(state);
    return {
      ui: state.ui,
      companies,
      domainsPerCompanyData: selectCompaniesDomains(companies, state),
      moderatorProfilesData: selectModerators(state),
    };
  });

  // Get domains
  const { isLoaded: isDomainsPerCompanyLoaded, data: domainsByCompany } = domainsPerCompanyData;
  const hasDomainsCreated = isDomainsPerCompanyLoaded && company && domainsByCompany[company].length;

  // Get moderators by company
  const { isLoaded: areModeratorProfilesLoaded } = moderatorProfilesData;
  const moderatorsByCompany = groupByCompany(moderatorProfilesData);

  // Select company moderators:
  const canSelectModerators = areModeratorProfilesLoaded && company;
  const companyModerators = canSelectModerators ? moderatorsByCompany[company] || {} : {};

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!company || !modUid) return;

    const parentMod = moderatorsByCompany[company][modUid];
    const error = checkValidId(domain);
    if (isObject(error)) {
      dispatch(
        updateUi({
          snackbar: {
            message: error,
            severity: 'error',
            show: true,
          },
        })
      );

      return;
    }

    dispatch(
      addDomain({
        company,
        domain,
        copyDomain: copyDomainSettings ? domainSettingsToUse : undefined,
        currentDomains: parentMod.authDomains,
        parentUid: modUid,
      })
    );
  };

  const changeCompany = (company) => {
    setCompany(company);
    setModUid('');
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={clsx(classes.title)}>
          {intl.formatMessage({ id: 'addDomain.title' })}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.inputContainer}
        >
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              required
              label={intl.formatMessage({ id: 'company' })}
              variant="outlined"
              value={company}
              onChange={(e) => changeCompany(e.target.value)}
              inputProps={{ name: 'inputType', id: 'type-select' }}
            >
              {companies.map((company) => (
                <MenuItem value={company} key={company}>
                  {company}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              disabled={!canSelectModerators}
              fullWidth
              required
              label={intl.formatMessage({ id: 'moderator' })}
              variant="outlined"
              value={modUid}
              onChange={(e) => setModUid(e.target.value)}
              inputProps={{ name: 'inputType', id: 'type-select' }}
            >
              {Object.entries(companyModerators).map(([uid, profile]) => (
                <MenuItem value={uid} key={uid}>
                  {`${profile.firstName} ${profile.lastName}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              id="domain"
              name="domain"
              type="text"
              label={intl.formatMessage({ id: 'addDomain.input' })}
              value={domain}
              onChange={(e) => setDomain(deburr(e.target.value))}
              className={classes.input}
              autoFocus
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={copyDomainSettings}
                  onChange={(e) => setCopyDomainSettings(e.target.checked)}
                  disabled={!hasDomainsCreated}
                />
              }
              label={
                <Typography variant="body2">{intl.formatMessage({ id: 'addDomain.copyDomainSettings' })}</Typography>
              }
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            {hasDomainsCreated && copyDomainSettings ? (
              <TextField
                select
                fullWidth
                variant="outlined"
                id="domainSettingsToUse"
                name="domainSettingsToUse"
                type="text"
                value={domainSettingsToUse}
                label={intl.formatMessage({ id: 'domain' })}
                onChange={(e) => setDomainSettingsToUse(e.target.value)}
                style={{ marginBottom: theme.spacing(2) }}
              >
                {[...domainsByCompany[company]].sort().map((domain) => (
                  <MenuItem key={domain} value={domain}>
                    {domain}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </Grid>
        </Grid>

        <LoadingButton
          isLoading={ui.loadingButton.isAddDomainLoading}
          content={intl.formatMessage({ id: 'addDomain.title' })}
          type="submit"
        />
      </form>
    </Container>
  );
}
