//eslint-disable-next-line
import { cloneDeep } from 'lodash';
// eslint-disable-next-line
import { isLoaded } from 'react-redux-firebase';

export function getDividedQueries(query, [compValue, whereOp, arr]) {
  const divisions = Math.floor(arr.length / 10 + (arr.length % 10 > 0 ? 1 : 0));
  const queries = [];
  for (let i = 0; i < divisions; i++) {
    const _query = cloneDeep(query);
    const partialQuery = [compValue, whereOp, arr.slice(i * 10, (i + 1) * 10)];
    if (_query.where) {
      _query.where.push(partialQuery);
    } else {
      _query.where = [partialQuery];
    }
    _query.storeAs += i;
    queries.push(_query);
  }
  return queries;
}

export function getAllFromDividedQuery(firestore, collectionName) {
  const regex = new RegExp(`${collectionName}\\d+`);

  const queryPartials = Object.keys(firestore.data).filter((n) => regex.test(n));

  const data = {};
  let loaded = [false];
  for (const index in queryPartials) {
    const blockName = queryPartials[index];
    let currData = firestore.data[blockName];

    loaded[index] = isLoaded(currData);
    if (loaded[index]) {
      Object.assign(data, currData);
    } else if (firestore.errors.byQuery[blockName]) {
      console.error(firestore.errors.byQuery[blockName]);
    }
    // console.log(`${index}: ${dumpStr(currData, 0)}`);
  }

  // console.log(`Final: ${dumpStr(data, 0)}`);
  return { isLoaded: loaded.every((v) => !!v), data };
}
