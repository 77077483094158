import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Button, Menu, MenuItem, Radio, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
// @ts-ignore
import SortIcon from '@material-ui/icons/Sort';
import { useIntl } from 'react-intl';
// @ts-ignore
import { FieldPath } from 'firebase/firestore';

export type SortOption = {
  field: string | FieldPath;
  direction: 'asc' | 'desc';
  valueToShow: string;
};

type Props = {
  /** All filters available for selection */
  sortingOptions: SortOption[];
  /** Callback for filter changes */
  onSortChange: (sort: number | null) => void;
  /** The initially selected filters, if any */
  initialState?: number;
};

const SortSelector: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = useState<number | null>(props.initialState ?? null);
  const { sortingOptions, onSortChange } = props;
  const { $t } = useIntl();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (option: number) => {
    setSelectedSort(option);
    handleClose(); // Close menu after selection
  };

  useEffect(() => {
    onSortChange(selectedSort);
  }, [selectedSort, onSortChange]);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Tooltip
        title={selectedSort !== null ? $t({ id: 'sortBy' }) + ` (${sortingOptions[selectedSort].valueToShow})` : ''}
      >
        <Button variant="text" onClick={handleClick} startIcon={<SortIcon />}>
          {$t({ id: 'sortBy' })}
        </Button>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {sortingOptions.map((option, idx) => (
          <MenuItem key={idx} onClick={() => handleSortChange(idx)}>
            <ListItemIcon>
              <Radio checked={selectedSort === idx} />
            </ListItemIcon>
            <ListItemText primary={option.valueToShow} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SortSelector;
