import React from 'react';
import { Icon, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette, spacing }) => ({
  iconRoot: {
    padding: spacing(1.5),
    borderRadius: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: spacing(2),
    height: spacing(2),
  },
}));

const LogoTooltip = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Tooltip
      disableTouchListener
      style={{ marginLeft: theme.spacing(1), alignSelf: 'center' }}
      title={props.tooltip && <Typography variant="subtitle2">{props.tooltip}</Typography>}
    >
      <Icon classes={{ root: clsx(classes.iconRoot, props.iconRootClass) }}>
        <img className={classes.logo} src={props.logo} alt={props.tooltip} />
      </Icon>
    </Tooltip>
  );
};

export default LogoTooltip;
