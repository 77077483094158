import { Container, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, pickBy } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addLicense } from '../../redux/actions/managementActions';
import { selectAllLicenses, selectParentCandidateModerators } from '../../redux/selectors/userSelectors';
import { buildLicenseDescription } from '../../util/helpers/other';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.container(2),
    marginLeft: 0,
    maxWidth: '800px',
  },
  title: {
    color: theme.palette.other.grey.dark,
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AddLicense(props) {
  const { company, domain } = props.match.params;

  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [licenseKey, setLicenseKey] = useState('');
  const { licensesData, ui, candidateModeratorsData } = useSelector((state) => ({
    ui: state.ui,
    licensesData: selectAllLicenses(state),
    candidateModeratorsData: selectParentCandidateModerators(state, company, domain),
  }));

  const { data: candidateModerators } = candidateModeratorsData;
  const { isLoaded: licensesIsLoaded, data: licenses } = licensesData;

  let unusedLicenses = licensesIsLoaded
    ? pickBy(licenses, (license, key) => license.company === company && !license.authDomains.includes(domain))
    : {};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(licenseKey)) {
      return;
    }

    // Guarantee valid ownership
    const candidatesCount = Object.keys(candidateModerators).length;
    if (candidatesCount !== 1) {
      this.props.updateUi({
        snackbar: {
          message: intl.formatMessage({
            id: candidatesCount > 1 ? 'manDom.errMultipleParents' : 'manDom.errMissingParent',
          }),
          severity: 'error',
          show: true,
        },
      });
      return;
    }
    const parentUid = Object.keys(candidateModerators)[0];

    dispatch(
      addLicense({
        company,
        domain,
        parentUid,
        licenseKey,
      })
    );
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={clsx(classes.title)}>
          {intl.formatMessage({ id: 'addLic.title' }, { domain })}
        </Typography>

        {licensesIsLoaded ? (
          !isEmpty(unusedLicenses) ? (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="license-select-label">{intl.formatMessage({ id: 'addLic.license' })}</InputLabel>
                <Select
                  labelId="license-select-label"
                  id="license-select"
                  value={licenseKey}
                  onChange={(e) => setLicenseKey(e.target.value)}
                  className={classes.input}
                >
                  {Object.keys(unusedLicenses).map((key) => (
                    <MenuItem key={key} value={key}>
                      {buildLicenseDescription(unusedLicenses[key], key, intl)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LoadingButton
                isLoading={ui.loadingButton.isAddLicenseLoading}
                content={intl.formatMessage({ id: 'addLic.addBtn' })}
                type="submit"
              />
            </>
          ) : (
            <Typography>{intl.formatMessage({ id: 'addLic.noUnused' })}</Typography>
          )
        ) : (
          <Skeleton variant="rect" animation="wave" height={30} />
        )}
      </form>
    </Container>
  );
}
