import { picture } from '../types';

const initialState = {
  // image id <String> : <imageLoadWrapper>
};

const imageLoadWrapper = {
  isLoading: false,
  isLoaded: false,
  src: undefined,
  alt: undefined,
  loadedAt: 0, // load time ms
};

const PICTURE_GROUP_MAX_SIZE = 10;

// Reduce to half size when buffer is full, by deleting oldest pics first
function cleanPicturesGroupBuffer(pictures) {
  const oldestFirst = Object.entries(pictures).sort(([idA, picA], [idB, picB]) => picA.loadedAt - picB.loadedAt);
  while (oldestFirst.length > PICTURE_GROUP_MAX_SIZE / 2) {
    delete pictures[oldestFirst[0][0]];
    oldestFirst.shift();
  }
}

const pictureReducer = (state = initialState, action) => {
  switch (action.type) {
    case picture.ON_PICTURE_LOADING: {
      if (!action.data.id) break;

      const data = action.data;
      const retState = { ...state };

      const pictureGroup = retState;
      if (Object.keys(pictureGroup).length >= PICTURE_GROUP_MAX_SIZE) {
        cleanPicturesGroupBuffer(pictureGroup);
      }

      let imageData = { ...(retState[data.id] || imageLoadWrapper) };

      imageData.isLoading = true;

      retState[data.id] = imageData;
      return retState;
    }
    case picture.ON_PICTURE_LOADED: {
      if (!action.data.id) break;

      const data = action.data;
      const retState = { ...state };

      let imageData = { ...(retState[data.id] || imageLoadWrapper) };

      imageData.isLoading = false;
      imageData.isLoaded = true;
      imageData.src = data.src;
      imageData.alt = data.alt;
      imageData.loadedAt = new Date().getTime();

      retState[data.id] = imageData;
      return retState;
    }
    default:
      return state;
  }
};

export default pictureReducer;
