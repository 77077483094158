import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import firebaseConfig from './config';

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true });

export const getProfilePicsBucket = (firebase) =>
  firebase.app().storage(process.env.REACT_APP_PROFILE_PICS_BUCKET)

// firebase
//   .auth()
//   .setPersistence(firebase.auth.Auth.Persistence.SESSION)
//   .then(() => console.log('Auth persistence set to Session'))
//   .catch(err => console.log(`Failed setting auth persistence: ${err}`));

export default firebase;
