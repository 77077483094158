import React, { useState, useEffect } from 'react';
import { updateUi } from '../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import SetPassword from '../user/SetPassword';
import LoadingCircle from '../widgets/LoadingCircle';
import { useIntl } from 'react-intl';
import { parse } from 'qs';
import BadRequest from '../widgets/BadRequest';

export default function EmailActionsHandler({ location: { search } }) {
  const queryParams = parse(search, { ignoreQueryPrefix: true });

  const dispatch = useDispatch();
  const firebase = useFirebase();
  const intl = useIntl();

  const [component, setComponent] = useState(null);
  const [handled, setHandled] = useState(false);

  const handleAction = (queryParams) => {
    switch (queryParams.mode) {
      case 'verifyEmail':
        if (!queryParams.oobCode) {
          setComponent(<BadRequest text={intl.formatMessage({ id: 'emailHandler.invalidData' })} />);
          break;
        }

        firebase
          .auth()
          .applyActionCode(queryParams.oobCode)
          .then(() => {
            setTimeout(() => {
              firebase.auth().currentUser && firebase.auth().currentUser.reload();
              dispatch(
                updateUi({
                  snackbar: {
                    message: { msgCode: 'emailHandler.emailVerified' },
                    duration: 8000,
                    severity: 'success',
                    show: true,
                  },
                })
              );
              setComponent(<Redirect to="/" />);
            }, 5000);
          })
          .catch((err) => setComponent(<BadRequest text={err.message} />));

        break;
      case 'resetPassword':
        if (!queryParams.oobCode) {
          setComponent(<BadRequest text={intl.formatMessage({ id: 'emailHandler.invalidData' })} />);
          break;
        }

        firebase
          .auth()
          .verifyPasswordResetCode(queryParams.oobCode)
          .then((email) => setComponent(<SetPassword resetData={{ email, actionCode: queryParams.oobCode }} />))
          .catch((err) => setComponent(<BadRequest text={err.message} />));
        break;
      case 'recoverEmail':
        setComponent(<BadRequest text="Not implemented!" />);
        break;
      default:
        break;
    }
    setHandled(true);
  };

  useEffect(() => {
    if (!handled) {
      handleAction(queryParams);
    }
  });

  return component || <LoadingCircle text={intl.formatMessage({ id: 'emailHandler.validatingData' })} />;
}
