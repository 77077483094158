import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { withTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { cloneDeep, isEmpty, pickBy } from 'lodash';
import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import noImg from '../../assets/images/avatar-no-img.png';
import { actions } from '../../redux/actions/actionsHandler';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import { fetchProfileImages as fetchProfileImagesAction } from '../../redux/actions/userActions';
import {
  groupByCompany,
  selectAllDomains,
  selectAllLicenses,
  selectAllUsers,
} from '../../redux/selectors/userSelectors';
import { buildLicenseDescription, buildShortLicenseDescription } from '../../util/helpers/other';
import { isModeratorProf } from '../../util/helpers/user';
import { urlDataReader } from '../../util/workers/fileWorkerUtils';
import LoadingButton from '../widgets/LoadingButton';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import fidLogo from '../../assets/images/logo/monograma_flyid.png';
import LogoTooltip from '../widgets/LogoTooltip';

const styles = ({ container, spacing, palette }) => ({
  container: { ...container(2), marginLeft: 0 },
  innerContainer: {
    ...container(0),
    maxWidth: '850px',
    marginLeft: spacing(2),
  },
  titleContainer: {
    maxWidth: '850px',
  },
  marginBottom: {
    marginBottom: spacing(2),
  },
  button: {
    margin: spacing(2, 0, 2, 0),
  },
  subtitle: {
    margin: spacing(1),
  },
  hidden: {
    display: 'none',
  },
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
  profileImage: {
    width: spacing(20),
    height: spacing(20),
  },
  mainGrid: {
    minWidth: '650px',
  },
  sessionMargin: {
    marginTop: spacing(2),
  },
  formControl: {
    margin: spacing(1),
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  authLicensesTitle: {
    color: palette.other.grey.main,
    paddingLeft: spacing(1),
  },
  countidIconRootClass: {
    backgroundColor: palette.primary.main,
  },
  flyidIconRootClass: {
    backgroundColor: palette.primary.dark,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

const pilotKey = 'pilot';
const checkerKey = 'checker';
const assistantKey = 'assistant';
const moderatorKey = 'moderator';
const keyUserKey = 'keyUser';
const permissionsRef = [pilotKey, checkerKey, assistantKey, moderatorKey];
const keyUserPermissionsRef = permissionsRef.concat(keyUserKey);

const initialState = {
  firstName: '',
  lastName: '',
  employeeId: '',
  permissions: [],
  authDomains: [],
  authLicenses: [],
  email: '',
  profilePicFile: null,
  parent: undefined,
  // UI Flags
  missingPermissions: false,
  missingDomains: false,
  missingLicenses: false,
  showConfirmation: false,
  loadingPicture: false,
};

const readPermissionsIntoArray = (data) => {
  const permissions = [];
  const permissionsToUse = data.keyUser ? keyUserPermissionsRef : permissionsRef;
  if (!isEmpty(data)) {
    permissionsToUse.forEach((perm) => {
      const permIsGiven = data[perm];
      if (permIsGiven) {
        permissions.push(perm);
      }
    });
  }
  return permissions;
};

class UserProfile extends Component {
  state = { ...initialState, initProfile: false };

  static getDerivedStateFromProps(props, state) {
    const forcedReload = props.location.state && props.location.state.reload;
    const checkProfile = !state.initProfile || forcedReload;

    if (checkProfile) {
      if (forcedReload) {
        props.location.state = null;
      }
      let targetUid = props.match.params.uid;
      let targetProfile;

      const isOwn = isEmpty(targetUid);
      if (isOwn) {
        // Abscence of targetUid indicates its a own profile change
        targetProfile = cloneDeep(props.myProfile);
      } else {
        // if editing someone else's profile, require userProfiles
        let { isLoaded: userProfilesIsLoaded, data: userProfiles } = props.userProfilesData;
        if (userProfilesIsLoaded && userProfiles && userProfiles[targetUid]) {
          targetProfile = userProfiles[targetUid];
        } else {
          return null;
        }
      }

      return {
        ...state,
        ...targetProfile,
        permissions: readPermissionsIntoArray(targetProfile),
        originalData: cloneDeep(targetProfile),
        initProfile: true,
      };
    }
    // Null indicates no state change due to changes in properties
    return null;
  }

  showPasswordResetConfirmation = () => {
    const { firstName, lastName } = this.state.originalData;
    const intl = this.props.intl;
    this.props.updateUi({
      dialog: {
        title: intl.formatMessage({ id: 'prof.pwResetTitle' }),
        message: intl.formatMessage(
          { id: 'prof.pwResetMsg' },
          {
            name: (
              <b key="uob0">
                {firstName} {lastName}
              </b>
            ),
            nl: <br key="upnl0" />,
          }
        ),
        confirmAction: actions.RESET_PASSWORD,
        actionData: {
          uid: this.props.match.params.uid,
          company: this.state.company,
        },
        useCheckbox: false,
        show: true,
      },
    });
  };

  handleFileChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    if (file) {
      this.setState({ loadingPicture: true });
      urlDataReader(file, { onload: (result) => this.setState({ profilePicFile: result, loadingPicture: false }) });
    }
  };

  handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { firstName: origFirstName, lastName: origLastName } = this.state.originalData;
    const { permissions, authDomains } = this.state;

    const isOwn = isEmpty(this.props.match.params.uid);
    const targetUid = isOwn ? this.props.myUid : this.props.match.params.uid;

    const missingDomains = isEmpty(authDomains);
    const missingPermissions = isEmpty(permissions);
    if (!isOwn && (missingDomains || missingPermissions)) {
      this.setState({ missingDomains, missingPermissions });
      return;
    }

    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      employeeId: this.state.employeeId,
      email: this.state.email,
      profilePicFile: this.state.profilePicFile,
      authDomains,
    };

    permissionsRef.forEach((perm) => {
      data[perm] = permissions.includes(perm);
    });

    const actionData = isOwn
      ? {
          uid: targetUid,
          userData: {
            // Only allow change of picture
            profilePicFile: this.state.profilePicFile,
          },
          // A company is required to pass authentication check
          company: this.props.myProfile.company[0] ?? '',
        }
      : {
          uid: targetUid,
          userData: data,
          company: this.state.company,
        };

    // Only fill parent if non null, otherwise firestore may fill { parentUid: "undefined" }
    if (!isOwn && this.state.parent) {
      actionData.parentUid = this.state.parent;
    }

    const intl = this.props.intl;
    this.props.updateUi({
      dialog: {
        title: intl.formatMessage({ id: 'prof.profChgTitle' }),
        message: intl.formatMessage(
          { id: isOwn ? 'prof.profChgMsgOwn' : 'prof.profChgMsg' },
          {
            name: (
              <b key="upb1">
                {origFirstName} {origLastName}
              </b>
            ),
          }
        ),
        confirmAction: actions.EDIT_USER,
        actionData,
        useCheckbox: false,
        show: true,
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  maybeFetchProfileImage() {
    const { match, myUid, userProfilesData, user } = this.props;
    const isOwn = !match.params.uid;

    // Don't fetch own image, since it already happens on NavBar.
    if (!isOwn) {
      let { isLoaded: userProfilesIsLoaded, data: userProfiles } = userProfilesData;
      const targetProfile = userProfiles[match.params.uid];

      if (userProfilesIsLoaded && targetProfile) {
        const profilePicData = user.profilePics[match.params.uid];
        if (!profilePicData || (!profilePicData.isLoading && !profilePicData.isLoaded)) {
          // Only try to download profile images if properly authenticated
          if (!myUid) return;
          this.props.fetchProfileImage(match.params.uid, targetProfile.company);
        }
      }
    }
  }

  componentDidMount() {
    this.maybeFetchProfileImage();
  }

  componentDidUpdate() {
    this.maybeFetchProfileImage();
  }

  render() {
    const {
      ui,
      intl,
      user,
      theme,
      classes,
      match,
      userProfilesData,
      licensesData,
      myUid,
      myProfile,
      myAuthDomainsData,
    } = this.props;
    const { isLoaded: userProfilesIsLoaded, data: userProfiles } = userProfilesData;
    const { isLoaded: licensesAreLoaded, data: licenses } = licensesData;
    const { isLoaded: myAuthDomainsAreLoaded, data: myAuthDomainsPerCompany } = myAuthDomainsData;

    let isOwnProfile = !match.params.uid;

    let targetProfile;
    if (!isOwnProfile) {
      if (isLoaded(userProfiles) && userProfiles[match.params.uid]) {
        targetProfile = userProfiles[match.params.uid];
      }
    } else {
      targetProfile = myProfile;
    }

    const targetProfileLoaded = Boolean(targetProfile);
    const isTargetMod = isModeratorProf(targetProfile);

    // If moderator, simply won't have any parent -> undefined
    const parentModProfile = (userProfilesIsLoaded && userProfiles[this.state.parent]) ?? undefined;

    let allAuthDomains = isOwnProfile
      ? (myAuthDomainsAreLoaded && myAuthDomainsPerCompany) ?? {}
      : parentModProfile?.authDomains ?? [];

    const targetAuthDomains = isOwnProfile ? allAuthDomains : this.state.authDomains;

    const profileImageData = user.profilePics[isOwnProfile ? myUid : match.params.uid];
    const profileImage = this.state.profilePicFile || (profileImageData && profileImageData.src) || noImg;

    const waitOnSkeletonGridRow = (content, ...extraChecks) =>
      targetProfileLoaded && extraChecks.every(Boolean) ? (
        content
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rect" height={theme.spacing(5)} animation="wave" />
        </Grid>
      );

    const renderHeader = () => (
      <div className={classes.titleContainer}>
        {targetProfileLoaded ? (
          <>
            <Typography variant="h4" style={theme.text.title}>
              {isOwnProfile
                ? intl.formatMessage({ id: 'prof.myTitle' })
                : intl.formatMessage(
                    { id: 'prof.title' },
                    { name: `${targetProfile.firstName} ${targetProfile.lastName}` }
                  )}
            </Typography>
            <Typography variant="subtitle1" style={theme.text.subtitle}>
              {isOwnProfile
                ? intl.formatMessage({ id: 'prof.subtitleOwn' })
                : intl.formatMessage({ id: 'prof.subtitleMod' })}
            </Typography>
          </>
        ) : (
          <>
            <Skeleton variant="rect" height={theme.spacing(5)} animation="wave" className={classes.marginBottom} />
            <Skeleton variant="rect" height={theme.spacing(3)} animation="wave" className={classes.marginBottom} />
          </>
        )}
      </div>
    );

    const renderProfilePic = () => (
      <>
        <Grid item>
          {targetProfileLoaded && profileImageData && profileImageData.isLoaded && !this.state.loadingPicture ? (
            <Avatar
              src={profileImage}
              className={classes.profileImage}
              alt={intl.formatMessage({ id: 'altUsrProfImage' })}
            />
          ) : (
            <Skeleton variant="circle" className={clsx(classes.profileImage, classes.marginBottom)} animation="wave" />
          )}
        </Grid>
        <Grid item>
          {targetProfileLoaded ? (
            <>
              <input
                accept="image/jpeg, image/png"
                style={{ display: 'none' }}
                id="profile-image-btn"
                type="file"
                onChange={this.handleFileChange}
                disabled={isTargetMod || ui.loadingButton.isEditUserLoading}
              />
              <label htmlFor="profile-image-btn">
                <Button
                  variant="contained"
                  component="span"
                  disabled={isTargetMod || ui.loadingButton.isEditUserLoading}
                >
                  {intl.formatMessage({ id: 'prof.chgPicture' })}
                </Button>
              </label>
            </>
          ) : (
            <Skeleton variant="rect" height={theme.spacing(5)} width={theme.spacing(20)} animation="wave" />
          )}
        </Grid>
      </>
    );

    const renderCompanyAndMod = (showParent) =>
      waitOnSkeletonGridRow(
        <>
          <Grid item xs={showParent ? 6 : 12}>
            <TextField
              disabled
              required
              fullWidth
              variant="outlined"
              id="company"
              name="company"
              type="text"
              label={intl.formatMessage({ id: 'company' })}
              value={this.state.company}
              autoFocus
            />
          </Grid>
          {showParent && (
            <Grid item xs={6}>
              <TextField
                disabled
                required
                fullWidth
                variant="outlined"
                id="parentMod"
                name="parentMod"
                type="text"
                label={intl.formatMessage({ id: 'parentMod' })}
                value={`${parentModProfile.firstName} ${parentModProfile.lastName}`}
              />
            </Grid>
          )}
        </>
      );

    const renderFirstAndLastNames = () =>
      waitOnSkeletonGridRow(
        <>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              disabled={isOwnProfile || isTargetMod}
              variant="outlined"
              id="firstName"
              name="firstName"
              type="text"
              label={intl.formatMessage({ id: 'firstName' })}
              value={this.state.firstName}
              onChange={this.handleChange}
              autoFocus
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              disabled={isOwnProfile || isTargetMod}
              variant="outlined"
              id="lastName"
              name="lastName"
              type="text"
              label={intl.formatMessage({ id: 'lastName' })}
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </Grid>
        </>
      );

    const renderIdAndEmail = () =>
      waitOnSkeletonGridRow(
        <>
          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              disabled={isOwnProfile || isTargetMod}
              variant="outlined"
              id="employeeId"
              name="employeeId"
              type="text"
              label={intl.formatMessage({ id: 'employeeId' })}
              value={this.state.employeeId}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              disabled
              required
              fullWidth
              variant="outlined"
              id="email"
              name="email"
              type="email"
              label={intl.formatMessage({ id: 'email' })}
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Grid>
        </>
      );

    const renderPermissions = () =>
      waitOnSkeletonGridRow(
        <Grid item xs={12} style={{ paddingRight: !isOwnProfile ? theme.spacing(5) : undefined }}>
          <FormControl fullWidth className={classes.formControl} error={this.state.missingPermissions}>
            <InputLabel id="permissions-label">{intl.formatMessage({ id: 'permissions' })}</InputLabel>
            <Select
              labelId="permissions-label"
              id="permissions"
              name="permissions"
              multiple
              disabled={isOwnProfile || isTargetMod}
              value={this.state.permissions}
              onChange={this.handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={intl.formatMessage({ id: value })} className={classes.chip} />
                  ))}
                </div>
              )}
              onClose={() => {
                if (this.state.missingPermissions) {
                  this.setState({
                    missingPermissions: isEmpty(this.state.permissions),
                  });
                }
              }}
              MenuProps={MenuProps}
              input={<Input id="permissions-input" />}
            >
              {(isOwnProfile ? keyUserPermissionsRef : permissionsRef).map((perm) => (
                <MenuItem key={perm} value={perm} disabled={perm === moderatorKey || perm === keyUserKey}>
                  <Checkbox checked={this.state.permissions.indexOf(perm) > -1} />
                  <ListItemText primary={intl.formatMessage({ id: perm })} />
                  {perm === checkerKey && (
                    <LogoTooltip
                      tooltip={intl.formatMessage({ id: 'grantsCountidAccess' })}
                      logo={cidLogo}
                      iconRootClass={classes.countidIconRootClass}
                    />
                  )}
                  {perm === pilotKey && (
                    <LogoTooltip
                      tooltip={intl.formatMessage({ id: 'grantsFlyidAccess' })}
                      logo={fidLogo}
                      iconRootClass={classes.flyidIconRootClass}
                    />
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );

    const renderAuthDomains = () => {
      const renderDomainsByCompany = (domains, company) => (
        <Grid item xs={12} style={{ paddingRight: theme.spacing(5) }} key={Math.random()}>
          <FormControl fullWidth className={classes.formControl} error={this.state.missingDomains}>
            <InputLabel id="authdomains-label">
              {intl.formatMessage({ id: 'authDomains' })}
              {company ? `: ${company}` : ''}
            </InputLabel>
            <Select
              labelId="authDomains-label"
              id="authDomains"
              name="authDomains"
              multiple
              disabled={isOwnProfile || isTargetMod}
              value={company ? targetAuthDomains[company] : targetAuthDomains}
              onChange={this.handleChange}
              onClose={() => {
                if (this.state.missingDomains && !isOwnProfile) {
                  this.setState({
                    missingDomains: isEmpty(this.state.authDomains),
                  });
                }
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((domain) => (
                    <Chip key={`rend${domain}`} label={domain} className={classes.chip} />
                  ))}
                </div>
              )}
              input={<Input id="authdomains-input" />}
              MenuProps={MenuProps}
            >
              {domains &&
                domains.map((domain) => (
                  <MenuItem key={`men${domain}`} value={domain}>
                    <Checkbox checked={this.state.authDomains.indexOf(domain) > -1} />
                    <ListItemText primary={domain} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      );

      return waitOnSkeletonGridRow(
        <>
          {isOwnProfile
            ? Object.entries(allAuthDomains).map(([company, domains]) => renderDomainsByCompany(domains, company))
            : renderDomainsByCompany(allAuthDomains)}
        </>
      );
    };

    const renderLicenses = () => {
      const renderLicensesByCompany = (licenses, company) => (
        <Fragment key={`lics${company}`}>
          <Grid item xs={12} style={{ paddingRight: theme.spacing(5) }}>
            <Typography className={classes.authLicensesTitle} variant="caption">
              {intl.formatMessage({ id: 'authLicenses' })}
              {company ? `: ${company}` : ''}
            </Typography>
          </Grid>
          {waitOnSkeletonGridRow(
            <>
              <Grid item xs={12} style={{ paddingRight: theme.spacing(5) }}>
                <div>
                  {Object.entries(licenses).map(([licId, licData]) => (
                    <Tooltip
                      key={licId}
                      disableTouchListener
                      className={classes.tooltip}
                      title={<Typography variant="h6">{buildLicenseDescription(licData, licId, intl)}</Typography>}
                    >
                      <Chip label={buildShortLicenseDescription(licData, licId)} className={classes.chip} />
                    </Tooltip>
                  ))}
                </div>
              </Grid>
            </>,
            licensesAreLoaded,
            licenses
          )}
        </Fragment>
      );

      let authLicenses = pickBy(licenses, (v, k) => this.state.authLicenses?.includes(k));
      if (isOwnProfile) {
        authLicenses = groupByCompany(licensesData);
      }

      return isTargetMod
        ? renderLicensesByCompany(authLicenses)
        : isOwnProfile && authLicenses
        ? Object.entries(authLicenses).map(([company, licenses]) => renderLicensesByCompany(licenses, company))
        : null;
    };

    const renderButtons = () =>
      waitOnSkeletonGridRow(
        <Grid container item direction="row" xs={12} justifyContent="space-between">
          <Grid item>
            <Button
              component={Link}
              to={isOwnProfile ? 'changepw' : '#'}
              onClick={isOwnProfile ? undefined : this.showPasswordResetConfirmation}
              disabled={ui.loadingButton.isEditUserLoading}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage({ id: isOwnProfile ? 'prof.chgPw' : 'prof.rstPw' })}
            </Button>
          </Grid>
          {(isOwnProfile ? typeof this.state.profilePicFile === 'string' : !isTargetMod) ? (
            <Grid item>
              <LoadingButton
                isLoading={ui.loadingButton.isEditUserLoading}
                content={intl.formatMessage({ id: 'saveChanges' })}
                type="submit"
              />
            </Grid>
          ) : null}
        </Grid>,
        targetProfileLoaded
      );

    return (
      <Container className={classes.container}>
        {renderHeader()}
        <Container className={classes.innerContainer}>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2} className={classes.mainGrid}>
              {/* Profile picture container */}
              <Grid container item spacing={2} direction="column" xs={4} alignItems="center">
                {renderProfilePic()}
              </Grid>
              {/* Render info to the right of picture */}
              <Grid container item spacing={2} direction="row" xs={8} alignContent="space-between">
                {!isOwnProfile && renderCompanyAndMod(!isTargetMod)}
                {renderFirstAndLastNames()}
                {renderIdAndEmail()}
                {isOwnProfile && renderPermissions()}
              </Grid>
              {/* Other stuff below the pic + info structure: auth domains, licenses and permissions */}
              {!isOwnProfile && renderPermissions()}
              {/* Auth domains */}
              {renderAuthDomains()}
              {/* Licenses */}
              {renderLicenses()}

              {/* Divider */}
              <Grid item xs={12} className={classes.sessionMargin}>
                <Divider />
              </Grid>
              {/* Submit button */}
              {renderButtons()}
            </Grid>
          </form>
        </Container>
      </Container>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state.user,
      myUid: state.firebase.auth.uid,
      myProfile: state.firebase.profile,
      userProfilesData: selectAllUsers(state),
      licensesData: selectAllLicenses(state),
      myAuthDomainsData: selectAllDomains(state),
      ui: state.ui,
    }),
    (dispatch, ownProps) => ({
      ...ownProps,
      updateUi: (uiData) => dispatch(updateUiAction(uiData)),
      fetchProfileImage: (uid, company) =>
        dispatch(fetchProfileImagesAction({ profileUids: [uid], isThumb: false, company })),
    })
  ),
  withStyles(styles),
  withTheme,
  injectIntl
)(UserProfile);
