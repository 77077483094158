/**
 * Returns sorting result from comparison of two objects (a and b) using its 'orderBy' key
 */
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Functions that returns sorting result depending on order (desc or asc)
 */
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

/**
 * Sort based on cmp function, if items are equal, sort by initial index
 */
function stableSortFromComparator(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Filters rows whenever searchText matches any of its columns
 */
export function searchData(rows, columns, searchText) {
  if (searchText) {
    const upperSearch = searchText.toUpperCase();
    return rows.filter((row) =>
      columns
        .filter((column) => {
          return column.searchable === undefined ? !column.hidden : column.searchable;
        })
        .some((column) => {
          if (column.customFilterAndSearch) {
            return !!column.customFilterAndSearch(searchText, row, column);
          } else if (column.id) {
            const value = row[column.id];

            return value ? value.toString().toUpperCase().includes(upperSearch) : null;
          }
          return null;
        })
    );
  }
  return rows;
}

export const stableSort = (data, order, orderBy) => stableSortFromComparator(data, getSorting(order, orderBy));
