import { isEqual, isObject, transform } from 'lodash';
import { useEffect, useRef } from 'react';

const util = require('util');

export const dump = (obj, depth = 3) => {
  console.log(dumpStr(obj, depth));
};

export const dumpStr = (obj, depth = 3) => {
  obj = obj || null;
  return util.inspect(obj, false, depth, true);
};

export function sleepFor(sleepDuration) {
  var now = new Date().getTime();
  while (new Date().getTime() < now + sleepDuration) {
    /* do nothing */
  }
}

export function logPropDifferences(newProps, lastProps) {
  const allKeys = new Set(Object.keys(newProps)).add(Object.keys(lastProps));
  allKeys.forEach((key) => {
    const newValue = newProps[key];
    const lastValue = lastProps[key];
    if (newValue !== lastValue) {
      console.log(`Changed ${key}:`);
      console.log('New Value: ', newValue);
      console.log('Last Value: ', lastValue);
      // console.log(`Difference: ${dumpStr(difference(newValue, lastValue))}`);
    }
  });
}

export function useDebugPropChanges(newProps) {
  const lastProps = useRef();
  // Should only run when the component re-mounts
  useEffect(() => {
    console.log('Mounted');
  }, []);
  if (lastProps.current) {
    logPropDifferences(newProps, lastProps.current);
  }
  lastProps.current = newProps;
}

// eslint-disable-next-line
const difference = (obj, base) => {
  function changes(_obj, _base) {
    return transform(_obj, (result, value, key) => {
      console.log(key);
      if (!isEqual(value, _base[key])) {
        result[key] = isObject(value) && isObject(_base[key]) ? changes(value, _base[key]) : value;
      }
    });
  }
  return changes(obj, base);
};
