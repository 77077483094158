/* eslint-disable no-eval */
import { ui } from '../types';
// eslint-disable-next-line
import { parseServerResponseIntoMessage } from '../../util/helpers/server';

export const updateUi = (data) => (dispatch, getState) => {
  dispatch({ type: ui.UPDATE_UI, data });
};

export const updateUiNoReset = (data) => (dispatch, getState) => {
  dispatch({ type: ui.UPDATE_UI_NO_RESET, data });
};

export function getSnackbar(resErr) {
  const success = !(resErr instanceof Error) && resErr && String(resErr.status) === '200';

  return {
    message: parseServerResponseIntoMessage(resErr),
    duration: success ? 3000 : 5000,
    severity: success ? 'success' : 'error',
    show: true,
  };
}
