import { Checkbox, Switch, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { fetchStoragePicture } from '../../redux/actions/userActions';
import { selectCustomDomainSettings, selectDomainSessions, selectDomainSettings } from '../../redux/selectors/userSelectors';
import { getDomainSettingsPath, getSessionsPath } from '../../util/helpers/path';
import { getStoredOrDefault, storeCheckState } from '../../util/web';
import { useTableDataParser } from '../../workers/dataWorkerApi';
import EnhancedTable from './EnhancedTable/EnhancedTable';

const showInputDataStorageKey = 'sessionShowInputData';
const showBaseFieldsStorageKey = 'sessionShowBaseFields';
const mergeLabelsStorageKey = 'sessionMergeLabels';

export default function Session(props) {
  const { match } = props;
  const company = match.params.company;
  const domain = match.params.domain;
  const sessionName = match.params.session;

  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sessionData, settings, sessionsLoaded, settingsLoaded } = useSelector((state) => {
    const { isLoaded: sessionsLoaded, data: sessions } =
      selectDomainSessions(company, domain, state);
    const { isLoaded: defaultSettingsLoaded, data: defaultSettings } =
      selectDomainSettings(company, domain, state);
    const { isLoaded: customSettingsLoaded, data: customSettings } =
      selectCustomDomainSettings(company, domain, sessionName, state);

    return {
      sessionData: (sessionsLoaded && sessions[`${sessionName}`]) || null,
      sessionsLoaded,
      settings: customSettings ?? defaultSettings,
      settingsLoaded: customSettingsLoaded || defaultSettingsLoaded
    }
  });

  const queries = useMemo(() => {
    // Sessions query
    const _queries = [{
      collection: getSessionsPath(company, domain),
      storeAs: `sess${company}${domain}`,
      orderBy: ['startTime', 'desc'],
      limit:100
    }];
    if (sessionName && sessionData) {
      // Custom settings query
      _queries.push({
        collection: getDomainSettingsPath(company, domain),
        where: [
          ["createdDate", "<", sessionData.startTime]
        ],
        orderBy: ["createdDate", "desc"],
        limit: 1,
        storeAs: `${company}-sett${domain}-${sessionName}`
      })
    }
    return _queries;
  }, [domain, sessionName, sessionData, company])
  useFirestoreConnect(queries);

  let wasTask = (sessionData && sessionData.wasTask) || false;

  const [showInputData, setShowInputData] = useState(
    wasTask && getStoredOrDefault(showInputDataStorageKey, false)
  );
  const [showBaseFields, setShowBaseFields] = useState(
    getStoredOrDefault(showBaseFieldsStorageKey, false)
  );
  const [mergeLabels, setMergeLabels] = useState(getStoredOrDefault(mergeLabelsStorageKey, false));

  const { isWorking, rowsAndColumns } = useTableDataParser(
    sessionData,
    settingsLoaded ? settings : undefined,
    showInputData,
    showBaseFields,
    mergeLabels
  );

  const { rows, columns } = rowsAndColumns || {};

  const topToolbarExtraSelectors = [];
  if (wasTask) {
    topToolbarExtraSelectors.push({
      label: intl.formatMessage({ id: 'session.inputFormat' }),
      control: (
        <Checkbox
          style={{ marginEnd: theme.spacing(2) }}
          color="secondary"
          checked={showInputData}
          onChange={storeCheckState(showInputDataStorageKey, setShowInputData)}
        />
      ),
    });
  }

  topToolbarExtraSelectors.push({
    label: intl.formatMessage({ id: 'session.showBaseFields' }),
    control: (
      <Checkbox
        style={{ marginEnd: theme.spacing(2) }}
        color="secondary"
        checked={showBaseFields}
        onChange={storeCheckState(showBaseFieldsStorageKey, setShowBaseFields)}
      />
    ),
  });

  const bottomToolbarExtraSelectors = [
    {
      label: intl.formatMessage({ id: 'session.mergeLabels' }),
      control: (
        <Switch
          checked={mergeLabels}
          onChange={storeCheckState(mergeLabelsStorageKey, setMergeLabels)}
        />
      ),
    },
  ];

  const dataLoaded = settingsLoaded && sessionsLoaded && !isWorking;
  const addressField = settingsLoaded && settings.baseFields.address;

  return (
    <EnhancedTable
      tableId="session"
      dataLoaded={dataLoaded}
      data={{ rows, columns }}
      title={intl.formatMessage({ id: 'domain.title' }, { domain: match.params.domain })}
      showTitle
      showPicture
      onPictureClick={(row) => {
        dispatch(
          fetchStoragePicture(
            company,
            domain,
            row.address,
            row.checkedAt.getTime(),
            row.__pictureFilename,
            addressField,
            intl
          )
        );
      }}
      toolbarProps={{
        exportButton: true,
        exportFileName: match.params.session,
        title: intl.formatMessage({ id: 'session.title' }, { session: match.params.session }),
        extraSelectors: topToolbarExtraSelectors,
      }}
      bottomToolbarProps={{
        extraSelectors: bottomToolbarExtraSelectors,
      }}
    />
  );
}
