import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Button, CircularProgress, useTheme } from '@material-ui/core';

const classes = makeStyles((theme) => ({
  button: {
    width: theme.spacing(12),
    height: theme.spacing(5),
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

export default function LoadingButton(props) {
  const theme = useTheme();
  const { isLoading, content, type, disabled } = props;

  return (
    <Button type={type} variant="contained" color="secondary" className={classes.button} disabled={isLoading || disabled}>
      {isLoading ? <CircularProgress size={theme.spacing(4)} color="secondary" /> : content}
    </Button>
  );
}
