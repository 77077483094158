import {
  AppBar,
  Avatar,
  Backdrop,
  Button,
  CardMedia,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import LanguageIcon from '@material-ui/icons/Language';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MenuIcon from '@material-ui/icons/Menu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import 'firebase/firestore';
import { isEmpty } from 'lodash';
import { isFunction } from 'lodash';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, withFirebase } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import Viewer from 'react-viewer';
import { compose } from 'redux';
import { sprintf } from 'sprintf-js';
import noImg from '../../assets/images/avatar-no-img.png';
import imageUnavailable from '../../assets/images/img_unavailable_white.png';
import squareLogo from '../../assets/images/logo/pid_logo_primary_m.png';
import { actions, handleAction } from '../../redux/actions/actionsHandler';
import { setLanguage as setLanguageAction } from '../../redux/actions/localeActions';
import { updateUi as updateUiAction, updateUiNoReset as updateUiNoResetAction } from '../../redux/actions/uiActions';
import { fetchProfileImages as fetchProfileImagesAction } from '../../redux/actions/userActions';
import { selectAllDomains } from '../../redux/selectors/userSelectors';
import { getCompaniesPath, getDomainsPath, getSettingsPath } from '../../util/helpers/path';
import { getAllFromDividedQuery, getDividedQueries } from '../../util/helpers/redux';
import { isKeyUserProf } from '../../util/helpers/user';
import { LOCALE_READABLE, SUPPORTED_LOCALES } from '../../util/locale';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import logo from './../../assets/images/logo/logo_fly_id.png';
import { firestore } from 'firebase';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawer.width,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawer.width}px)`,
      marginLeft: theme.drawer.width,
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
  },
  toolbarContent: {
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(1),
  },
  drawerPaper: {
    width: theme.drawer.width,
  },
  main: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawer.width}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  mainContent: {
    flexGrow: 1,
  },
  logoContainer: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  logo: {
    width: 136,
    maxHeight: 60,
  },
  userBtnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: theme.spacing(1),
    width: 'auto',
    padding: 0,
  },
  userProfileContainer: {
    padding: theme.spacing(2, 4),
    height: 185,
    backgroundColor: theme.palette.primary.dark,
  },
  userProfile: {
    marginTop: theme.spacing(0.75),
  },
  avatar: {
    width: 100,
    height: 100,
  },
  greyColor: {
    color: theme.palette.other.grey.light,
  },
  squareLogo: {
    position: 'absolute',
    width: 90,
    height: 100,
    right: theme.spacing(2),
    top: theme.spacing(2),
    borderRadius: 10,
  },
  nestedItems: {
    paddingLeft: theme.spacing(4),
  },
  progressCircle: {
    margin: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  imageViewer: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    width: '70vw',
    height: '70vh',
    left: 0,
    top: 0,
  },
  languageText: { marginLeft: theme.spacing(1) },
  notLoggedMargin: { marginTop: theme.spacing(2) },
  marginBottom: { marginBottom: theme.spacing(2) },
  backdropText: { margin: theme.spacing(4) },
  alertMessage: {
    fontSize: theme.typography.fontSize * 1.2,
  },
});

const ROUTE_KEYS = {
  PROFILE: 'PROFILE',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_DOMAINS: 'MANAGE_DOMAINS',
  MANAGE_KEYS: 'MANAGE_KEYS',
  LOGOUT: 'LOGOUT',
};
const routes = {
  [ROUTE_KEYS.PROFILE]: { route: 'profile', icon: <AccountCircleIcon /> },
  [ROUTE_KEYS.MANAGE_USERS]: { route: 'manageusers', icon: <GroupIcon /> },
  [ROUTE_KEYS.MANAGE_DOMAINS]: { route: 'managedomains', icon: <ListAltIcon /> },
  [ROUTE_KEYS.MANAGE_KEYS]: { route: 'managekeys', icon: <VpnKeyIcon /> },
  [ROUTE_KEYS.LOGOUT]: { route: 'logout', icon: <MeetingRoomIcon /> },
};

function handleIntlMessage(widget, intl) {
  if (widget.message) {
    if (widget.message.msgCode) {
      // This is in a Message format, there we must try to get its translation
      return sprintf(
        intl.formatMessage({ id: widget.message.msgCode, defaultMessage: widget.message.msg }),
        ...(Array.isArray(widget.message.args) ? widget.message.args : [])
      );
    } else if (isString(widget.message)) {
      // This is probably a front-end message
      return widget.message;
    } else if (isString(widget.message && widget.message.msg)) {
      return widget.message.msg;
    }
  }
  return null;
}

class NavBar extends Component {
  state = {
    userOpen: false,
    dashboardOpen: false,
    mobileOpen: false,
    anchorEl: null,
  };

  showLogoutDialogConfirmation = (handleDrawer) => {
    this.props.updateUi({
      dialog: {
        title: this.props.intl.formatMessage({ id: 'nav.logoutTitle' }),
        message: this.props.intl.formatMessage({ id: 'nav.logoutMsg' }),
        confirmAction: actions.LOGOUT,
        actionData: {},
        show: true,
      },
    });

    if (handleDrawer) {
      this.handleDrawerToggle();
    }
  };

  handleUserClick = () => {
    this.setState((prevState) => ({
      userOpen: !prevState.userOpen,
    }));
  };

  handleDashboardClick = () => {
    this.setState((prevState) => ({
      dashboardOpen: !prevState.dashboardOpen,
    }));
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  handleLanguageMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handleLanguageMenuClose = (e, lang) => {
    this.setState({
      anchorEl: null,
    });

    if (lang) {
      this.props.setLanguage(lang);
    }
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const { updateUi } = this.props;
    updateUi(null);
  };

  handleDialogClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (!isEmpty(this.props.ui.dialog.cancelAction)) {
      this.props.dispatchAction({
        type: this.props.ui.dialog.cancelAction,
        data: this.props.ui.dialog.actionData,
      });
    } else {
      this.props.updateUi(null);
    }
  };

  handleDialogConfirmation = () => {
    if (isFunction(this.props.ui.dialog.confirmAction)) {
      this.props.ui.dialog.confirmAction();
    } else {
      this.props.dispatchAction(this.props.ui.dialog.confirmAction, this.props.ui.dialog.actionData);
    }
  };

  maybeFetchProfileImage() {
    const { auth, fetchProfileImage } = this.props;
    // Fetch own profile picture
    const profileImage = this.props.user.profilePics[auth.uid];
    if (!profileImage || (!profileImage.isLoading && !profileImage.isLoaded)) {
      // Only try to download profile images if properly authenticated
      if (!isLoaded(auth) || !auth.uid) {
        return;
      }
      fetchProfileImage(auth.uid, '__key_users');
    }
  }

  componentDidMount() {
    this.maybeFetchProfileImage();
  }

  componentDidUpdate() {
    this.maybeFetchProfileImage();
  }

  getImages() {
    const { selectedId } = this.props.ui.imageViewer;
    const pictures = this.props.picture;

    if (pictures && pictures[selectedId]) {
      const selectedPicture = pictures[selectedId];
      if (selectedPicture.isLoaded && !!selectedPicture.src) {
        // Image is loaded, show it
        return [{ src: selectedPicture.src, alt: selectedPicture.alt }];
      }
    }
    // Image data is missing, show unavailable
    return [{ src: imageUnavailable, alt: this.props.intl.formatMessage({ id: 'img.unavailable' }) }];
  }

  render() {
    const { container, children, auth, profile, classes, ui, intl, locale, user } = this.props;
    const profileImage = user.profilePics[auth.uid];
    const companies = isLoaded(profile) ? profile.company : [];

    const { isLoaded: companiesDataIsLoaded, data: loadedCompaniesData } = this.props.companiesData;
    const companiesData = companiesDataIsLoaded ? loadedCompaniesData : null;

    const isSignedKeyUser = auth && auth.uid && profile && profile.keyUser;

    const getDrawer = () => (
      <>
        <Container className={classes.userProfileContainer}>
          {profileImage?.isLoading ? (
            <Skeleton variant="circle" className={clsx(classes.greyColor, classes.avatar)} animation="wave" />
          ) : (
            <Avatar
              alt={intl.formatMessage({ id: 'altUsrProfImage' })}
              src={auth.uid ? profileImage?.src ?? noImg : noImg}
              className={classes.avatar}
            />
          )}
          {isLoaded(profile) ? (
            <>
              {isSignedKeyUser ? (
                <>
                  <Typography color="secondary" className={classes.userProfile} noWrap>
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <Typography className={clsx(classes.greyColor, classes.userProfile)} noWrap>
                    {auth.email}
                  </Typography>
                </>
              ) : (
                <Typography color="secondary" className={clsx(classes.userProfile, classes.notLoggedMargin)}>
                  {intl.formatMessage({ id: 'nav.notSigned' })}
                </Typography>
              )}
              <CardMedia component="img" src={squareLogo} className={classes.squareLogo} />
            </>
          ) : (
            <>
              <Skeleton type="rect" className={clsx(classes.greyColor, classes.userProfile)} animation="wave" />
              <Skeleton type="rect" className={clsx(classes.greyColor, classes.userProfile)} animation="wave" />
              <Skeleton type="rect" className={clsx(classes.greyColor, classes.squareLogo)} animation="wave" />
            </>
          )}
        </Container>

        <List>
          <ListItem button id="user" onClick={this.handleUserClick}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: 'user' })} />
            {this.state.userOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.userOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {isSignedKeyUser ? (
                Object.entries(routes).map(([routeKey, routeData]) => {
                  let icon = routeData.icon;

                  let isLogout = routeKey === ROUTE_KEYS.LOGOUT;
                  let isProfile = routeKey === ROUTE_KEYS.PROFILE;
                  let action = isLogout ? () => this.showLogoutDialogConfirmation(true) : this.handleDrawerToggle;

                  return (
                    <ConditionalWrapper
                      condition={isLogout}
                      wrapper={(children) => <Hidden mdUp>{children}</Hidden>}
                      key={`profileItems${routeKey}`}
                    >
                      {/* Profile and logout are always loaded, management pages require moderator permission */}
                      {isProfile || isLogout || isKeyUserProf(profile) ? (
                        <ListItem
                          button
                          component={isLogout ? 'a' : Link}
                          to={{
                            pathname: `/${routeData.route}`,
                            state: isProfile ? { reload: true } : null,
                          }}
                          onClick={action}
                          color="inherit"
                          className={classes.nestedItems}
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={intl.formatMessage({ id: `nav.${routeData.route}` })} />
                        </ListItem>
                      ) : null}
                    </ConditionalWrapper>
                  );
                })
              ) : (
                <ListItem
                  button
                  key={0}
                  component={Link}
                  to="/login"
                  className={classes.nestedItems}
                  onClick={this.handleDrawerToggle}
                >
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: 'login' })} />
                </ListItem>
              )}
              <ListItem
                button
                key={1}
                component="a"
                rel="noopener noreferrer"
                target="_blank"
                href="https://panoramaid.atlassian.net/servicedesk/customer/portals"
                className={classes.nestedItems}
                onClick={this.handleDrawerToggle}
              >
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'nav.help' })} />
              </ListItem>
            </List>
          </Collapse>
        </List>

        {isSignedKeyUser ? (
          <>
            <List>
              <ListItem button id="dashboardOpen" onClick={this.handleDashboardClick} name="dashboardOpen">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboards" />
                {this.state.dashboardOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.dashboardOpen} timeout="auto" unmountOnExit>
                {companies && companiesData ? (
                  [...companies].sort().map((company) => {
                    return (
                      <ListItem
                        button
                        key={company}
                        component={Link}
                        to={`/company/${company}`}
                        color="inherit"
                        className={classes.nestedItems}
                        onClick={this.handleDrawerToggle}
                      >
                        <ListItemText primary={companiesData[company]?.exhibitionName ?? company} />
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <CircularProgress style={{ margin: 'auto' }} />
                  </ListItem>
                )}
              </Collapse>
            </List>
          </>
        ) : null}
      </>
    );

    const getToolbar = () => (
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className={classes.grow} />
        <MenuItem component={Link} to="/" className={classes.logoContainer}>
          <CardMedia
            component="img"
            alt={intl.formatMessage({ id: 'nav.companyLogo' })}
            className={classes.logo}
            image={logo}
            title="Panorama.id - Fly.id"
          />
        </MenuItem>
        <div className={classes.grow} />

        <div>
          <IconButton
            aria-label="Language change"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={this.handleLanguageMenu}
            color="inherit"
          >
            <LanguageIcon />
            <Typography variant="body2" className={classes.languageText}>
              {locale.toUpperCase()}
            </Typography>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            open={Boolean(this.state.anchorEl)}
            onClose={(e) => this.handleLanguageMenuClose(e, null)}
          >
            {SUPPORTED_LOCALES.map((lang) => (
              <MenuItem key={lang} onClick={(e) => this.handleLanguageMenuClose(e, lang)}>
                {LOCALE_READABLE[lang]}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Hidden smDown>
          {isSignedKeyUser ? (
            <MenuItem color="inherit" onClick={this.showLogoutDialogConfirmation} key={`userBtns-logout`}>
              {intl.formatMessage({ id: 'logout' })}
            </MenuItem>
          ) : (
            <MenuItem component={Link} to="/login" color="inherit" key={`userBtns-login`}>
              {intl.formatMessage({ id: 'login' })}
            </MenuItem>
          )}
        </Hidden>
      </Toolbar>
    );

    const getNav = () => (
      <>
        <Hidden mdUp>
          <Drawer
            container={container}
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {getDrawer()}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {getDrawer()}
          </Drawer>
        </Hidden>
      </>
    );

    const getGlobalFeedback = () => (
      <>
        <Snackbar open={ui.snackbar.show} autoHideDuration={ui.snackbar.duration} onClose={this.handleSnackbarClose}>
          <Alert
            elevation={6}
            variant="filled"
            severity={ui.snackbar.severity}
            onClose={this.handleSnackbarClose}
            classes={{
              message: classes.alertMessage,
            }}
          >
            {handleIntlMessage(ui.snackbar, intl)}
          </Alert>
        </Snackbar>

        <Dialog open={ui.dialog.show} onClose={this.handleDialogClose} aria-labelledby="alert-dialog-title">
          <DialogTitle id="alert-dialog-title">{ui.dialog.title}</DialogTitle>
          <DialogContent>
            <div className={classes.marginBottom}>{ui.dialog.message}</div>
            {ui.dialog.useCheckbox ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ui.dialog.checkboxState}
                    onChange={(e) => this.props.updateUiNoReset({ dialog: { checkboxState: e.target.checked } })}
                  />
                }
                label={<Typography variant="body2">{ui.dialog.checkboxMessage}</Typography>}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary" autoFocus>
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              onClick={this.handleDialogConfirmation}
              color="primary"
              disabled={ui.dialog.useCheckbox && !ui.dialog.checkboxState}
            >
              {intl.formatMessage({ id: 'confirm' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop className={classes.backdrop} open={ui.backdrop.show}>
          <CircularProgress color="inherit" size={60} thickness={4} />
          <Typography variant="h5" className={classes.backdropText}>
            {handleIntlMessage(ui.backdrop, intl)}
          </Typography>
        </Backdrop>

        <Viewer
          className={classes.imageViewer}
          visible={ui.imageViewer.show}
          onClose={() => this.props.updateUi({ imageViewer: { show: false } })}
          images={this.getImages()}
          zoomSpeed={0.1}
          noNavbar
        />
      </>
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          {getToolbar()}
        </AppBar>

        <nav className={classes.drawer} aria-label="drawer-menu">
          {getNav()}
        </nav>

        <main className={classes.main}>
          <div className={classes.toolbarContent} />
          <div className={classes.mainContent}>{children}</div>
        </main>

        {getGlobalFeedback()}
      </div>
    );
  }
}

NavBar.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  updateUi: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
};

export default compose(
  connect(
    (s) => ({
      auth: s.firebase.auth,
      profile: s.firebase.profile,
      ui: s.ui,
      picture: s.picture,
      locale: s.locale.locale,
      user: s.user,
      userProfiles: s.firestore.data.userProfiles,
      authDomainsByCompanyData: selectAllDomains(s),
      companiesData: getAllFromDividedQuery(s.firestore, 'companiesData'),
    }),
    (dispatch, ownProps) => ({
      ...ownProps,
      updateUi: (uiData) => dispatch(updateUiAction(uiData)),
      updateUiNoReset: (uiData) => dispatch(updateUiNoResetAction(uiData)),
      fetchProfileImage: (uid, company) =>
        dispatch(fetchProfileImagesAction({ profileUids: [uid], company, isThumb: false })),
      setLanguage: (lang) => dispatch(setLanguageAction(lang)),
      dispatchAction: (action, data) => handleAction(dispatch, action, data),
    })
  ),
  firestoreConnect((props) => {
    const { profile, auth, authDomainsByCompanyData } = props;
    const queries = [];

    // Auth licenses, users, api keys and domains for a key user
    // are all the ones under authorized companies
    if (isLoaded(profile, auth) && auth && auth.uid && isKeyUserProf(profile)) {
      const authCompanies = profile.company;
      if (Array.isArray(authCompanies)) {
        const whereConditions = ['company', 'in', authCompanies];

        // Licenses
        queries.push(
          ...getDividedQueries(
            {
              collection: '/ourLicenses',
              storeAs: `licenses`,
            },
            whereConditions
          )
        );

        // Users
        queries.push(
          ...getDividedQueries(
            {
              collection: '/users',
              storeAs: 'userProfiles',
            },
            whereConditions
          )
        );

        // ApiKeys
        queries.push(
          ...getDividedQueries(
            {
              collection: '/APIKeys',
              storeAs: `APIKeys`,
            },
            whereConditions
          )
        );

        // Companies
        queries.push(
          ...getDividedQueries(
            {
              collection: getCompaniesPath(),
              storeAs: 'companiesData',
            },
            [firestore.FieldPath.documentId(), 'in', authCompanies]
          )
        );

        // Domains
        authCompanies.forEach((company) => {
          queries.push({
            collection: getDomainsPath(company),
            storeAs: `${company}-authDomains`,
          });

          // Domains Settings
          const { isLoaded: authDomainsLoaded, data: authDomainsByCompany } = authDomainsByCompanyData;
          if (authDomainsLoaded) {
            authDomainsByCompany[company].forEach((domainName) => {
              queries.push({
                collection: getSettingsPath(company, domainName),
                storeAs: `${company}-sett${domainName}`,
                orderBy: ['createdDate', 'desc'],
                limit: 1,
              });
            });
          }
        });
      }
    }
    return queries;
  }),
  withStyles(styles),
  withFirebase,
  injectIntl
)(NavBar);
