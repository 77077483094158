import { ui } from '../types';

const uiElements = ['snackbar', 'dialog', 'backdrop', 'loadingButton', 'imageViewer'];

const initialSnackbarState = {
  message: null,
  duration: 3000,
  severity: 'error',
  show: false,
};

const initialDialogState = {
  title: '',
  message: '',
  checkboxMessage: '',
  confirmAction: '',
  cancelAction: '',
  actionData: null,
  useCheckbox: false,
  checkboxState: false,
  show: false,
};

const initialBackdropState = {
  message: null,
  show: false,
};

const initialLoadingButtonState = {
  isLoginLoading: false,
  isSetPasswordLoading: false,
  isForgetPasswordLoading: false,
  isChangePasswordLoading: false,
  isAddUserLoading: false,
  isEditUserLoading: false,
  isAddDomainLoading: false,
  isAddLicenseLoading: false,
  isEditDomainLoading: false,
  isBarcodePatternLoading: false,
  isCreateTaskLoading: false,
  isCreateApiKeyLoading: false,
  isEditApiKeyAuthDomainsLoading: false,
};

const initialImageViewerState = {
  show: false,
  selectedId: undefined,
};

const getInitialStateCopy = () => ({
  snackbar: { ...initialSnackbarState },
  dialog: { ...initialDialogState },
  backdrop: { ...initialBackdropState },
  loadingButton: { ...initialLoadingButtonState },
  imageViewer: { ...initialImageViewerState },
});

const uiReducer = (state = getInitialStateCopy(), action) => {
  switch (action.type) {
    case ui.UPDATE_UI: {
      if (action.data != null) {
        const retState = getInitialStateCopy();

        uiElements.forEach((type) => {
          if (action.data[type]) {
            Object.assign(retState[type], action.data[type]);
          }
        });

        return retState;
      } else {
        const retState = { ...state };

        retState.snackbar.show = false;
        retState.dialog.show = false;
        retState.backdrop.show = false;
        retState.loadingButton = { ...initialLoadingButtonState };
        retState.imageViewer.show = false;

        return retState;
      }
    }
    case ui.UPDATE_UI_NO_RESET: {
      if (action.data != null) {
        const retState = {
          ...state,
        };

        uiElements.forEach((type) => {
          if (action.data[type]) {
            Object.assign(retState[type], action.data[type]);
          }
        });

        return retState;
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export default uiReducer;
