import { Card, CardActionArea, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCompanyDomains } from '../../redux/selectors/userSelectors';
import { Link } from 'react-router-dom';
import BadRequest from '../widgets/BadRequest';
import { getAllFromDividedQuery } from '../../util/helpers/redux';

const useStyles = makeStyles(({ container, spacing, palette }) => ({
  container: {
    ...container(2),
    marginTop: spacing(2),
    marginLeft: 0,
    maxWidth: '900px',
  },
  title: {
    color: palette.other.grey.dark,
    marginBottom: spacing(2),
  },
}));

export default function Company(props) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const match = props.match;
  const company = match.params.company;

  const { authDomainsData, companiesData } = useSelector((state) => ({
    ui: state.ui,
    profile: state.firebase.profile,
    authDomainsData: selectCompanyDomains(company, state),
    companiesData: getAllFromDividedQuery(state.firestore, "companiesData")
  }));

  const { isLoaded: companiesDataIsLoaded, data: loadedCompaniesData } = companiesData;
  const companyData = companiesDataIsLoaded ? loadedCompaniesData?.[company] : null;

  const { isLoaded: isAuthDomainsLoaded, data: authDomains } = authDomainsData;
  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={clsx(classes.title)}>
        {intl.formatMessage({ id: 'company.title' }, { company: companyData?.exhibitionName ?? company })}
      </Typography>
      <Typography variant="subtitle1" style={theme.text.subtitle}>
        {intl.formatMessage({ id: 'company.subtitle' })}
      </Typography>

      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        {isAuthDomainsLoaded ? (
          authDomains && authDomains.length ? (
            authDomains.map((domain) => (
              <Grid item xs="auto" key={domain}>
                <Card>
                  <CardActionArea component={Link} to={`/company/${company}/domains/${domain}`}>
                    <CardContent>
                      <Typography>{domain}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs="auto">
              <BadRequest text={intl.formatMessage({ id: 'company.noDomains' })} />
            </Grid>
          )
        ) : (
          [...new Array(3).keys()].map((idx) => (
            <Grid item xs="auto" key={`domainSkel${idx}`}>
              <Skeleton
                type="rect"
                animation="wave"
                style={{ width: theme.spacing(10), height: theme.spacing(8) }}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}
