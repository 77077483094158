import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  lighten,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { withStyles, withTheme } from '@material-ui/styles';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUi as updateUiAction } from '../../redux/actions/uiActions';
import LoadingButton from '../widgets/LoadingButton';
// eslint-disable-next-line
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { cloneDeep, isEmpty } from 'lodash';
import { injectIntl } from 'react-intl';

const myWidth = 900;

const styles = ({ spacing, palette }) => ({
  root: { marginLeft: 0 },
  inputContainer: {
    marginLeft: spacing(2),
    width: myWidth,
  },
  labelInputContainer: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    width: myWidth,
  },
  title: {
    color: palette.other.grey.dark,
    marginBottom: spacing(2),
  },
  input: {
    marginBottom: spacing(2),
  },
  tooltip: {
    alignSelf: 'center',
  },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%',
  },
  extendedButtonIcon: {
    marginRight: spacing(1),
  },
  header: {
    color: palette.other.grey.dark,
  },
  fieldModifierTop: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '15px 15px 0 0',
    margin: 0,
  },
  fieldItemLight: {
    backgroundColor: lighten(palette.primary.dark, 0.96),
  },
  fieldItemDark: {
    backgroundColor: lighten(palette.primary.dark, 0.93),
  },
  fieldModifier: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
  },
  fieldModifierBottom: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '0 0 15px 15px',
  },
  testRegexError: {
    marginLeft: spacing(2),
    color: palette.error.main,
  },
  testRegexButon: {
    paddingLeft: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: spacing(3),
    marginBottom: spacing(2),
    width: myWidth,
  },
});

const initialInputState = {
  // Current field-to-be-added values
  type: 0,
  name: '',
  limiterType: 0,
  range: '',
  separator: '',
  prefix: '',
  postfix: '',
  checkField: '',
  useCheckField: false,
  // errors
  nameError: null,
  rangeError: null,
  posError: null,
};

const initialState = {
  fields: [],
  currentRegex: '',
  ignoreRemaining: false,
  isRequired: false,
  pos: 0,
  dialogOpen: false,
  // Check regex
  regexResult: {},
  regexError: null,
  regexTest: '',
  ...initialInputState,
};

// If these matches, means data contains disallowed characters
// const nameInvalidCharsRegexCheck = /[^a-zA-Z0-9_]+/;
const prepostfixInvalidCharsRegexCheck = /[^a-zA-Z0-9_@]+/;
// Filters special regex characters
const specialCharsRegex = /[-/\\^$*+?.()|[\]{}]/g;
const specialCharsRegexIntentional = /_\\([-/\\^$*+?.()|[\]{}])/g;
const rangeRegex = /^(-1)$|^(\d)+$|^(\d+),(\d+)$/;

const fieldTypes = ['data', 'constant'];
const limiterTypes = ['range', 'separator'];

const regexEscape = (s) => {
  return s.replace(specialCharsRegex, '\\$&');
};

const regexAllowIntentional = (s) => {
  return s.replace(specialCharsRegexIntentional, '$1');
};

const escapeRegexAllowingIntentional = (s) => regexAllowIntentional(regexEscape(s));

const getBarcodePatternField = (barcodePatterns, index, field) => {
  const barcodePattern = barcodePatterns[index];
  return !isEmpty(barcodePattern) ? barcodePattern[field] : null;
};

const getBarcodePatternDataFields = (barcodePatterns, index) => {
  const retData = cloneDeep(getBarcodePatternField(barcodePatterns, index, 'dataFields')) || [];

  retData.forEach((field) => {
    field.type = fieldTypes.indexOf(field.type);
    field.limiterType = limiterTypes.indexOf(field.limiterType);
    if (field.limiterType === 0) {
      field.range = field.limiter;
      field.separator = '';
    } else {
      field.range = '';
      field.separator = field.limiter;
    }
  });
  return retData;
};

class EditBarcodePattern extends Component {
  state = {
    ...initialState,
    index: -1,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.index !== props.index) {
      const { barcodePatterns, index } = props;
      if (barcodePatterns && index > -1) {
        const dataFields = getBarcodePatternDataFields(barcodePatterns, index);

        return {
          fields: dataFields,
          currentRegex: getBarcodePatternField(barcodePatterns, index, 'regex') || '',
          ignoreRemaining: getBarcodePatternField(barcodePatterns, index, 'ignoreRemaining') || false,
          isRequired: getBarcodePatternField(barcodePatterns, index, 'isRequired') || false,
          pos: dataFields.length,
          index,
        };
      }
    }
    // Null indicates no change in state due to changes in properties
    return null;
  }

  handleChange = (e) => {
    const errorField = /(pos|range|separator|name)/g.exec(e.target.name);
    let nextState = {};

    if (errorField) {
      nextState[errorField[1].concat('Error')] = '';
    }

    switch (e.target.name) {
      case 'type': {
        nextState = {
          ...nextState,
          ...initialInputState,
          name: this.state.name,
        };
        break;
      }
      case 'prefix':
      case 'postfix':
        if (prepostfixInvalidCharsRegexCheck.test(e.target.value) && !specialCharsRegex.test(e.target.value)) {
          return;
        }
        break;
      default:
        break;
    }

    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  handleCheckboxChange = (name) => (event) => {
    // rebuild regex based on type of checkbox change
    const checked = event.target.checked;
    const currentRegex = name === 'ignoreRemaining' ? this.buildRegex(null, checked) : this.state.currentRegex;
    this.setState(
      {
        [name]: checked,
        currentRegex,
      },
      () => this.handleChangeBarcodePattern({ regex: currentRegex, [name]: checked })
    );
  };

  handleRemoveField = (index) => {
    const newFields = this.state.fields.filter((val, idx) => idx !== index);
    const newRegex = this.buildRegex(newFields);

    this.setState(
      {
        ...initialState,
        fields: newFields,
        currentRegex: newRegex,
        pos: newFields.length,
      },
      () => this.handleChangeBarcodePattern({ ...initialState, regex: newRegex, fields: newFields })
    );
  };

  handleAddField = () => {
    const { pos, type, name, limiterType, range, separator, prefix, postfix, checkField, useCheckField, fields } =
      this.state;
    const intl = this.props.intl;
    const mandatory = intl.formatMessage({ id: 'ebp.mandatoryField' });

    // Check fields validity
    if (!name || !name.length) {
      this.setState({ nameError: mandatory });
      return;
    } else if (type === 0) {
      const match = specialCharsRegex.exec(name);
      if (match) {
        this.setState({ nameError: intl.formatMessage({ id: 'ebp.charNotAllowed' }, { char: match[0] }) });
        return;
      }
    }

    if (pos < 0 || pos > fields.length) {
      this.setState({ posError: intl.formatMessage({ id: 'ebp.invalidPos' }) });
      return;
    }

    const data = { type, name, limiterType, range, separator, prefix, postfix, checkField, useCheckField };
    // Check all required input fields in data type
    if (type === 0) {
      // data
      if (limiterType === 0) {
        // range
        if (isEmpty(range)) {
          this.setState({ rangeError: mandatory });
          return;
        }

        let match = rangeRegex.exec(range);
        if (match) {
          if (match[3] && match[4]) {
            if (parseInt(match[3], 10) >= parseInt(match[4], 10)) {
              this.setState({ rangeError: intl.formatMessage({ id: 'ebp.rangeBet' }) });
              return;
            }
          }
        } else {
          this.setState({ rangeError: intl.formatMessage({ id: 'ebp.invalidRange' }) });
          return;
        }
      } // separator
      else if (isEmpty(separator)) {
        this.setState({ separatorError: mandatory });
        return;
      }

      // Check for repeated items if is data field
      for (var i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (field.name === name) {
          this.setState({ dialogOpen: true });
          return;
        }
      }

      //Use name as checkfield if checkfield is left empty
      if (useCheckField && isEmpty(checkField)) {
        data.checkField = name;
      }
    }

    let newFields = [...fields];
    newFields.splice(pos, 0, data);

    const newRegex = this.buildRegex(newFields);
    this.setState(
      {
        ...initialState,
        fields: newFields,
        currentRegex: newRegex,
        pos: newFields.length,
      },
      () => this.handleChangeBarcodePattern({ ...initialState, regex: newRegex, fields: newFields })
    );
  };

  buildRegex = (fields, ignoreRemaining = false) => {
    if (fields === null) {
      fields = this.state.fields;
    }

    if (fields && fields.length) {
      let regexData = ['^'];
      fields.forEach((field) => {
        const escPrefix = escapeRegexAllowingIntentional(field.prefix);
        const escPostfix = escapeRegexAllowingIntentional(field.postfix);
        const escSeparator = escapeRegexAllowingIntentional(field.separator);

        switch (field.type) {
          case 0: // Field
            if (field.limiterType === 0) {
              // Range
              let match = rangeRegex.exec(field.range);
              if (match) {
                if (match[1]) {
                  regexData.push(`(${escPrefix}.*${escPostfix})`);
                } else {
                  regexData.push(`(${escPrefix}.{${field.range}}${escPostfix})`);
                }
              }
            } else {
              // Limiter
              regexData.push(`(${escPrefix}[^${field.postfix}${field.separator}]*)${escSeparator}`);
            }
            break;
          case 1: // Constant
            regexData.push(escapeRegexAllowingIntentional(field.name));
            break;
          default:
            break;
        }
      });

      regexData.push(ignoreRemaining ? '.*$' : '$');
      return regexData.join('');
    }
    return null;
  };

  buildDatabaseFields = (data) => {
    const barcodePattern = {
      regex: data.regex || this.state.regex,
      ignoreRemaining: data.ignoreRemaining !== undefined ? data.ignoreRemaining : this.state.ignoreRemaining,
      isRequired: data.isRequired !== undefined ? data.isRequired : this.state.isRequired,
    };

    const fields = data.fields !== undefined ? data.fields : this.state.fields;

    const dataFields = [];
    fields.forEach((field) => {
      dataFields.push({
        name: field.name,
        type: fieldTypes[field.type],
        prefix: field.prefix,
        postfix: field.postfix,
        limiterType: limiterTypes[field.limiterType],
        limiter: field.limiterType === 0 ? field.range : field.separator,
        checkField: field.checkField,
        useCheckField: field.useCheckField,
      });
    });

    barcodePattern.dataFields = dataFields;

    return barcodePattern;
  };

  testRegex = () => {
    const intl = this.props.intl;
    const regex = new RegExp(this.state.currentRegex);
    if (regex != null) {
      const match = regex.exec(this.state.regexTest);
      if (match && match.length > 1) {
        let index = 1;
        let regexResult = {};
        this.state.fields.forEach((field) => {
          if (field.type === 0) {
            regexResult[field.name] = match[index];
            index++;
          }
        });
        this.setState({ regexResult, regexError: null });
        return;
      }
    }
    this.setState({ regexResult: {}, regexError: intl.formatMessage({ id: 'ebp.failMatch' }) });
  };

  handleChangeBarcodePattern = (data) => {
    const index = this.props.index;
    // if (this.state.fields.length < 1) {
    //   this.props.updateUi({
    //     snackbar: {
    //       message: this.props.intl.formatMessage({ id: 'ebp.fieldReq' }, { index: index + 1 }),
    //       severity: 'error',
    //       show: true,
    //     },
    //   });
    //   return;
    // }

    this.props.onBarcodePatternChange(index, this.buildDatabaseFields(data));
  };

  render() {
    const { classes, theme, ui, intl, index, domain, labelName, stageWidget, onBackToLabelEditor } = this.props;

    const readyToRender = index !== -1;

    const renderHeader = () => (
      <div className={classes.headerContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h5" className={classes.title}>
            {intl.formatMessage({ id: 'ebp.title' })}
            {readyToRender && (
              <>
                {':'}
                <br />
                &emsp;↪ {intl.formatMessage({ id: 'domain' })}
                {`: ${domain}`}
                <br /> &emsp;&emsp;↪ {intl.formatMessage({ id: 'label' })}
                {`: ${labelName}`}
                <br /> &emsp;&emsp;&emsp;↪ {intl.formatMessage({ id: 'ebp.patternIndex' }, { index: index + 1 })}
              </>
            )}
          </Typography>

          {readyToRender && (
            <Typography variant="h6" style={{ color: theme.palette.other.grey.dark }}>
              {intl.formatMessage({ id: 'ebp.currRegex' })}
              <Tooltip
                disableTouchListener
                className={classes.tooltip}
                title={
                  <Typography variant="caption">
                    {intl.formatMessage({ id: 'ebp.regexInfo' }, { nl: <br key="nl2" /> })}
                  </Typography>
                }
              >
                <HelpOutlineIcon fontSize="small" style={{ color: theme.palette.info.dark }} />
              </Tooltip>
              {': '}
              {this.state.currentRegex}
            </Typography>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {stageWidget}
          <Button
            aria-label={intl.formatMessage({ id: 'ebp.editLabel' })}
            variant="contained"
            color="primary"
            onClick={onBackToLabelEditor}
            style={{ marginTop: theme.spacing(0.5), minWidth: '202px' }}
          >
            <SettingsOverscanIcon style={{ marginRight: theme.spacing(2) }} />
            {intl.formatMessage({ id: 'ebp.editLabel' })}
          </Button>
        </div>
      </div>
    );

    const renderPatternEditor = () => (
      <form onSubmit={this.props.onSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.inputContainer}
        >
          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            <Typography variant="subtitle1" style={{ color: theme.palette.other.grey.main }}>
              {intl.formatMessage({ id: 'ebp.fields' })}
            </Typography>
          </Grid>
          {/* Existing fields */}
          {this.state.fields.length ? (
            <>
              <Grid
                item
                xs={12}
                container
                className={classes.fieldModifierTop}
                spacing={2}
                style={{
                  padding: theme.spacing(0.5),
                }}
              >
                {/* Header */}
                <Grid item xs={1}>
                  <Typography className={classes.header}>Pos</Typography>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.fieldType' })}</Typography>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.name' })}</Typography>
                </Grid>
                <Grid item xs={1} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.prefix' })}</Typography>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.limiter' })}</Typography>
                </Grid>
                <Grid item xs={1} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.postfix' })}</Typography>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'checkField' })}</Typography>
                </Grid>
                <Grid item xs={1} style={{ marginLeft: 0 }}>
                  <Typography className={classes.header}>{intl.formatMessage({ id: 'ebp.actions' })}</Typography>
                </Grid>
              </Grid>
              {/* Field Items */}
              {this.state.fields.map((field, index) => (
                <Grid
                  key={`${field.name}${index}`}
                  xs={12}
                  item // Simultaneously 'item' of parent and
                  container // 'container' of its children
                  justifyContent="flex-start"
                  alignItems="center"
                  className={index % 2 === 0 ? classes.fieldItemLight : classes.fieldItemDark}
                >
                  <Grid item xs={1}>
                    {index}
                  </Grid>
                  <Grid item xs={2}>
                    {intl.formatMessage({ id: `ebp.${fieldTypes[field.type]}` })}
                  </Grid>
                  <Grid item xs={2}>
                    {field.name}
                  </Grid>
                  {field.type === 0 ? (
                    <Fragment key={`${field}${index}`}>
                      <Grid item xs={1}>
                        {field.prefix}
                      </Grid>
                      <Grid item xs={2}>
                        {`${intl.formatMessage({ id: `ebp.${limiterTypes[field.limiterType]}` })}: ${
                          field.limiterType === 0 ? field.range : field.separator
                        }`}
                      </Grid>
                      <Grid item xs={1}>
                        {field.postfix}
                      </Grid>
                      <Grid item xs={2} style={{ paddingLeft: theme.spacing(0.5) }}>
                        {field.checkField}
                      </Grid>
                    </Fragment>
                  ) : (
                    <Grid item xs={6} />
                  )}
                  <Grid item xs={1}>
                    <Tooltip title={intl.formatMessage({ id: 'remove' })}>
                      <IconButton
                        edge="end"
                        aria-label={intl.formatMessage({ id: 'remove' })}
                        style={{ color: theme.palette.error.main }}
                        onClick={() => this.handleRemoveField(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1">{intl.formatMessage({ id: 'ebp.noFields' })}</Typography>
            </Grid>
          )}
        </Grid>
        {/* Add new field */}
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.inputContainer}
        >
          {/* Adding new fields */}
          <Grid item xs={12} className={this.state.fields.length ? classes.fieldModifier : classes.fieldModifierTop}>
            <Typography variant="subtitle1" style={{ color: theme.palette.other.grey.dark }}>
              {intl.formatMessage({ id: 'ebp.addNewFields' })}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            spacing={2}
            style={{ marginLeft: 0 }}
            className={this.state.type === 0 ? classes.fieldModifier : classes.fieldModifierBottom}
          >
            <Grid item xs={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="type-label">{intl.formatMessage({ id: 'ebp.fieldType' })}</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  id="type-select"
                  value={this.state.type}
                  onChange={this.handleChange}
                >
                  {fieldTypes.map((value, index) => (
                    <MenuItem value={index} key={value}>
                      {intl.formatMessage({ id: `ebp.${fieldTypes[index]}` })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <TextField
                error={!isEmpty(this.state.nameError)}
                name="name"
                id="name"
                type="text"
                label={`${intl.formatMessage({ id: 'ebp.name' })} *`}
                value={this.state.name}
                onChange={this.handleChange}
                helperText={this.state.nameError}
              />
            </Grid>

            {this.state.type === 0 ? (
              <>
                <Grid item xs={1} style={{ paddingLeft: 0 }}>
                  <TextField
                    name="prefix"
                    id="prefix"
                    type="text"
                    label={intl.formatMessage({ id: 'ebp.prefix' })}
                    value={this.state.prefix}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={2} style={{ paddingLeft: 0 }}>
                  <FormControl className={classes.formControl} style={{ marginLeft: 0 }}>
                    <InputLabel id="limiter-label">{intl.formatMessage({ id: 'ebp.limiter' })}</InputLabel>
                    <Select
                      labelId="limiter-label"
                      name="limiterType"
                      id="limiter-select"
                      value={this.state.limiterType}
                      onChange={this.handleChange}
                    >
                      {limiterTypes.map((value, index) => (
                        <MenuItem value={index} key={value}>
                          {intl.formatMessage({ id: `ebp.${limiterTypes[index]}` })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2} style={{ paddingLeft: 0 }}>
                  {this.state.limiterType === 0 ? (
                    <TextField
                      error={!isEmpty(this.state.rangeError)}
                      id="range"
                      name="range"
                      type="text"
                      label={`${intl.formatMessage({ id: 'ebp.range' })} *`}
                      value={this.state.range}
                      onChange={this.handleChange}
                      helperText={this.state.rangeError}
                    />
                  ) : (
                    <TextField
                      error={!isEmpty(this.state.separatorError)}
                      id="separator"
                      name="separator"
                      type="text"
                      label={`${intl.formatMessage({ id: 'ebp.separator' })} *`}
                      value={this.state.separator}
                      onChange={this.handleChange}
                      helperText={this.state.separatorError}
                    />
                  )}
                </Grid>

                <Grid item xs={1} style={{ paddingLeft: 0 }}>
                  <TextField
                    name="postfix"
                    id="postfix"
                    type="text"
                    label={intl.formatMessage({ id: 'ebp.postfix' })}
                    value={this.state.postfix}
                    onChange={this.handleChange}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={1} style={{ paddingLeft: 0 }}>
              <TextField
                error={!isEmpty(this.state.posError)}
                name="pos"
                id="pos"
                type="number"
                label="Pos"
                value={this.state.pos}
                onChange={this.handleChange}
                inputProps={{ min: 0, max: this.state.fields ? this.state.fields.length : 0 }}
                helperText={this.state.posError}
              />
            </Grid>

            {/* Add button */}
            <Grid item xs={this.state.type === 0 ? 1 : 6}>
              <Tooltip title={intl.formatMessage({ id: 'add' })} onClick={this.handleAddField}>
                <Fab size="small" color="secondary" aria-label="add">
                  <AddIcon fontSize="large" />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>

          {/* CheckFields */}
          {this.state.type === 0 && (
            <Grid item container xs={12} className={classes.fieldModifierBottom}>
              <Grid item xs={this.state.useCheckField ? 5 : 12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={this.state.useCheckField}
                      onChange={this.handleCheckboxChange('useCheckField')}
                    />
                  }
                  label={intl.formatMessage({ id: 'ebp.useCheckField' })}
                  labelPlacement="end"
                />
              </Grid>
              {this.state.useCheckField && (
                <Grid item xs={7} style={{ paddingLeft: 0 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={this.state.checkField ? null : this.state.name}
                    size="small"
                    id="checkField"
                    name="checkField"
                    type="text"
                    label={`${intl.formatMessage({ id: 'checkField' })} (${this.state.name})`}
                    value={this.state.checkField}
                    onChange={this.handleChange}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        {/* Label Options */}
        <Grid container className={classes.labelInputContainer}>
          <Grid item xs={5}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={this.state.ignoreRemaining}
                  onChange={this.handleCheckboxChange('ignoreRemaining')}
                />
              }
              label={intl.formatMessage({ id: 'ebp.ignoreRemaining' })}
              labelPlacement="end"
            />
          </Grid>

          <Grid item xs={7}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={this.state.isRequired}
                  onChange={this.handleCheckboxChange('isRequired')}
                />
              }
              label={intl.formatMessage({ id: 'ebp.isRequired' })}
              labelPlacement="end"
            />
          </Grid>
        </Grid>

        {/* Regex testing */}
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.labelInputContainer}
        >
          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            <Typography variant="subtitle1" style={{ color: theme.palette.other.grey.main }}>
              {intl.formatMessage({ id: 'ebp.checkRegex' }, { currentRegex: this.state.currentRegex })}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: 0 }}>
            <TextField
              variant="outlined"
              size="small"
              name="regexTest"
              id="regexTest"
              type="text"
              label={intl.formatMessage({ id: 'ebp.codeTest' })}
              value={this.state.regexTest}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={8} className={classes.testRegexButon}>
            <Button onClick={this.testRegex} color="primary" variant="outlined">
              {intl.formatMessage({ id: 'ebp.test' })}
            </Button>

            {this.state.regexError && (
              <Typography variant="body1" className={classes.testRegexError}>
                {this.state.regexError}
              </Typography>
            )}
          </Grid>

          {Object.keys(this.state.regexResult).map((key, index) => (
            <Grid item xs={4} style={{ paddingLeft: 0 }} key={`${key}${index}`}>
              <Typography variant="body1">{`${key}: ${this.state.regexResult[key]}`}</Typography>
            </Grid>
          ))}
        </Grid>
        <LoadingButton
          isLoading={ui.loadingButton.isBarcodePatternLoading}
          content={intl.formatMessage({ id: 'saveChanges' })}
          type="submit"
        />
      </form>
    );

    const renderErrorDialog = () => (
      <Dialog
        open={this.state.dialogOpen}
        onClose={() => this.setState({ dialogOpen: false })}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-content"
      >
        <DialogTitle id="dialog-title">
          {intl.formatMessage(
            { id: 'ebp.fieldExistsError' },
            { name: <b key={`name${Math.random()}`}>{this.state.name}</b> }
          )}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => this.setState({ dialogOpen: false })} color="primary" autoFocus>
            {intl.formatMessage({ id: 'ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <Container className={classes.root}>
        {/* Header with title, subtitle and label preview */}
        {renderHeader()}

        {readyToRender ? (
          <>
            {/* Barcode pattern editor */}
            {renderPatternEditor()}

            {/* Error dialog */}
            {renderErrorDialog()}
          </>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: theme.spacing(2) }}>
            <WarningRoundedIcon style={{ color: theme.palette.error.main, fontSize: theme.spacing(4) }} />
            <Typography variant="h5">{intl.formatMessage({ id: 'ebp.noBarcodeSelected' })}</Typography>
          </div>
        )}
      </Container>
    );
  }
}

export default compose(
  connect(null, (dispatch, ownProps) => ({
    ...ownProps,
    updateUi: (uiData) => dispatch(updateUiAction(uiData)),
  })),
  withStyles(styles),
  withTheme,
  injectIntl
)(EditBarcodePattern);
