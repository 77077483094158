import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Box,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { SaveAlt, Search, Clear } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as xlsx from 'xlsx';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
    marginBottom: spacing(1),
    borderRadius: 10,
  },
  highlight: {
    ...(palette.type === 'light'
      ? {
          color: palette.secondary.dark,
          backgroundColor: lighten(palette.secondary.light, 0.7),
        }
      : {
          color: palette.secondary.contrastText,
          backgroundColor: <theme className="palette secondary dark0"></theme>,
        }),
    paddingLeft: spacing(1),
  },
  searchBox: {
    minWidth: spacing(32),
  },
  leftJustifiedFlexContainer: {
    flex: '1 1 100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rightJustifiedFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexShrink: 0,
  },
  button: {
    width: spacing(48),
    marginRight: spacing(4),
  },
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  extraSelectorsFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(2),
  },
}));

const dateFormat = 'YYYY/MM/DD HH:MM:SS';

const EnhancedTableToolbar = (props) => {
  const { columns, rows, selected, title, showLink, linkPath, linkText, linkState } = props;
  const { localization } = { ...EnhancedTableToolbar.defaultProps, ...props };
  const intl = useIntl();

  Object.keys(localization).forEach((key) => {
    const trans = intl.formatMessage({ id: `ett.${key}` });
    localization[key] = trans.startsWith('ett.') ? localization[key] : trans;
  });

  const classes = useStyles();
  const theme = useTheme();
  const [exportAnchor, setExportAnchor] = React.useState();

  const isSelection = selected && selected.length > 0;
  const firstKey = columns && columns.length ? columns[0].id : null;

  const filterDataBeforeExport = () => {
    if (!(columns && columns.length && rows && rows.length)) {
      return false;
    }

    const columnsToExport = columns.filter((column) => {
      return !column.hidden && column.id && column.export !== false;
    });

    let dataToExport = isSelection ? rows.filter((row) => selected.includes(row[firstKey])) : rows;

    return {
      columns: columnsToExport.map((column) => column.label),
      rows: dataToExport.map((row) =>
        columnsToExport.map((column) => {
          const value = row[column.id];
          return value instanceof Date ? value : String(value);
        })
      ),
    };
  };

  const exportTo = (format) => () => {
    const data = filterDataBeforeExport();
    if (!data) {
      alert('Error: No data available!');
      return;
    }
    const { columns, rows } = data;

    let ws = xlsx.utils.aoa_to_sheet([columns, ...rows], { dateNF: dateFormat });
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'data');

    xlsx.writeFile(wb, `${props.exportFileName || props.title || 'data'}.${format}`);
    setExportAnchor(null);
  };

  const searchAlignedLeft = props.searchFieldAlignment === 'left';

  const renderExtraSelectors = () => {
    return (
      (props.extraSelectors && props.extraSelectors.length && (
        <FormControl component="fieldset">
          <FormGroup className={classes.extraSelectorsFormGroup}>
            {props.extraSelectors.map((selector) => (
              <FormControlLabel
                control={selector.control}
                label={selector.label}
                key={`selector${selector.label}`}
              />
            ))}
          </FormGroup>
        </FormControl>
      )) ||
      null
    );
  };

  const renderDeleteButton = () =>
    props.deleteButton &&
    isSelection && (
      <Tooltip title={props.deleteButton.title}>
        <IconButton
          color="inherit"
          onClick={props.deleteButton.onAction(selected)}
          aria-label={props.deleteButton.title}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Tooltip>
    );

  const renderSearch = () =>
    props.search ? (
      <TextField
        className={classes.searchBox}
        style={searchAlignedLeft ? { padding: theme.spacing(0, 2) } : null}
        value={props.searchText}
        onChange={(event) => props.onSearchChanged(event.target.value)}
        placeholder={localization.searchPlaceholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={localization.searchTooltip}>
                <Search color="inherit" fontSize="small" />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!props.searchText} onClick={() => props.onSearchChanged('')}>
                <Clear color="inherit" fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          style: props.searchFieldStyle,
        }}
      />
    ) : null;

  const renderExportButton = () => {
    const singleExport = props.customExports && props.customExports.length === 1;

    return props.exportButton || (props.exportButtonIfSelection && isSelection) ? (
      <>
        <Tooltip title={isSelection ? localization.exportSelectedTitle : localization.exportTitle}>
          <IconButton
            color="inherit"
            onClick={(e) =>
              singleExport
                ? props.customExports[0].callback(e, selected)
                : setExportAnchor(e.currentTarget)
            }
            aria-label={isSelection ? localization.exportSelectedTitle : localization.exportTitle}
          >
            <SaveAlt />
          </IconButton>
        </Tooltip>
        {props.customExports ? (
          <Menu
            anchorEl={exportAnchor}
            open={Boolean(exportAnchor)}
            onClose={() => setExportAnchor(null)}
          >
            {props.customExports.map((exp, i) => (
              <MenuItem
                key={`custom-export-${i}`}
                onClick={(e) => {
                  exp.callback(e, selected);
                  setExportAnchor(null);
                }}
              >
                {exp.description}
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            anchorEl={exportAnchor}
            open={Boolean(exportAnchor)}
            onClose={() => setExportAnchor(null)}
          >
            <MenuItem key="export-csv" onClick={exportTo('csv')}>
              {isSelection ? localization.exportSelectedCsvTitle : localization.exportCsvTitle}
            </MenuItem>
            <MenuItem key="export-xlsx" onClick={exportTo('xlsx')}>
              {isSelection ? localization.exportSelectedXlsxTitle : localization.exportXlsxTitle}
            </MenuItem>
          </Menu>
        )}
      </>
    ) : null;
  };

  // eslint-disable-next-line
  const renderFilterButton = () =>
    props.exportButton ? (
      <>
        <Tooltip>
          <IconButton
            color="inherit"
            onClick={(event) => setExportAnchor(event.currentTarget)}
            aria-label="Filter list"
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={exportAnchor}
          open={Boolean(exportAnchor)}
          onClose={() => setExportAnchor(null)}
        >
          <MenuItem key="export-csv" onClick={exportTo('csv')}>
            {localization.exportName}
          </MenuItem>
        </Menu>
      </>
    ) : null;

  const renderHead = () =>
    // Priority order: selection feedback -> leftAlignedButtons -> title
    selected.length > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {intl.formatMessage({ id: `ett.selectedText` }, { number: selected.length })}
      </Typography>
    ) : !!props.leftAlignedButtons ? (
      <div style={{ marginRight: theme.spacing(2) }}>{props.leftAlignedButtons}</div>
    ) : (
      props.showTitle && (
        <Typography style={theme.text.tableTitle} variant="h5" id="tableTitle">
          {title}
        </Typography>
      )
    );

  return (
    <Toolbar className={clsx(classes.root, { [classes.highlight]: selected.length > 0 })}>
      <div className={classes.leftJustifiedFlexContainer}>
        {renderHead()}
        {searchAlignedLeft && (
          <>
            {renderSearch()}
            {/* {renderFilterButton()} */}
            {renderExtraSelectors()}
          </>
        )}
      </div>
      <div className={classes.rightJustifiedFlexContainer}>
        {showLink && linkPath && linkText && (
          <Button
            component={Link}
            to={{ pathname: linkPath, state: linkState }}
            className={classes.button}
          >
            <Box className={classes.buttonText}>{linkText}</Box>
          </Button>
        )}

        {!searchAlignedLeft && (
          <>
            {renderExtraSelectors()}
            {/* {renderFilterButton()} */}
            {renderSearch()}
          </>
        )}
        {renderDeleteButton()}
        {renderExportButton()}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.defaultProps = {
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export all data',
    exportSelectedTitle: 'Export selection',
    exportCsvTitle: 'Export to CSV file',
    exportSelectedCsvTitle: 'Export Selected to CSV file',
    exportXlsxTitle: 'Export to XLSX file',
    exportSelectedXlsxTitle: 'Export Selected XLSX file',
    searchTooltip: 'Search',
    searchPlaceholder: 'Search',
  },
  search: true,
  showTitle: true,
  showTextRowsSelected: true,
  toolbarButtonAlignment: 'right',
  searchFieldAlignment: 'right',
  searchText: '',
  onSearchChanged: () => alert('I was not implemented =´('),
  exportButton: false,
  exportCsvDelimiter: ',',
  extraSelectors: [],
};

EnhancedTableToolbar.propTypes = {
  actions: PropTypes.array,
  deleteButton: PropTypes.object,
  columns: PropTypes.array,
  columnsButton: PropTypes.bool,
  components: PropTypes.object,
  localization: PropTypes.object.isRequired,
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
  searchFieldStyle: PropTypes.object,
  searchText: PropTypes.string.isRequired,
  selectedRows: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showTitle: PropTypes.bool.isRequired,
  showTextRowsSelected: PropTypes.bool.isRequired,
  toolbarButtonAlignment: PropTypes.string.isRequired,
  searchFieldAlignment: PropTypes.string.isRequired,
  extraSelectors: PropTypes.array,
  renderData: PropTypes.array,
  data: PropTypes.array,
  exportAllData: PropTypes.bool,
  exportButton: PropTypes.bool,
  exportCsvDelimiter: PropTypes.string,
  exportFileName: PropTypes.string,
  exportCsv: PropTypes.func,
  classes: PropTypes.object,
};

export default EnhancedTableToolbar;
